import { Navigate,  useLocation } from "react-router-dom";
import Layout from "../Layout";
export default function AuthCheckRoutes({
  path,
  Component,
  children,
  ...rest
}) {
  let location = useLocation();
  
  
  function GetToken() {
    const user = localStorage.getItem("token");
    return user;
  }
//   if (GetToken() === "" || GetToken() === null || GetToken() === undefined) {
//     return <Navigate to="/login" state={{ from: location }} replace />;
//   }
  return <Layout>{children}</Layout>;  
}
