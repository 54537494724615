import React from "react";
import ButtonHover from "../../../Helpers/ButtonHover";
import "./index.css";
import strategiesServices from '../../../../assets/capeark_img/services/strategiesServices.jpg'
import digitalstrategy from '../../../../assets/capeark_img/services/digital-strategy.jpg'


function StrategyComp() {
  return (
    <React.Fragment>
      {/* Css is used in one place (in main service folder) */}
      <div className="service_banner_item_cont_main">
        <div className="service_banner_item_bg_cont">
          <div className="row service_banner_item_content_cont pt-5 pb-5 ">
            <div className="col-sm-12 col-md-6 col-lg-6 service_banner_hover_cont service_banner_column">
              <figure className="p-0 m-0 service_banner_img_cont">
                <img
                  src={strategiesServices}
                  alt=""
                />
              </figure>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 service_banner_desc_cont">
              <div className="service_banner_desc_media__body">
                <h2 className="service_banner_desc_media__title mb-4">
                  What We Do
                </h2>
                <div className="service_banner_desc_media__text">
                  <p>
                    Capeark empowers organizations to adapt to the
                    ever-changing digital landscape. We’ll craft a solid
                    strategy to help you realize your goals and cultivate
                    valuable relationships with users through:{" "}
                  </p>
                  <ul className="service_banner_desc_list ">
                    <li>User research</li>
                    <li>Content strategy &amp; governance</li>
                    <li>Information architecture</li>
                    <li>Technical discovery</li>
                    <li>Digital governance planning</li>
                  </ul>
                </div>

                <div className="mt-4">
                  <ButtonHover
                    btnLink={"/services"}
                    btnText={"Find Out More"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container pb-5 pt-5">
          <div className="strategy_process_header_main">
            <h2>OUR STRATEGY PROCESS</h2>
          </div>
          <div className="row pb-4 pt-4">
            <div className="col-sm-12 col-md-4 col-lg-4 ">
              <div className="strategy_process_card_main">
                <h2>DISCOVERY</h2>
                <p>
                  In this initial phase, we deep-dive to understand your
                  organization and its needs. We hold workshops with your team
                  to assess your site, content, and analytics.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 ">
              <div className="strategy_process_card_main">
                <h2>AUDIENCE ANALYSIS</h2>
                <p>
                  We research your target audience and gather insights from
                  important stakeholders. Our team develops user personas
                  outlining user pain points and needs.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 ">
              <div className="strategy_process_card_main">
                <h2>USER JOURNEY MAPPING</h2>
                <p>
                  We work closely with representative users to identify typical
                  pathways through the website and propose a new information
                  architecture.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 ">
              <div className="strategy_process_card_main">
                <h2>CONTENT AUDIT</h2>
                <p>
                  We assess your existing content and identify opportunities to
                  improve, add, update, and retire content. We also assess your
                  content governance workflow and roles.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 ">
              <div className="strategy_process_card_main">
                <h2>CONTENT STRATEGY</h2>
                <p>
                  We collaborate with your team to create a solid content
                  strategy, generate a refreshed style guide, and build a new
                  governance plan.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 ">
              <div className="strategy_process_card_main">
                <h2>SUCCESS MEASUREMENT</h2>
                <p>
                  Together, we determine KPIs to measure success and ensure the
                  implemented strategy achieves your business objectives.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service_banner_item_cont_main_right">
        <div className="service_banner_item_bg_cont_right capeark-bg">
          <div className="row right_banner_flex service_banner_item_content_cont_right pt-5 pb-5 g-0 ">
            <div className="col-sm-12 col-md-6 col-lg-6 service_banner_desc_cont_right">
              <div className="service_banner_desc_media__body">
                <h2 className="service_banner_desc_media__title mb-4 text-white">
                  Our Philosophy
                </h2>
                <div className="service_banner_desc_media__text text-white">
                  {/* <h2>Design for Delight</h2> */}
                  <p>
                    We have a distinct philosophy that shapes our strategy work
                    and sets it apart. This philosophy is built upon our team’s
                    shared and deep-seated values:
                  </p>
                  <h3 className="mt-4">Smart & Actionable</h3>
                  <p>
                    No fluff in our strategy work. We bring you intelligent
                    insights, innovative ideas, and robust strategies, backed by
                    meticulous research and analysis.
                  </p>
                  <h3>Balanced & Aligned</h3>
                  <p>
                    We balance your business priorities with user needs. For
                    every project we take on, we assign an owner who’s
                    responsible for aligning the strategic objectives with the
                    deliverables.
                  </p>
                  <h3>Collaborative & Sustainable</h3>
                  <p>
                    Rooted in the open source community, we’re natural
                    collaborators and tap into our clients' teams' knowledge for
                    even-better results, with long-term vision in mind.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 service_banner_hover_cont service_banner_column_right">
              <figure className="p-0 m-0 service_banner_img_cont">
                <img
                  src={digitalstrategy}
                  alt=""
                />
              </figure>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container pb-5 pt-5">
          <div className="strategy_process_header_main">
            <h2>BENEFITS OF WORKING WITH OUR STRATEGISTS</h2>
          </div>
          <div className="row pb-4 pt-4">
            <div className="col-sm-12 col-md-4 col-lg-4 ">
              <div className="strategy_process_card_main">
                <h2>TRIED-AND-TRUE APPROACH</h2>
                <p>
                  We have a defined process that documents and builds on our
                  insights at every stage. This ensures everything is aligned
                  and working toward the same goal.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 ">
              <div className="strategy_process_card_main">
                <h2>GUIDANCE YOU CAN TRUST</h2>
                <p>
                  Make big decisions with confidence. We have a 15-year track
                  record of delivering ambitious projects and our work has been
                  recognized by many partners.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 ">
              <div className="strategy_process_card_main">
                <h2>OWN YOUR VISION</h2>
                <p>
                  We involve clients at every step and share knowledge openly,
                  empowering your team to successfully implement your strategy
                  while adapting to your needs and priorities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default StrategyComp;
