import React from 'react'
import enterprises from '../../../../assets/capeark_img/industry/interprises.jpg';
function EnterpriseBanner() {
    return (
        <React.Fragment>
            <div className="service_banner_item_cont_main">
                <div className="service_banner_item_bg_cont">
                    <div className="row service_banner_item_content_cont pt-5 pb-5 ">
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_hover_cont service_banner_column">
                            <figure className="p-0 m-0 service_banner_img_cont">
                                <img
                                    src={enterprises}
                                    alt=""
                                />
                            </figure>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_desc_cont">
                            <div className="service_banner_desc_media__body">
                                <h2 className="service_banner_desc_media__title mb-4">
                                    Make Your Message Go Further
                                </h2>
                                <div className="service_banner_desc_media__text">
                                    <p>There’s no shortage of opportunities in the ever-changing digital landscape. But enterprises need to adapt quickly and strategically to thrive. <br />Capeark empowers you to meet challenges head-on and harness them for profit. Our team of 80+ strategists, creatives, and technologists have a track record of taking ambitious enterprises to new heights.</p>
                                    <ul className="service_banner_desc_list ">
                                        <li><span className='fw-bold'>Strategic guidance.</span> We help you make smart, bold decisions that set you apart and boost your bottom line. </li>
                                        <li><span className='fw-bold'>Balanced project.</span> Our team carefully aligns your business goals, stakeholder priorities, and user needs. </li>
                                        <li><span className='fw-bold'>Lasting success.</span> We build dynamic platforms with your long-term vision and continuous growth in mind. </li>
                                        <li><span className='fw-bold'>Digital independence.</span> We set your team up for ongoing success, with the tools they need to confidently own and grow your platform.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default EnterpriseBanner