import React from "react";
import "./ButtonHover.css";
import { Link } from "react-router-dom";
function ButtonHover({ btnLink , btnText}) {
  return (
    <div className="service_globel_container">
      <Link to={btnLink} className="service_globel_btn btn service_btn-four">
        <span>{btnText}</span>
      </Link>
    </div>
  );
}

export default ButtonHover;
