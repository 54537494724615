import React from 'react';
import ContactBottom from '../../Helpers/ContactBtn/ContactBottom';
import EnterpriseBanner from './Pages/EnterpriseBanner';
import EnterpriseIntro from './Pages/EnterpriseIntro';
import EnterpriseTextBanner from './Pages/EnterpriseTextBanner';
import { Helmet } from 'react-helmet';
import stareBanner from "../../../assets/Images/General/stare_banner.jpeg";
function Enterprise() {
    return (
        <React.Fragment>
            <Helmet>
                <meta charset="utf-8" />
                <title>
                    Capeark | Website Development for Enterprise
                </title>
                <meta charset="utf-&" />
                <meta name="description" content="Enterprise, Cultivate Valuable Customer Relationships" />
                <meta name="keywords" content="Government, Municipality, Higer education, Non profit, Health care, Enterprises" />
                <meta name="author" content="Capeark" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div className="row position-relative industries-top-banner g-0">
                <img src={stareBanner} className="img-fluid p-0" alt="" />
                <div className="col-12 text-center industries-banner-content d-flex justify-content-center">
                    <div className="align-self-center industries-banner-content-child">
                        <h1>ENTERPRISE</h1>
                        <p>
                            Cultivate Valuable Customer Relationships
                        </p>
                    </div>
                </div>
                <div className="industires-banner-clip-img">
                    <div className='industires-banner-clip-img-div'></div>
                </div>
            </div>
            <div>
                <EnterpriseBanner />
            </div>

            <div>
                <EnterpriseIntro />
            </div>
            <div>
                <EnterpriseTextBanner />
            </div>
            <div>
                <ContactBottom
                    btnLink={'/contact'}
                    btnText={'Reach Out to Our Team'}
                    textVarient="h1"
                    BannerText={"We Look Forward to Working with You"}
                />
            </div>
        </React.Fragment>
    )
}

export default Enterprise