import React from 'react'
import ButtonHover from '../../../Helpers/ButtonHover';
import websiteforgovt from '../../../../assets/capeark_img/industry/website-for-govt.jpg'
import highereducationmain from '../../../../assets/capeark_img/industry/higher-education-main.jpg'
import healthcare from '../../../../assets/capeark_img/industry/health-care-main.jpg'
import enterprise from '../../../../assets/capeark_img/industry/interprises.jpg'
import nonprofit from '../../../../assets/capeark_img/industry/non-profit.jpg'


function AllIndustriesBannerItem() {
    return (
        <React.Fragment>
            <div className="service_banner_item_cont_main">
                <div className="service_banner_item_bg_cont">
                    <div className="row service_banner_item_content_cont pt-5 pb-5 ">
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_hover_cont service_banner_column">
                            <figure className="p-0 m-0 service_banner_img_cont">
                                <img
                                    src={websiteforgovt}
                                    alt=""
                                />
                            </figure>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_desc_cont">
                            <div className="service_banner_desc_media__body">
                                <h2 className="service_banner_desc_media__title mb-4">
                                    Governments
                                </h2>
                                <div className="service_banner_desc_media__text">
                                    <p>We understand the specific challenges government and public-sector clients face. For years, we have been helping them communicate clearly with their key audiences while adhering to accessibility, security, and privacy compliance requirements. Organizations we’ve worked with include:</p>
                                    {/* <ul className="service_banner_desc_list ">
                                        <li>Government of India</li>
                                        <li>U.S. Senate</li>
                                        <li>City of San Francisco</li>
                                        <li>City of Hamilton</li>
                                        <li>Yukon Government</li>
                                    </ul> */}
                                </div>

                                <div className="mt-4">
                                    <ButtonHover
                                        btnLink={"/website-development-government-agencies"}
                                        btnText={"Find Out More"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service_banner_item_cont_main">
                <div className="service_banner_item_bg_cont">
                    <div className="row service_banner_item_content_cont pt-5 pb-5 ">
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_hover_cont service_banner_column">
                            <figure className="p-0 m-0 service_banner_img_cont">
                                <img
                                    src={highereducationmain}
                                    alt=""
                                />
                            </figure>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_desc_cont">
                            <div className="service_banner_desc_media__body">
                                <h2 className="service_banner_desc_media__title mb-4">
                                    Higher Education
                                </h2>
                                <div className="service_banner_desc_media__text">
                                    <p>recruitment and admissions to research and services for current students, to continuing education and alumni engagement.<br />
                                        Our higher education clientele includes:</p>
                                    {/* <ul className="service_banner_desc_list ">
                                        <li>Princeton University</li>
                                        <li>Georgia Tech</li>
                                        <li>Emory University</li>
                                        <li>University of Toronto</li>
                                        <li>York University</li>
                                    </ul> */}
                                </div>

                                <div className="mt-4">
                                    <ButtonHover
                                        btnLink={"/higher-education-website-design-development"}
                                        btnText={"Find Out More"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service_banner_item_cont_main">
                <div className="service_banner_item_bg_cont">
                    <div className="row service_banner_item_content_cont pt-5 pb-5 ">
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_hover_cont service_banner_column">
                            <figure className="p-0 m-0 service_banner_img_cont">
                                <img
                                    src={nonprofit}
                                    alt=""
                                />
                            </figure>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_desc_cont">
                            <div className="service_banner_desc_media__body">
                                <h2 className="service_banner_desc_media__title mb-4">
                                    Non-profit
                                </h2>
                                <div className="service_banner_desc_media__text">
                                    <p>We help non-profit and socially-oriented organizations improve their outreach and enhance their digital communications and branding to better connect with donors, supporters, and the communities they serve. We’re proud of the work we’ve done with the following organizations in North America:</p>
                                    {/* <ul className="service_banner_desc_list ">
                                        <li>The Canada Foundation for Innovation</li>
                                        <li>The CURE Foundation</li>
                                        <li>The Pierre Elliott Trudeau Foundation</li>
                                        <li>Alberta Municipalities</li>
                                        <li>McGill SAFE </li>
                                    </ul> */}
                                </div>

                                <div className="mt-4">
                                    <ButtonHover
                                        btnLink={"/website-development-non-governmental-organizations"}
                                        btnText={"Find Out More"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service_banner_item_cont_main">
                <div className="service_banner_item_bg_cont">
                    <div className="row service_banner_item_content_cont pt-5 pb-5 ">
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_hover_cont service_banner_column">
                            <figure className="p-0 m-0 service_banner_img_cont">
                                <img
                                    src={healthcare}
                                    alt=""
                                />
                            </figure>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_desc_cont">
                            <div className="service_banner_desc_media__body">
                                <h2 className="service_banner_desc_media__title mb-4">
                                    Healthcare
                                </h2>
                                <div className="service_banner_desc_media__text">
                                    <p>Capeark collaborates with organizations in the healthcare space to make their digital content and services more accessible and easier to use. In turn, this helps them improve the experience for patients, healthcare workers, and the public-at-large. Organizations we’ve worked with include:</p>
                                    {/* <ul className="service_banner_desc_list ">
                                        <li>McGill University Health System</li>
                                        <li>Children’s Hospital of Philadelphia</li>
                                        <li>The Boston Children’s Hospital</li>
                                        <li>The Council for Responsible Nutrition</li>
                                        <li>Sainte-Justine University Hospital Centre</li>
                                    </ul> */}
                                </div>

                                <div className="mt-4">
                                    <ButtonHover
                                        btnLink={"/website-development-healthcare"}
                                        btnText={"Find Out More"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service_banner_item_cont_main">
                <div className="service_banner_item_bg_cont">
                    <div className="row service_banner_item_content_cont pt-5 pb-5 ">
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_hover_cont service_banner_column">
                            <figure className="p-0 m-0 service_banner_img_cont">
                                <img
                                    src={enterprise}
                                    alt=""
                                />
                            </figure>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_desc_cont">
                            <div className="service_banner_desc_media__body">
                                <h2 className="service_banner_desc_media__title mb-4">
                                    Enterprise
                                </h2>
                                <div className="service_banner_desc_media__text">
                                    <h2>Dive Deeper</h2>
                                    <p>Aligning a digital strategy with your business goals requires powerful, scalable, stable and secure digital experiences. We help enterprise clients create an on-brand digital presence that connects them with their audiences across North America and around the world. Organizations we’ve worked with include:</p>
                                    <ul className="service_banner_desc_list ">
                                        <li>Galaxy Enterprise</li>
                                        
                                    </ul>
                                </div>

                                <div className="mt-4">
                                    <ButtonHover
                                        btnLink={"/enterprise"}
                                        btnText={"Find Out More"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AllIndustriesBannerItem;