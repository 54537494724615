import React from "react";

function AuditInfo() {
  return (
    <React.Fragment>
      <div className="container pb-5 pt-5">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 p-2">
            <div className="audit_info_card_main">
              <div>
                <h4>FULL WEBSITE AUDIT</h4>
                <p>
                  Need a launchpad for your next digital project? This audit
                  provides an overview of how well your site meets user needs
                  and how it could improve. We assess and provide general
                  recommendations on:
                </p>
              </div>
              <div className="audit_info_desc_media__text">
                <ul className="audit_info_desc_list ">
                  <li>User research</li>
                  <li>Content strategy &amp; governance</li>
                  <li>Information architecture</li>
                  <li>Technical discovery</li>
                  <li>Digital governance planning</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 p-2">
            <div className="audit_info_card_main">
              <div>
                <h4>CONTENT & SEO AUDITS</h4>
                <p>
                  Get practical recommendations to improve how users find your
                  site and engage with its content. Our experts will evaluate:
                </p>
              </div>
              <div className="audit_info_desc_media__text">
                <ul className="audit_info_desc_list ">
                  <li>Your existing content and information architecture.</li>
                  <li>Your workflows and roles for updating content.</li>
                  <li>Opportunities to optimize, add, and retire content.</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 p-2">
            <div className="audit_info_card_main">
              <div>
                <h4>TECHNICAL SITE AUDITS</h4>
                <p>
                  Get actionable steps to make your site more robust and
                  maintainable. We analyze your code, configuration, databases,
                  and development workflows to help you:
                </p>
              </div>
              <div className="audit_info_desc_media__text">
                <ul className="audit_info_desc_list ">
                  <li>Address issues with security and performance.</li>
                  <li>Improve the quality of your code.</li>
                  <li>Identify legacy code that can be removed.</li>
                  <li>Prepare your site for migration.</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 p-2">
            <div className="audit_info_card_main">
              <div>
                <h4>WEB ACCESSIBILITY AUDITS</h4>
                <p>
                  Our accessibility audit gives you the tools to build a better
                  digital experience for everyone and ensure compliance with
                  relevant laws and regulations. We will:
                </p>
              </div>
              <div className="audit_info_desc_media__text">
                <ul className="audit_info_desc_list ">
                  <li>
                    Assess your level of compliance with AODA and WCAG 2.0.
                  </li>
                  <li>
                    Highlight specific violations and areas for improvement.
                  </li>
                  <li>Recommend a course to compliance.</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 p-2">
            <div className="audit_info_card_main">
              <div>
                <h4>MULTILINGUAL SITE AUDITS</h4>
                <p>
                  Prepare for a smoother roll-out of multilingual content and
                  address any barriers to integration with a translation module
                  such as Lingotek. Our experts will:
                </p>
              </div>
              <div className="audit_info_desc_media__text">
                <ul className="audit_info_desc_list ">
                  <li>
                    Review your content, technologies, themes, and workflows.
                  </li>
                  <li>Determine how your web components display content.</li>
                  <li>Provide a risk assessment and a remediation plan.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AuditInfo;
