import { POST, PUT, DELETE, GET } from "../../constants/methods";
import callApi from "../../callApi";


export const getAllJobs = async (page) => {
  var url = '/api/jobs/list?active=true?offset=10';
  if (page !== undefined) {
    url = `/api/jobs/list?active=true?offset=10&page=${page}`
  }
  return await callApi(url, {}, GET);
};
export const getJobsById = async (id) => {
  return await callApi(`/api/jobs/get/${id}`, GET);
};
export const createNewJobs = async (data) => {
  return await callApi("/api/jobs/create", data, POST);
};
export const updateJobsById = async (id, data) => {
  return await callApi(`/api/jobs/update/${id}/`, data, PUT);
};
export const deleteJobsById = async (id) => {
  return await callApi(`/api/jobs/delete/${id}/`, {}, DELETE);
};