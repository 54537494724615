import React from "react";
import "./NotFound.css";
import { Link } from "react-router-dom";
// import notfound_error from "../../assets/images/genaral/notfound_error.svg";
export default function NotFound() {
    return (
        <div className="not_found_main" id="notfound">
            <div className="container main_notfound">
                <div className="notfound-404">
                    <h1>404</h1>
                    <h2>Page not found</h2>
                </div>   
                <Link className="notfound_home_btn" to="/">Homepage</Link>
            </div>
        </div>
    );
}
