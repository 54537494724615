import React from "react";
import { Helmet } from 'react-helmet';
import "./Strategy.css";
import StrategyComp from "./pages/StrategyComp";

function Strategy() {
    return (
        <React.Fragment>
            <Helmet>
                <meta charset="utf-8" />
                <title>
                    Capeark | Strategy Services
                </title>
                <meta charset="utf-&" />
                <meta name="description" content="Strategy Services, Dive Deeper. Take Your Message Further." />
                <meta name="keywords" content="Web development, App development, Mern stack, Mean stack, Audit Services, Strategy, Digital Products & Experience Designs, Ongoing Support" />
                <meta name="author" content="Capeark" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div className="service_header_main_cont">
                <div className="container mt-4 mb-5">
                    <div className="row mt-5">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <h6 className="service-page-header-title-text text-white">
                                STRATEGY
                            </h6>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                            <h1 className="service-page-header-subtitle-text text-white">
                                Dive Deeper. Take Your Message Further.
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="project-banner-clip-one"></div>
            <div className="">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <p className="service_intro_text">
                                Let’s get to the heart of your audience’s needs and develop a
                                roadmap to evolve your digital presence.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <StrategyComp />
            </div>
        </React.Fragment>
    );
}

export default Strategy;
