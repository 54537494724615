import React from 'react'

function EnterpriseTextBanner() {
    return (
        <React.Fragment>
            <div style={{ backgroundColor: "#f5f5f5" }} className='mb-5'>
                <div className="container pb-5 pt-5">
                    <div className="row pb-4 pt-4">
                        <div className="col-sm-12 col-md-12 col-lg-12 ">
                            <div className="industry_non_profit_design_desc_card_main">
                                <h2>Why choose Capeark?</h2>
                                <div className="industry_non_profit__desc_media__text">
                                    <ul className="industry_non_profit_desc_list ">
                                        <li><span className='fw-bold'>Experienced.</span> We’ve been collaborating with clients on complex projects since 2007. Our trainers have delivered courses to more than 3,000 people. </li>
                                        <li><span className='fw-bold'>Expert.</span> Capeark is a Drupal Certified Partner (Platinum Level) and we’ve built strategic partnerships to build solutions that are effective, and sustainable. </li>
                                        <li><span className='fw-bold'>User-centric.</span> Our work is grounded by a deep understanding of your audience. This ensures we build valuable experiences that help you create deeper relationships.</li>
                                        <li><span className='fw-bold'>Collaborative.</span> Our team works side-by-side with yours. We leverage and build on your knowledge and vision for the best results. </li>
                                        <li><span className='fw-bold'>Diverse.</span> We hail from 20 different countries, speak 14 languages, and represent a variety of backgrounds. Our diverse perspectives empower us to be more innovative and inclusive.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default EnterpriseTextBanner