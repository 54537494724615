import React from 'react'
import ButtonHover from '../../../Helpers/ButtonHover';
import marketingbanner from '../../../../assets/capeark_img/services/marketingbanner.jpg'
function MarketingBanner() {
    return (
        <React.Fragment>
            {/* Css is used in one place (in main service folder) */}
            <div className="service_banner_item_cont_main_right">
                <div className="service_banner_item_bg_cont_right capeark-bg">
                    <div className="row right_banner_flex service_banner_item_content_cont_right pt-5 pb-5 g-0 ">
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_desc_cont_right">
                            <div className="service_banner_desc_media__body">
                                <h2 className="service_banner_desc_media__title mb-4 text-white">
                                    Engage with Your Audience
                                </h2>
                                <div className="service_banner_desc_media__text text-white">
                                    <h3 className="mt-4">Digital marketing strategy</h3>
                                    <p>
                                        We start by analyzing your existing strategies, understanding the challenges, then making recommendations for a consistent content marketing strategy that targets the most effective channels and considers the needs of your audiences.
                                    </p>
                                    <h3 className="mt-4">Communications strategy</h3>
                                    <p>
                                        We develop communications and marketing strategies and campaigns that will help you deliver your message reach the right audiences through the right channels at the right time.
                                    </p>
                                    <h3 className="mt-4">Social media strategy and management</h3>
                                    <p>
                                        We develop and manage winning campaigns by selecting appropriate social media channels, identifying objectives and key metrics for each platform, and producing effective user-centric content that's consistent with your branding.
                                    </p>
                                </div>
                                <div className='mt-5'>
                                    <ButtonHover
                                        btnLink={"/contact"}
                                        btnText={"Tell us about your project"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_hover_cont service_banner_column_right">
                            <figure className="p-0 m-0 service_banner_img_cont">
                                <img
                                    src={marketingbanner}
                                    alt=""
                                />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MarketingBanner