import React from 'react'
import { Link } from 'react-router-dom';
import "./index.css"
function Industries({ colorLink, activeChild }) {
    return (
        <div>
            <Link to="/industries" className={`dropdown-item ${activeChild === "/industries" ? "active" : ""}`} onClick={() => colorLink("/industries")}>
                All Industries
            </Link>
            <Link to="/website-development-government-agencies" className={`dropdown-item ${activeChild === "/website-development-government-agencies" ? "active" : ""}`} onClick={() => colorLink("/website-development-government-agencies")}>
                Govt and Municipalities
            </Link>
            <Link to="/higher-education-website-design-development" className={`dropdown-item ${activeChild === "/higher-education-website-design-development" ? "active" : ""}`} onClick={() => colorLink("/higher-education-website-design-development")}>
                Higher education
            </Link>
            <Link to="/website-development-non-governmental-organizations" className={`dropdown-item ${activeChild === "/website-development-non-governmental-organizations" ? "active" : ""}`} onClick={() => colorLink("/website-development-non-governmental-organizations")}>
                Non-profit 
            </Link>
            <Link to="/website-development-healthcare" className={`dropdown-item ${activeChild === "/website-development-healthcare" ? "active" : ""}`} onClick={() => colorLink("/website-development-healthcare")}>
                Health care
            </Link>
            <Link to="/enterprise" className={`dropdown-item ${activeChild === "/enterprise" ? "active" : ""}`} onClick={() => colorLink("/enterprise")}>
                Enterprise
            </Link>
            {/* <Link to="/" className={`dropdown-item ${activeChild === "/about" ? "active" : ""}`}>
                Banking
            </Link>
            <Link to="/" className={`dropdown-item ${activeChild === "/about" ? "active" : ""}`}>
                Travel
            </Link>
            <Link to="/" className={`dropdown-item ${activeChild === "/about" ? "active" : ""}`}>
                Auction
            </Link>
            <Link to="/" className={`dropdown-item ${activeChild === "/about" ? "active" : ""}`}>
                Media
            </Link> */}
        </div>
    )
}

export default Industries;