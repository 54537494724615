import React from "react";
import "./index.css";
import { Link } from "react-router-dom";
import ButtonHover from "../../../Helpers/ButtonHover";
import serviceBanner1 from '../../../../assets/capeark_img/services/serviceBanner1.jpg'
import serviceBanner2 from '../../../../assets/capeark_img/services/serviceBanner2.jpg'
import serviceBanner3 from '../../../../assets/capeark_img/services/serviceBanner3.jpg'
import serviceBanner4 from '../../../../assets/capeark_img/services/serviceBanner4.jpg'


function ServiceBannerItem() {
    return (
        <React.Fragment>
            <div className="service_banner_item_cont_main">
                <div className="service_banner_item_bg_cont">
                    <div className="row service_banner_item_content_cont pt-5 pb-5 ">
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_hover_cont service_banner_column">
                            <figure className="p-0 m-0 service_banner_img_cont">
                                <img
                                    src={serviceBanner1}
                                    alt="STRATEGY"
                                />
                            </figure>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_desc_cont">
                            <div className="service_banner_desc_media__body">
                                <h2 className="service_banner_desc_media__title mb-4">
                                    STRATEGY
                                </h2>
                                <div className="service_banner_desc_media__text">
                                    <h2>Dive Deeper</h2>
                                    <p>Laying a foundation for growth with strategic thinking</p>
                                    <ul className="service_banner_desc_list ">
                                        <li>User research</li>
                                        <li>Content strategy &amp; governance</li>
                                        <li>Information architecture</li>
                                        <li>Technical discovery</li>
                                        <li>Digital governance planning</li>
                                    </ul>
                                </div>

                                <div className="mt-4">
                                    <ButtonHover
                                        btnLink={"/strategies-services"}
                                        btnText={"Find Out More"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service_banner_item_cont_main_right">
                <div className="service_banner_item_bg_cont_right capeark-bg">
                    <div className="row right_banner_flex service_banner_item_content_cont_right pt-5 pb-5 g-0 ">
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_desc_cont_right">
                            <div className="service_banner_desc_media__body">
                                <h2 className="service_banner_desc_media__title mb-4 text-white">
                                    DIGITAL PRODUCTS & EXPERIENCE DESIGN
                                </h2>
                                <div className="service_banner_desc_media__text text-white">
                                    <h2>Design for Delight</h2>
                                    <p>
                                        Dynamic visual storytelling that expands across channels
                                    </p>
                                    <ul className="service_banner_desc_list text-white">
                                        <li>Research &amp; ideation</li>
                                        <li>Brand &amp; product development</li>
                                        <li>Art direction</li>
                                        <li>UX &amp; UI design</li>
                                        <li>Visual prototyping</li>
                                        <li>Usability testing</li>
                                    </ul>
                                </div>

                                <div className="mt-4">
                                    <ButtonHover
                                        btnLink={"/web-design-services"}
                                        btnText={"Find Out More"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_hover_cont service_banner_column_right">
                            <figure className="p-0 m-0 service_banner_img_cont">
                                <img
                                    src={serviceBanner2}
                                    alt="DESIGN"
                                />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service_banner_item_cont_main">
                <div className="service_banner_item_bg_cont">
                    <div className="row service_banner_item_content_cont pt-5 pb-5 ">
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_hover_cont service_banner_column">
                            <figure className="p-0 m-0 service_banner_img_cont">
                                <img
                                    src={serviceBanner3}
                                    alt="TECHNOLOGY"
                                />
                            </figure>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_desc_cont">
                            <div className="service_banner_desc_media__body">
                                <h2 className="service_banner_desc_media__title mb-4">
                                    DEVELOPMENT & TECHNOLOGY
                                </h2>
                                <div className="service_banner_desc_media__text">
                                    <h2>Tech for Success</h2>
                                    <p>Technical expertise to make the complex simple</p>
                                    <ul className="service_banner_desc_list ">
                                        <li>Back-end development</li>
                                        <li>Front-end development</li>
                                        <li>Content migration &amp; synchronization</li>
                                        <li>Multilingual websites</li>
                                        <li>Search interfaces</li>
                                    </ul>
                                </div>

                                <div className="mt-4">
                                    <ButtonHover
                                        btnLink={"/development-technology"}
                                        btnText={"Find Out More"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service_banner_item_cont_main_right">
                <div className="service_banner_item_bg_cont_right capeark-bg">
                    <div className="row right_banner_flex service_banner_item_content_cont_right pt-5 pb-5 g-0 ">
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_desc_cont_right">
                            <div className="service_banner_desc_media__body">
                                <h2 className="service_banner_desc_media__title mb-4 text-white">
                                    ONGOING SUPPORT
                                </h2>
                                <div className="service_banner_desc_media__text text-white">
                                    <h2>Ongoing Improvement to Ensure your Continued Success</h2>
                                    <p>Technical expertise to make the complex simple</p>
                                    <ul className="service_banner_desc_list text-white">
                                        <li>On-going improvements &amp; evolution</li>
                                        <li>Maintenance &amp; security updates</li>
                                        <li>Managed hosting</li>
                                        <li>SEO monitoring and improvements</li>
                                        <li>Accessibility and security compliance</li>
                                        <li>Performance optimization</li>
                                        <li>Training and knowledge transfer</li>
                                    </ul>
                                </div>

                                <div className="mt-4">
                                    <ButtonHover
                                        btnLink={"/maintenance"}
                                        btnText={"Find Out More"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_hover_cont service_banner_column_right">
                            <figure className="p-0 m-0 service_banner_img_cont">
                                <img
                                    src={serviceBanner4}
                                    alt="SUPPORT"
                                />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ServiceBannerItem;
