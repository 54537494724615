import React from 'react'
import { Link } from 'react-router-dom';
import "./index.css"
function Services({ colorLink, activeChild }) {
    return (
        <div>
            <Link to="/services" className={`dropdown-item ${activeChild === "/services" ? "active" : ""}`} onClick={() => colorLink("/services")}>
                Our Services
            </Link>
            <Link to="/audit-services" className={`dropdown-item ${activeChild === "/audit-services" ? "active" : ""}`} onClick={() => colorLink("/audit-services")}>
                Audit services
            </Link>
            <Link to="/strategies-services" className={`dropdown-item ${activeChild === "/strategies-services" ? "active" : ""}`} onClick={() => colorLink("/strategies-services")}>
                Strategy
            </Link>
            <Link to="/web-design-services" className={`dropdown-item ${activeChild === "/web-design-services" ? "active" : ""}`} onClick={() => colorLink("/web-design-services")}>
                Designs
            </Link>
            <Link to="/development-technology" className={`dropdown-item ${activeChild === "/development-technology" ? "active" : ""}`} onClick={() => colorLink("/development-technology")}>
                Development and technology
            </Link>
            <Link to="/marketing" className={`dropdown-item ${activeChild === "/marketing" ? "active" : ""}`} onClick={() => colorLink("/marketing")}>
                Marketing
            </Link>
            <Link to="/maintenance" className={`dropdown-item ${activeChild === "/maintenance" ? "active" : ""}`} onClick={() => colorLink("/maintenance")}>
                Maintenance and support
            </Link>
            <Link to="/content-strategy-seo" className={`dropdown-item ${activeChild === "/content-strategy-seo" ? "active" : ""}`} onClick={() => colorLink("/content-strategy-seo")}>
                Content Strategy & SEO
            </Link>
        </div>
    )
}

export default Services;