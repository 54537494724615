import React from 'react'
import maintenancebanner from '../../../../assets/capeark_img/services/maintenanceBanner.jpg'

function MaintenanceBanner() {
    return (
        <React.Fragment>
            <div className="service_banner_item_cont_main">
                <div className="service_banner_item_bg_cont">
                    <div className="row service_banner_item_content_cont pt-5 pb-5 ">
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_hover_cont service_banner_column">
                            <figure className="p-0 m-0 service_banner_img_cont">
                                <img
                                    src={maintenancebanner}
                                    alt=""
                                />
                            </figure>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_desc_cont">
                            <div className="service_banner_desc_media__body">
                                <h2 className="service_banner_desc_media__title mb-4">
                                Maximizing Your Investment
                                </h2>
                                <div className="service_banner_desc_media__text">
                                    <p>The launch of a new website is always exciting. It’s empowering to work with a platform that is built around your needs and leverages the latest features. And as time passes and your needs evolve, we can also work with you to help maximize your investment. Through continuous improvements, we ensure that your website continues to transform as your needs change. And through our support and maintenance plan, we ensure the stability and security of your platform.</p>
                                    <p>Wether you built your website with us or not, we'll help you through:</p>
                                    <ul className="service_banner_desc_list ">
                                        <li>Training and knowledge transfer</li>
                                        <li>Support and maintenance</li>
                                        <li>Strategic upgrades</li>
                                        <li>Functional QA</li>
                                        <li>User acceptance testing</li>
                                        <li>Accessibility</li>
                                        <li>Performance optimization</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MaintenanceBanner;