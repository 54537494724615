import React from 'react';
import "./index.css";
import { Link } from 'react-router-dom';

function ContactBottom({ btnLink, btnText, BannerText, textVarient, bannerDesc, Desc }) {
    return (
        <React.Fragment>
            <div className='container pt-2 pb-5'>
                <div className='row g-0'>
                    <div className='col-sm-12 col-lg-12 col-md-12'>
                        <div className={`${textVarient === "h1" ? 'contact_bottom_label_second_main' : "contact_bottom_label_main"}`}>
                            <h2>{BannerText}</h2>
                        </div>
                        {Desc && (
                            <p className='contact_bottom_label_desc'>
                                {bannerDesc}
                            </p>
                        )}
                    </div>
                    <div className='col-sm-12 col-lg-12 col-md-12 pt-4 pb-2'>
                        <div className='contact_bottom_button_main'>
                            <Link to={btnLink} className="text-white contact_bottom_get_started_btn">
                                <span class="contact_bottom_dot-line-left"></span>
                                <span class="contact_bottom_text">{btnText}</span>
                                <span class="contact_bottom_block-right"></span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ContactBottom;