import "./css/About.css";
export default function CarrersIcon() {
  return (

    <div className="hand-shake-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="100%"
        width="100"
        id="svg8"
        xmlSpace="preserve"
        enableBackground="new 0 0 1000 1000"
        viewBox="0 0 979.94867 960.23102"
        y="0px"
        x="0px"
        version="1.1"
      >

        {/* hhhh */}
        <defs id="defs12"></defs>

        <g transform="translate(-10,-19.868985)" id="g6">

          <path id="path4"
            d="M 985.5,350.8 C 998.7,276.8 982.3,202
         939.3,140.4 896.3,78.7 831.9,37.4 757.8,24.2 661.6,7 562.6,
         41.8 497.8,114.9 l -0.2,-0.2 -1.3,1.3 C 443.2,59.4 369.9,27.2
         291.5,27.2 136.3,27.1 10,153.4 10,308.7 c 0,94.6 48.6,182.9
         127.1,234.7 l -32,30.5 c -29.3,27.9 -30.4,74.5 -2.5,103.8 l
         12.8,13.4 c 13.3,13.9 31.8,22 51,22.5 -0.9,19.3 5.7,38.9 20,54 
         L 199,781 c 13.8,14.5 33.2,22.8 53.2,22.8 2,0 4,-0.4 6.1,-0.6
         -0.1,19 7,37.1 20.1,50.9 l 12.7,13.3 c 13.8,14.5 33.2,22.8 53.2,
         22.8 0.2,0 0.4,-0.1 0.5,-0.1 0,0.1 -0.1,0.3 -0.1,0.4 -0.5,19.6 6.7,
         38.2 20.2,52.4 l 12.8,13.4 c 13.8,14.5 33.2,22.8 53.2,22.8 18.9,0 
         36.9,-7.2 50.6,-20.2 l 7.9,-7.5 10.5,7.9 c 14.2,13.4 32.6,20.8 52.2,
         20.8 0.1,0 0.3,0 0.4,0 20.2,-0.1 39.2,-8.1 53.5,-22.5 l 16.1,-16.3 c 
         14.2,-14.4 22,-33.5 21.9,-53.7 0,-0.8 -0.2,-1.6 -0.2,-2.4 0.8,0 1.6,0.2
         2.4,0.2 19.5,0 39.1,-7.5 53.9,-22.5 l 16.1,-16.3 c 14.2,-14.4 22,-33.5
         21.9,-53.7 0,-3.1 -0.5,-6.1 -1,-9.2 20,-0.3 38.7,-8.2 52.8,-22.4 L 
         806,745 c 15.6,-15.7 22.6,-36.6 21.5,-57.2 0.4,0 0.8,0.1 1.2,0.1 0.1,0
         0.3,0 0.4,0 20.2,-0.1 39.2,-8.1 53.5,-22.5 l 16.1,-16.3 c 14.2,-14.4 22,
         -33.5 21.9,-53.7 -0.1,-20.2 -8.1,-39.2 -22.5,-53.5 l -18,-17.8 C 935.4,481.7
         973.2,420 985.5,350.8 Z m -840.6,312 -12.8,-13.4 c -12.3,-12.9 -11.8,-33.5
         1.1,-45.9 L 261.5,481.4 c 6.1,-5.8 14,-8.9 22.3,-8.9 9,0 17.3,3.6 23.5,10.1
         l 12.8,13.4 c 11.3,11.9 11.3,29.8 1.4,42.3 -1.5,1.2 -3.1,2.1 -4.5,3.4 L 188.8,
         663.8 c -0.9,0.9 -1.6,1.9 -2.5,2.9 -12.7,8.4 -30.7,7.4 -41.4,-3.9 z m 83.8,89.9
         -12.8,-13.4 c -11.2,-11.8 -11.3,-29.6 -1.5,-42.1 1.5,-1.2 3.2,-2.3 4.6,-3.6 L 347.3,
         571.5 c 1,-1 1.7,-2.1 2.7,-3.1 5.3,-3.5 11.2,-5.9 17.7,-5.9 9,0 17.3,3.6 23.5,10 l
         12.8,13.4 c 6,6.3 9.2,14.5 8.9,23.2 -0.2,8.6 -3.8,16.7 -10.1,22.7 l -128.2,122 c -12.6,12
         -33.9,11.5 -45.9,-1.1 z m 92.1,86.5 -12.7,-13.3 c -6,-6.3 -9.2,-14.5 -9,-23.2 0.2,-8.7 3.8,-16.7 
         10.1,-22.7 L 437.5,657.8 c 6.1,-5.8 14,-8.9 22.4,-8.9 9,0 17.3,3.6 23.5,10.1 l 12.7,13.3 c 6,6.3
         9.2,14.5 9,23.2 -0.2,8.4 -3.7,16.1 -9.6,22 L 367.6,839.2 c -0.4,0.4 -0.7,0.8 -1,1.2 -12.6,11.9 -33.9,11.3 
         -45.8,-1.2 z m 132.5,90.1 c -12.5,12 -33.9,11.4 -45.8,-1.1 l -12.8,-13.4 c -6,-6.3 -9.2,-14.5 -8.9,-23.2
         0.2,-8.3 3.7,-16.1 9.5,-22 L 523.2,747.8 c 0.4,-0.4 0.6,-0.8 1,-1.1 6,-5.7 14,-8.9 22.3,-8.9 9,0 17.3,3.6 
         23.5,10 l 12.8,13.4 c 6,6.3 9.2,14.5 8.9,23.2 -0.2,8.6 -3.8,16.7 -10.1,22.7 z M 593.1,912.5 577,928.8 c
         -6.5,6.6 -15.3,10.3 -24.5,10.3 -0.1,0 -0.1,0 -0.2,0 -9.2,0 -17.9,-3.6 -24.5,-10.1 -0.7,-0.7 -1.4,-1.3 -2.2,-1.8 l
         -6,-4.5 68.2,-64.9 5.2,5.7 v 0 c 13.4,13.6 13.5,35.4 0.1,49 z m 276.6,-292.1 -16.1,16.3 c -6.5,6.6 -15.3,10.3 
         -24.6,10.3 -0.1,0 -0.1,0 -0.2,0 -8.9,0 -17.2,-3.5 -23.7,-9.5 l -137,-135.5 -0.1,0.1 -1,-1.1 -28.8,29.1 137.4,136 c 
         0.4,0.4 0.8,0.6 1.3,1 13.6,13.5 13.7,35.5 0.2,49.1 L 761,732.5 c -6.5,6.6 -15.3,10.3 -24.6,10.3 -0.1,0 -0.1,0 -0.2,0 -9.2,0 
         -17.9,-3.6 -24.5,-10.1 L 575,597.6 574.4,597 v 0 l -0.1,-0.1 -14.4,14.6 -14.6,14.4 141.6,142.8 c 6.6,6.5 10.3,15.3 10.3,24.6 0.1,9.3 
         -3.5,18 -10.1,24.6 L 671,834.1 c -13.5,13.6 -35.6,13.7 -48.5,1 l -0.5,-0.5 c -0.1,-0.1 -0.2,-0.3 -0.4,-0.5 v 0 l -4.9,-5.3 c 9.8,-12.4 15.5,-27.4 15.9,
         -43.4 0.5,-19.6 -6.7,-38.2 -20.2,-52.4 l -12.8,-13.4 c -14,-14.7 -33.9,-21.9 -53.8,-21.7 0,-0.5 0.2,-0.9 0.2,-1.4 0.5,-19.6 -6.7,-38.2 -20.2,-52.5 L 513,630.7 c
         -15.2,-16 -37.6,-23 -59.2,-21.4 0.3,-19.3 -6.8,-37.7 -20.2,-51.7 l -12.8,-13.4 c -13.4,-14 -32.1,-21.3 -51.1,-21.9 1.1,-19.5 -5.4,-39.4 -19.9,-54.7
         L 337,454.2 c -26.9,-28.1 -75.6,-29.4 -103.8,-2.5 l -66,62.8 C 95.6,471.1 51.1,392.6 51.1,308.7 51.1,176.1 159,68.1 291.6,68.1 359,68.1 421.9,96 467.4,144.9 L 265.2,347.1 c -7.4,7.4 
         -8,19.1 -1.5,27.3 49.1,61.6 106.1,93.2 169.7,93.9 100.1,0.1 186.4,-75.8 220,-110.5 l 216.2,213.3 c 6.6,6.5 10.3,15.3 10.3,24.6 -0.1,9.3 -3.7,18.1 -10.2,24.7 z M 666.9,313.7 c -4.1,-4 -9.5,-5.7 -15.4,-5.8 -5.7,0.3 -11.1,2.9 -14.7,7.3 -1,1.1
         -95,112.1 -201.4,112.1 -0.6,0 -1.2,0 -1.8,0 -45.8,-0.6 -88.1,-22.2 -126.1,-64.5 L 525.2,145 l 0.2,0.1 C 580.7,80.5 667,49.6 750.7,64.5 c 63.2,11.3 118.3,46.6 155,99.3 36.7,52.7 50.8,116.6 39.5,179.8 -10.9,61 -45,115 -94.6,151.4 z" />
        </g>
      </svg>
    </div>
  );
}



