import React from 'react';
import { Link } from "react-router-dom";
import "./HomeIntro.css";
import strategy from '../../../assets/capeark_img/home/home-stratesies.jpg'


function HomeIntro() {
  return (
    <>
      <div className='home_field_item mb-4'>
        <div className='container'>
          <div>
            <div className='mb-4 mt-2'>
              <h2 className='home_custom_h1__title'>
                <Link to="/projects" className='home_stretched_link'>
                  <p className='m-0 p-0'>We empower our clients</p>
                  <p className='m-0 p-0'>to make a difference</p>
                  <p className='m-0 p-0'>in the world  <i className='fa fa-arrow-right home_intro_icon'></i></p>
                </Link>
              </h2>
            </div>
            <div className='home_custom_quotes__title d-flex'>
              <span>Looking for inspiration?</span>
              <Link to="/projects" >Discover our work </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='home_field_item home_intro_strter_cont pt-5 pb-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12 col-md-6 col-lg-6 home_intro_card_info_img_cont_main'>
              <div className='home_intro_card_info_img_cont'>
                <div className="home_intro_content-overlay"></div>
                <img className='image_bounces_left home_intro_animated' src={strategy} alt="Teamwork" />
                <div className="home_intro_content-details home_inro_fadeIn-left">
                  <h3>Teamwork makes the dream work.</h3>
                  <p>Together, let's construct something remarkable.</p>
                </div>
              </div>
              {/* <div className='home_intro_card_info_img_cont'>
                <div className="home_intro_content-overlay"></div>
                <img className='image_bounces_left home_intro_animated' src={development} alt="" />
                <div className="home_intro_content-details home_inro_fadeIn-left">
                  <h3>This is a title</h3>
                  <p>This is a short description</p>
                </div>
              </div> */}
            </div>
            <div className='col-sm-12 col-md-6 col-lg-6 home_intro_services_cont_main pt-3'>
              <div className='home_intro_services_cont'>
                <h6 className='mb-4'>LET’S BUILD SOMETHING GREAT TOGETHER</h6>
                <Link to="/strategies-services" className='home_intro_stretched_link'>
                  <p className='m-0 p-0'><span>Strategy</span>  <i className='fa fa-arrow-right home_intro_icon_second'></i></p>
                </Link>
                <Link to="/web-design-services" className='home_intro_stretched_link'>
                  <p className='m-0 p-0'><span>Design</span>  <i className='fa fa-arrow-right home_intro_icon_second'></i></p>
                </Link>
                <Link to="/development-technology" className='home_intro_stretched_link'>
                  <p className='m-0 p-0'><span>Development</span>  <i className='fa fa-arrow-right home_intro_icon_second'></i></p>
                </Link>
                <Link to="/maintenance" className='home_intro_stretched_link'>
                  <p className='m-0 p-0'><span>Support</span>  <i className='fa fa-arrow-right home_intro_icon_second'></i></p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='home-banner-clip-two'></div>
    </>
  )
}

export default HomeIntro