import React from 'react'
import "../Government.css";
function GovenmentItroComp() {
    return (
        <React.Fragment>
            {" "}
            <div>
                <div className="container pb-5 pt-5">
                    <div className="row pb-4 pt-4">
                        <div className="col-sm-12 col-md-4 col-lg-4 ">
                            <div className="industry_govt_design_desc_card_main">
                                <h2>Customized Search</h2>
                                <p>
                                    To ensure an optimized search experience, we work to understand the content prioritization, create search filters adapted to the habits of your visitors and customize the results ordering and show the most relevant content first.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 ">
                            <div className="industry_govt_design_desc_card_main">
                                <h2>Content strategy</h2>
                                <p>
                                    By focusing on audience needs, we’ll work with your content team to help structure new content and streamline the process of adding this content to your CMS.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 ">
                            <div className="industry_govt_design_desc_card_main">
                                <h2>Website accessibility</h2>
                                <p>
                                    To make the website accessible to as broad an audience as possible, we address accessibility during design, development, and QA. Our websites are built inline with the latest WCAG AA guidelines and AODA standards.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 ">
                            <div className="industry_govt_design_desc_card_main">
                                <h3>Flexibility Through an Open Source CMS</h3>
                                <p>
                                    We build platforms that have the flexibility to adapt to your ever-changing communications and digital needs. Leveraging an open source CMS, we develop flexible infrastructures that will keep pace and evolve as the needs of your constituents change and new services are brought online. It will be a tool that your staff use to effect change rather than a static website that is finished the day it launches.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 ">
                            <div className="industry_govt_design_desc_card_main">
                                <h3>A Collaborative Approach</h3>
                                <p>
                                    Government websites are important hubs that need to meet the varied needs of their constituents. When building government websites, we take into account:
                                    <ol className="industry_govt_design_desc_card_main_ol">
                                        <li> Search experience across a wide range of content and user needs</li>
                                        <li>Content accessibility</li>
                                        <li>An inclusive approach to design</li>
                                        <li>User experience, tailored to the needs of constituents</li>
                                        <li>Responsive, mobile-first design</li>
                                        <li>Multilingual content and translation workflows</li>
                                        <li>Security and privacy standards</li>

                                    </ol>
                                </p>
                                <h6 className='industry_govt_design_desc_card_h6'>Capeark will ensure long-term project success.</h6>
                                <p>Together, we’ll create a streamlined solution that leverages your team’s capabilities and build a user-centric experience for your constituents.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default GovenmentItroComp;