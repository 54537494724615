import React from "react";
import { Link } from "react-router-dom";
import "./index.css";

function ReadmoreLink({ link, linkText, style }) {
  return (
    <>
      <Link to={link} className="readmore_arrow_link">
        <div style={{ ...style }}>
          <div>{linkText}</div> <div className="readmore_arrow"></div>
        </div>
      </Link>
    </>
  );
}

export default ReadmoreLink;
