import React from 'react'
import "../NonProfit.css";

function NonProfitIntro() {
    return (
        <React.Fragment>
            <div>
                <div className="container pb-5 pt-5">
                    <div className="row pb-4 pt-4">
                        <div className="col-sm-12 col-md-4 col-lg-4 ">
                            <div className="industry_non_profit_design_desc_card_main">
                                <h2>Donations</h2>
                                <p>
                                    Increase donations with form builders and payment integration to give you the ability to accept online donations for your initiatives.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 ">
                            <div className="industry_non_profit_design_desc_card_main">
                                <h2>Event Enrollment</h2>
                                <p>
                                    Integrate event registration, ticket purchases, video conferences or live feeds on your website to bring people together.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 ">
                            <div className="industry_non_profit_design_desc_card_main">
                                <h2>Member-Only</h2>
                                <p>
                                    Connect to your community with members-only areas that include content such as newsletters, forums and events.
                                </p>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </React.Fragment>
    )
}

export default NonProfitIntro