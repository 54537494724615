import React, { useEffect, useState } from 'react'
import "./BlogDetailMain.css";
import { Link, useParams } from 'react-router-dom';
import ButtonHover from '../../Helpers/ButtonHover';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { FaXTwitter } from "react-icons/fa6";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { AllBlogData } from '../../../helpers/dataFetcher/Blog/AllBlogData';
import { getTransformedText } from '../../Helpers/getTransforemedText';

function BlogDetailMain() {
    const [blogDetails, setBlogDetails] = useState({})

    const { blog_id } = useParams()
    useEffect(() => {
        getBlogDetails()
    }, [blog_id])

    function getBlogDetails() {
        const newLayout = AllBlogData?.filter((item) => {
            if (item?.id === blog_id) {
                return item;
            }
        });
        setBlogDetails(newLayout[0]);
    }


    // async function getBlogDetails() {
    //     const { data, status } = await getBlogById(blog_id)
    //     setBlogDetails(data?.data)
    // }





    return (
        <React.Fragment>
            <div className='blog_detail_hero_cont'>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-10 col-lg-8 blog_detail_hero_content_cont">
                            <div>
                                <ButtonHover btnLink={"/blogs"} btnText={"Go Back"} />
                            </div>
                            <div className='blog_detail_content_text mt-5'>
                                <h1>{blogDetails?.headline}</h1>
                            </div>
                            <div className='blog_detail_extra_content'>
                                <span>{blogDetails?.date} </span>&#x2022;<span> {blogDetails?.duration} </span>
                            </div>
                        </div>
                        <div className="blog_detail_img_container col-sm-12 col-md-10">
                            <img decoding="async" src={blogDetails?.poster_url} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="container blog_detail_main_cont ">
                    <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-10 col-lg-8">
                            <h3>
                                {blogDetails?.description_headline}
                            </h3>
                            <p style={{ whiteSpace: "pre-line", lineHeight: "30px",  }} className='blog_detail_main_con_p'>
                                {getTransformedText(blogDetails?.description)}
                                
                            </p>
                        </div>
                        <div>
                            <hr className="wp-block-separator has-alpha-channel-opacity is-style-dots" />
                        </div>
                        <div className="col-sm-12 col-md-10 col-lg-8 blog_detail_main_share_cont">
                            <h2 className='mb-3'>Share Article</h2>
                            <div>
                                <Link to={`https://twitter.com/intent/tweet?url=https%3A%2F%2Fcapeark.com%2Fblog%2F${blog_id}`} target="_blank">
                                    <FaXTwitter fontSize='medium' />
                                </Link>
                                <Link to={`https://www.linkedin.com/sharing/share-offsite/&url=https%3A%2F%2Fcapeark.com%2Fblog%2F${blog_id}`} target="_blank">
                                    <LinkedInIcon fontSize='medium' />
                                </Link>
                                <Link to={`https://www.facebook.com/sharer.php?u=https%3A%2F%2Fcapeark.com%2Fblog%2F${blog_id}`} target="_blank">
                                    <FacebookIcon fontSize='medium' />
                                </Link>
                                {/* <Link to={`https://www.linkedin.com/sharing/share-offsite/&url=https%3A%2F%2Fcapeark.com%2Fblog%2F${blog_id}`} target="_blank">
                                    <InstagramIcon fontSize='medium' />
                                </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default BlogDetailMain;