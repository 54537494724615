import React from 'react';
import { Helmet } from 'react-helmet';
import './Maintenance.css';
import ContactBottom from '../../Helpers/ContactBtn/ContactBottom';
import MaintenanceBanner from './Pages/MaintenanceBanner';
import MaintenanceIntro from './Pages/MaintenanceIntro';


function Maintenance() {
    return (
        <React.Fragment>
            <Helmet>
                <meta charset="utf-8" />
                <title>
                    Capeark | Maintanance & Support Services
                </title>
                <meta charset="utf-&" />
                <meta name="description" content="Maintanance & Support Services, Your Launch Is Just The Beginning" />
                <meta name="keywords" content="Web development, App development, Mern stack, Mean stack, Audit Services, Strategy, Digital Products & Experience Designs, Ongoing Support" />
                <meta name="author" content="Capeark" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div className="service_header_main_cont">
                <div className="container mt-4 mb-5">
                    <div className="row mt-5">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <h6 className="service-page-header-title-text text-white">
                                MAINTENANCE, OPTIMIZATION, TRAINING
                            </h6>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                            <h1 className="service-page-header-subtitle-text text-white">
                                Your Launch Is Just The Beginning
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="project-banner-clip-one"></div>
            <div className="">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <p className="service_intro_text">
                                Innovative strategies that cut through digital noise.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <MaintenanceBanner />
            </div>
            <div>
                <MaintenanceIntro />
            </div>
            <div>
                <ContactBottom
                    btnLink={"/contact"}
                    btnText={"Contact Capeark"}
                    BannerText={""} />
            </div>
        </React.Fragment>
    )
}

export default Maintenance;