import React from 'react';
import "./index.css";

function JobDetailsPage({ jobDetails, setTabValue }) {
    return (
        <React.Fragment>
            {jobDetails?.job_description && (
                <div className='job_details_content_sec_main pt-3 mb-3'>
                    <h6>Description</h6>
                    <p style={{whiteSpace: "pre-wrap"}}>{jobDetails?.job_description}</p>
                </div>
            )}

            {jobDetails?.roles_in_job?.roles && (
                <div className='job_details_content_sec_main pt-3 mb-3'>
                    <h6>
                        In your role as {jobDetails?.job_name}, you will be expected to:
                    </h6>
                    <ul>
                        {jobDetails?.roles_in_job?.roles?.map((item, i) => (
                            <li key={i}>{item?.key}</li>
                        ))}
                    </ul>
                </div>
            )}
            {jobDetails?.requirement?.specialization && (
                <div className='job_details_content_sec_main pt-3 mb-3'>
                    <h6>Requirements</h6>
                    <ul>
                        {jobDetails?.requirement?.specialization?.map((item, i) => (
                            <li key={i}>{item?.key}</li>
                        ))}
                    </ul>
                </div>
            )}
            {jobDetails?.bonus?.bonus && (
                <div className='job_details_content_sec_main pt-3 mb-3'>
                    <p>Bonus for:</p>
                    <ul>
                        {jobDetails?.bonus?.bonus?.map((item, i) => (
                            <li key={i}>{item?.key}</li>
                        ))}
                    </ul>
                </div>
            )}
            {jobDetails?.benifits?.person_fit && (
                <div className='job_details_content_sec_main pt-3 mb-3'>
                    <h6>Benefits</h6>
                    <p>What kind of person usually gets along easily with us?</p>
                    <ul>
                        {jobDetails?.benifits?.person_fit?.map((item, i) => (
                            <li key={i}>{item?.key}</li>
                        ))}
                    </ul>
                </div>
            )}
            {jobDetails?.benifits?.what_you_get && (
                <div className='job_details_content_sec_main pt-3 mb-3'>
                    <h6>What you get:</h6>
                    <ul>
                        {jobDetails?.benifits?.what_you_get?.map((item, i) => (
                            <li key={i}>{item?.key}</li>
                        ))}
                    </ul>
                </div>
            )}

            <div className='job_details_content_sec_apply_btn_main mt-5'>
                <div onClick={() => setTabValue("1")}>Apply for this job</div>
            </div>
        </React.Fragment>
    )
}

export default JobDetailsPage;