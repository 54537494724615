import React from "react";
import { Helmet } from 'react-helmet';
import ContactBottom from "../../Helpers/ContactBtn/ContactBottom";
import HealthCareBanner from "./Pages/HealthCareBanner";
import HealthCareIntro from "./Pages/HealthCareIntro";
import HealthCareTextBanner from "./Pages/HealthCareTextBanner";
import stareBanner from "../../../assets/Images/General/stare_banner.jpeg";
function HealthCare() {
  return (
    <React.Fragment>
      <Helmet>
        <meta charset="utf-8" />
        <title>
          Capeark | Website Development for Health Care
        </title>
        <meta charset="utf-&" />
        <meta name="description" content="Health Care Agencies, Taking Your Public Health Services and Information Digital" />
        <meta name="keywords" content="Government, Municipality, Higer education, Non profit, Health care, Enterprises" />
        <meta name="author" content="Capeark" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="row position-relative industries-top-banner g-0">
        <img
          src={stareBanner}
          className="img-fluid p-0"
          alt=""
        />
        <div className="col-12 text-center industries-banner-content d-flex justify-content-center">
          <div className="align-self-center industries-banner-content-child">
            <h1>HEALTHCARE</h1>
            <p>Taking Your Public Health Services and Information Digital</p>
          </div>
        </div>
        <div className="industires-banner-clip-img">
          <div className="industires-banner-clip-img-div"></div>
        </div>
      </div>
      <div>
        <HealthCareBanner />
      </div>

      <div>
        <HealthCareIntro />
      </div>
      <div>
        <HealthCareTextBanner />
      </div>
      <div>
        <ContactBottom
          btnLink={"/contact"}
          btnText={"Reach Out to Our Team"}
          textVarient="h1"
          BannerText={"We Look Forward to Working with You"}
        />
      </div>
    </React.Fragment>
  );
}

export default HealthCare;
