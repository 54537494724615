import React from 'react'
import './css/About.css'
import ButtonHover from "../Helpers/ButtonHover";
import aboutLeftImg from "../../assets/capeark_img/about/aboutmission.jpg"
import aboutvision from "../../assets/capeark_img/about/aboutvision.jpg"


function AboutMessage() {
    return (
        <React.Fragment>
            <section>
                <div className="service_banner_item_cont_main">
                    <div className="service_banner_item_bg_cont">
                        <div className="row service_banner_item_content_cont pt-5 pb-5 ">
                            <div className="col-sm-12 col-md-6 col-lg-6 service_banner_hover_cont service_banner_column">
                                <figure className="p-0 m-0 service_banner_img_cont">
                                    <img
                                        src={aboutLeftImg}
                                        alt=""
                                    />
                                </figure>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 service_banner_desc_cont">
                                <div className="about-banner-banner_desc_media_body p-5">
                                    <h2>
                                        Our Mission
                                    </h2>
                                    <p>
                                        Through continuous innovation, strategic partnerships, and a dedicated commitment to excellence, we aim to revolutionize the IT industry and set new standards for unparalleled customer satisfaction on a global scale.
                                    </p>
                                    <p>
                                        By fostering innovation and staying ahead of industry trends, we aim to drive digital transformation for our clients, enabling them to gain a competitive edge in the rapidly evolving IT landscape.
                                    </p>

                                    <div className="mt-4">
                                        <ButtonHover
                                            btnLink={"/services"}
                                            btnText={"Find Out More"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service_banner_item_cont_main_right">
                    <div className="service_banner_item_bg_cont_right capeark-bg">
                        <div className="row right_banner_flex service_banner_item_content_cont_right pt-5 pb-5 g-0">
                            <div className="col-sm-12 col-md-6 col-lg-6 service_banner_desc_cont_right">
                                <div className="service_banner_desc_media__body">
                                    <h2 className="service_banner_desc_media__title mb-4 text-white">
                                        Our Vision
                                    </h2>
                                    <div className="service_banner_desc_media__text text-white">
                                        <p>
                                            Capeark will be the first choice solution partner in the technology sector providing IT and Communication services to businesses throughout the region. We will become an integral part of our client’s success, collaborating with them to achieve their strategic objectives whilst creating long lasting business value through the delivery and management of their technology.
                                        </p>
                                        <p>
                                            Our team of talented, experienced and motivated industry specialists will be recognised as the best in their field. Our continued success will be driven by our people who will share in it.
                                        </p>
                                    </div>
                                    <div className="mt-4">
                                        <ButtonHover
                                            btnLink={"/services"}
                                            btnText={"Find Out More"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 service_banner_hover_cont service_banner_column_right">
                                <figure className="p-0 m-0 service_banner_img_cont">
                                    <img
                                        src={aboutvision}
                                        alt=""
                                    />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>


            </section>
        </React.Fragment>
    )
}

export default AboutMessage
