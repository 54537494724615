import React from "react";
import Carousel from "./Pages/Carousel";
import HomeIntro from "./Pages/HomeIntro";
import IndustryIntro from "./Pages/IndustryIntro";
import CompanyDelInfo from "./Pages/CompanyDelInfo";
import HomeFooterContent from "./Pages/HomeFooterContent";
import HomeMessage from "./Pages/HomeMessage";
import { Helmet } from 'react-helmet';
import HomeIntroBlockOne from "./Pages/HomeIntroBlockOne";
import ClientReviews from "./Pages/ClientReviews";
function Home() {
  const collection = [
    { src: "https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?cs=srgb&dl=pexels-pixabay-268533.jpg&fm=jpg", caption: "Caption one" },
    { src: "https://uploads.codesandbox.io/uploads/user/d82bb611-d5d7-4aa5-84e8-b56504285062/M1v0-06.jpg", caption: "Caption two" },
    { src: "https://thumbs.dreamstime.com/b/beautiful-rain-forest-ang-ka-nature-trail-doi-inthanon-national-park-thailand-36703721.jpg", caption: "Caption three" },
    { src: "https://uploads.codesandbox.io/uploads/user/d82bb611-d5d7-4aa5-84e8-b56504285062/M1v0-06.jpg", caption: "Caption four" },
    { src: "https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?cs=srgb&dl=pexels-pixabay-268533.jpg&fm=jpg", caption: "Caption five" },
    { src: "https://uploads.codesandbox.io/uploads/user/d82bb611-d5d7-4aa5-84e8-b56504285062/M1v0-06.jpg", caption: "Caption six" }
  ];
  return (
    <React.Fragment>
      <Helmet>
        <meta charset="utf-8" />
        <title>
          Capeark | Home
        </title>
        <meta name="description" content="Capeark is a leading software development company in India. We excel at enhancing businesses and transforming people's lives." />
        <meta name="keywords" content="Web development, App development, Mern stack, Mean stack, Audit Services, Strategy, Design, Development, Support, Digital Products & Experience Designs, Ongoing Support" />
        <meta name="author" content="Capeark" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div>
        <Carousel input={collection} ratio={`3:2`} mode={`manual`} />
      </div>
      <div>
        <HomeIntro />
      </div>
      <div>
        <CompanyDelInfo />
      </div>
      <div>
        <IndustryIntro />
      </div>
      <div>
        <HomeMessage />
      </div>
      <div>
        <HomeIntroBlockOne />
      </div>
      <div>
        <ClientReviews />
      </div>
      <div>
        <HomeFooterContent />
      </div>

    </React.Fragment>
  );
}

export default Home;
