import React, { useState, useEffect } from 'react';
import "./index.css";
import { useSearchParams } from "react-router-dom";


function BlogFilterTab({ page, filterData, getBlogData }) {
    const [tabValue, setTabValue] = useState("all");
    let [searchParams, setSearchParams] = useSearchParams();

    const searchUrl = window.location.search;

    const handleOnChange = (tab) => {
        setTabValue(tab);
        getBlogData(page, tab);
        setSearchParams(params => {
            params.set("category", tab);
            return params;
        });
    }


    useEffect(() => {
        if (searchUrl) {
            setTabValue(searchUrl?.split("=")[1])
        } else {
            setSearchParams(params => {
                params.set("category", searchUrl?.split("=")[1]);
                return params;
            });
            setTabValue(searchUrl?.split("=")[1]);
        }
        handleOnChange(tabValue);
    }, [searchUrl])

    return (
        <React.Fragment>
            <div className='container mb-4 mt-4'>
                <div className='row'>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                        <h5 className='m-2 mb-3'>Browse our blog by category</h5>
                        <div className="project_custom_TabContext">
                            <div className="blog_custom_tab_list_cont">
                                <div className={`blog_custom_tab_list ${tabValue === "all" ? "blog_custom_tab_list_active" : ""}`}
                                    onClick={(e) => handleOnChange('all')}>
                                    <div>All </div>
                                </div>

                                {filterData?.map((item, index) => (
                                    <>
                                        {item && (
                                            <div key={index} className={`blog_custom_tab_list ${tabValue === item ? "blog_custom_tab_list_active" : ""}`}
                                                onClick={(e) => handleOnChange(item)}>
                                                <div>{item}</div>
                                            </div>
                                        )}


                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default BlogFilterTab