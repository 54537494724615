import React, { useEffect, useState } from 'react'
import './css/Careers.css'
import './css/jobsOpening.css'
import { HiOutlineLocationMarker } from "react-icons/hi";
import { AiOutlineClockCircle, } from "react-icons/ai";
import ButtonHover from '../Helpers/ButtonHover';
import { getAllJobs } from '../../helpers/dataFetcher/Jobs/Jobs';
import MainLoader from '../../helpers/loader/Loader';

import Pagination from '@mui/material/Pagination';
import emptyjob from "../../assets/Images/General/emptyjob.png"

function CurrentOpenings() {

    const [jobData, setJobData] = useState([]);
    const [load, setLoad] = useState(false);
    const [pages, setPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1)
    async function fetchAllJobs(page) {
        setLoad(true);
        const { data, status } = await getAllJobs(page);
        setJobData(data?.data)
        setPages(data?.pages)
        if (status === 200) {
            setLoad(false);
        }
        setLoad(false);
    }

    useEffect(() => {
        fetchAllJobs();
    }, [])


    const handlePageChange = (event, value) => {
        fetchAllJobs(value)
        setCurrentPage(value)
    }


    return (
        <React.Fragment>
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="job-item p-4" >
                        {load ? (
                            <MainLoader />
                        ) : (
                            <>
                                {jobData && jobData?.length === 0 ? (
                                    <>
                                        <div className='no_job_div_cont'>
                                            <div>
                                                <img src={emptyjob} alt="" />
                                            </div>
                                            <h4>Stay tuned, exciting opportunities are on the way!</h4>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {jobData?.map((item, index) => (
                                            <div className="border-bottom d-flex gap-3 mb-5 mt-2 wow bounceInLeft col-12 animated g-0 p-0" style={{ visibility: "visible" }}>
                                                <div className="proPic float-left align-self-center ">
                                                    <img src={item?.job_logo} className="img-fluid" width="100" alt='' />
                                                </div>
                                                <div className="align-self-center w-100 bg-light p-4">
                                                    <h3 className="border-bottom pb-2 font-weight-light">{item?.job_name}</h3>
                                                    <div className="row g-0">
                                                        <div className='col-sm-12 col-lg-10 col-md-8 g-0'>
                                                            <span className="likes mr-3"><HiOutlineLocationMarker aria-hidden="true" className='job-opening-icon' /> &nbsp; {item?.job_location}</span>
                                                            <span className="photo"><AiOutlineClockCircle aria-hidden="true" className='job-opening-icon' />&nbsp; {item?.job_type}</span>
                                                        </div>
                                                        <div className='col-sm-12 col-lg-2 col-md-4 g-0 d-flex justify-content-end'>
                                                            <span className="comments">
                                                                <ButtonHover
                                                                    btnLink={`/apply-now/${item?._id}`}
                                                                    btnText={'Apply Now'}
                                                                />
                                                            </span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    {jobData && jobData?.length === 0 ? null : (
                        <div className='row mt-5 mb-5'>
                            <div className='col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center'>
                                <Pagination
                                    count={pages}
                                    variant="outlined"
                                    color="primary"
                                    page={currentPage}
                                    onChange={handlePageChange}
                                />
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </React.Fragment>
    )
}

export default CurrentOpenings




