import * as React from "react";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import "./SavingProgress.css";

// Inspired by the former Facebook spinners.
function SavingProgress() {
  return (
    <Box
      sx={{
        position: "relative",
      }}
      className="saving_progress_main_cont"
    >
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) => (theme.palette.mode === "#fff" ? "#fff" : "#fff"),
          animationDuration: "550ms",
          position: "relative",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={22}
        thickness={4}
      // {...props}/
      />
    </Box>
  );
}
export default SavingProgress;
