import React from 'react'
import "./index.css";
import galaxybanner from "../../../assets/capeark_img/project/galaxyenterprise/galaxybanner.jpg"
import fulllogo from "../../../assets/capeark_img/project/galaxyenterprise/FullLogo.png"
import galaxyone from "../../../assets/capeark_img/project/galaxyenterprise/galaxyone.jpeg"
import galaxytwo from "../../../assets/capeark_img/project/galaxyenterprise/galaxytwo.jpeg"
import galaxythree from "../../../assets/capeark_img/project/galaxyenterprise/galaxythree.jpeg"
import galaxyfour from "../../../assets/capeark_img/project/galaxyenterprise/galaxyfour.jpeg"
import ButtonHover from '../../Helpers/ButtonHover';
import { Helmet } from 'react-helmet';
function GalaxyEnterprisePage() {
    return (
        <React.Fragment>
            <Helmet>
                <meta charset="utf-8" />
                <title>Capeark | Galaxy Enterprise</title>
                <meta charset="utf-&" />
                <meta name="description" content="Welcome to Galaxy Enterprise, where craftsmanship meets innovation to redefine your living spaces." />
                <meta name="keywords" content="Web development, App development, Mern stack, Mean stack, Strategy, Digital Products & Experience Designs, Ongoing Support, technology" />
                <meta name="author" content="Capeark" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div className="project_details_banner_cont" style={{ background: `url(${galaxybanner})` }}>
                <div className="project_details_banner_overlay">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-sm-6 col-md-3 col-lg-2 project_details_banner_logo'>
                                <div>
                                    <img src={fulllogo} alt='' />
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                                <div className='mt-2 project_details_banner_details'>
                                    <h1>
                                        Designing a Unified Digital Presence for Windows & Doors Solution
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className='container mt-5 mb-5'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12 col-md-12 project_detail_overview_cont mb-3'>
                            <h6>
                                ABOUT THE CLIENT
                            </h6>
                            <p >
                                <a href="https://galaxyenterprise.co" style={{ textDecoration: "none", color: "black", fontWeight: "700" }} target="_blank">Galaxy enterprise</a> is a dynamic online platform dedicated to showcasing a comprehensive range of windows, doors, and glass-related solutions. With a commitment to excellence in manufacturing and a passion for innovation, Galaxy Enterprise aims to provide a one-stop destination for individuals, businesses, and professionals seeking top-quality windows and doors, as well as advanced glass solutions for various applications. As a full-service company, we take pride in offering a comprehensive range of services, from manufacturing to installation and beyond, making Galaxy Enterprise your trusted partner for all windows, doors, and glass-related solutions, whether it's for residential, commercial, or industrial projects.
                            </p>


                            <div className='project_detail_overview_img_cont'>
                                <img src={galaxyone} alt="" />
                            </div>
                        </div>

                        <div className='col-sm-12 col-lg-12 col-md-12 project_detail_overview_cont'>
                            <h6>
                                Mission and Vision
                            </h6>
                            <p className="mb-2">
                                Our mission at <a href="https://galaxyenterprise.co" style={{ textDecoration: "none", color: "black", fontWeight: "700" }} target="_blank">Galaxy enterprise</a> is to simplify the process of enhancing spaces with top-tier windows, doors, and glass solutions. We envision a future where our expertise and dedication to excellence not only redefine the standards in the industry but also make a significant impact on the comfort, security, and aesthetics of buildings worldwide. Our primary goal is to offer a wide array of products that combine cutting-edge technology, exceptional craftsmanship, and sustainable materials, with the ultimate vision of becoming a global leader in providing superior solutions for residential, commercial, and industrial spaces.
                            </p>

                            <div className='project_detail_overview_img_cont'>
                                <img src={galaxyfour} alt="" />
                            </div>
                        </div>

                        <div className='col-sm-12 col-lg-12 col-md-12 project_detail_overview_cont mb-3'>
                            <h6>
                                Key Features
                            </h6>
                            <ul className="" style={{ display: "flex", flexDirection: "column", gap: "14px" }}>
                                <li><strong>Comprehensive Product Catalog</strong> - Galaxy Enterprise boasts an extensive catalog of windows, doors, and glass solutions, catering to diverse needs and preferences. The product range includes energy-efficient windows, customizable doors, decorative glass panels, and much more.</li>
                                <li><strong>Customization Options</strong> - Recognizing that every project is unique, Galaxy Enterprise offers a range of customization options. Customers can choose from various materials, finishes, sizes, and styles to ensure their products perfectly match their requirements.</li>
                                <li><strong>Innovation and Technology</strong> - The platform constantly stays at the forefront of technological advancements in the industry. It features state-of-the-art products that emphasize energy efficiency, security, and aesthetic appeal.</li>
                                <li><strong>Educational Resources</strong> - Galaxy Enterprise aims to educate its customers about the benefits and features of its products. The website includes articles, guides, and videos that help users make informed decisions.</li>
                                <li><strong>Sustainability</strong> - As part of its commitment to environmental responsibility, Galaxy Enterprise emphasizes sustainable manufacturing practices, including the use of eco-friendly materials and energy-efficient production processes.</li>
                                <li><strong>Professional Services</strong> - In addition to serving individual consumers, Galaxy Enterprise offers specialized services for architects, builders, and contractors. The platform provides tools and resources to simplify the procurement process for large-scale projects.</li>
                                <li><strong>Quality Craftsmanship</strong> - Our products are crafted with precision and attention to detail. We prioritize quality materials and manufacturing processes to deliver products that stand the test of time.</li>
                                <li><strong>Expert Consultation</strong> - Galaxy Enterprise offers professional guidance to help you make informed decisions. Our experienced team provides valuable insights and recommendations to meet your project goals.</li>
                                <li><strong>Energy Efficiency</strong> - We offer a range of energy-efficient windows and doors that can reduce energy consumption, enhance insulation, and contribute to a more sustainable environment.</li>
                                <li><strong>Security Solutions</strong> - Galaxy Enterprise understands the importance of safety. We provide robust security options to protect your property and loved ones.</li>
                            </ul>
                            <div className='project_detail_overview_img_cont'>
                                <img src={galaxythree} alt="" />
                            </div>

                            <h6>
                                Benefits for Customers
                            </h6>

                            <ul className="project_details_desc_list" style={{ listStyle: "none" }}>
                                <li>A single point of contact for all your windows, doors, and glass needs.</li>
                                <li>Tailored solutions that align with your vision and requirements.</li>
                                <li>Assurance of top-quality products and craftsmanship.</li>
                                <li>Expert advice and support throughout your project.</li>
                                <li>Opportunities to improve energy efficiency and security.</li>
                                <li>Peace of mind knowing your project is in capable hands.</li>
                            </ul>
                        </div>
                        <div className='col-sm-12 col-lg-12 col-md-12 project_detail_overview_cont'>

                            <div className='project_detail_overview_img_cont'>
                                <img src={galaxytwo} alt="" />
                            </div>


                            <h6>
                                ROLE OF CAPEARK
                            </h6>
                            <p>
                                Capeark, a renowned web development agency, had the privilege of collaborating with <a href="https://galaxyenterprise.co" style={{ textDecoration: "none", color: "black", fontWeight: "700" }} target="_blank">Galaxy enterprise</a> in late 2023 to embark on an exciting project - the creation of their new website. This partnership was initiated with the goal of designing a visually captivating and highly functional platform that would serve as a digital showcase for Galaxy Enterprise's extensive range of products and services. <br />
                                Our team at Capeark approached this project with unwavering dedication and expertise in user interface design. We understood the importance of creating an online presence that not only highlighted Galaxy Enterprise's offerings but also provided an intuitive and engaging user experience for their website visitors.
                            </p>
                            <p className="mb-5">
                                <strong>Services</strong>: Web Development, Maintenance and Support, Discovery, SEO audit
                            </p>

                            <h6>
                                Goals
                            </h6>
                            <p>Capeark had aim to achieve during the creation of the Galaxy Enterprise website:</p>
                            <ul className="project_details_desc_list" style={{ listStyle: "none", display: "flex", flexDirection: "column", gap: "14px" }}>
                                <li><strong>Enhanced User Experience</strong> -  The primary goal was to create a website that offered an exceptional user experience.</li>
                                <li><strong>Highlighted Expertise</strong> -  We emphasized Galaxy Enterprise's expertise in manufacturing and innovation within the windows and doors industry. This was achieved through informative content, case studies, and testimonials.</li>
                                <li><strong>Optimized for SEO</strong> -  We created content with search engine optimization (SEO) in mind to improve the website's visibility on search engines. This included optimizing product descriptions, metadata, and ensuring a mobile-responsive design.</li>
                                <li><strong>Established Credibility</strong> -  We built trust and credibility for Galaxy Enterprise by prominently displaying certifications, awards, and industry affiliations. This reassured potential customers of the company's reliability.</li>
                                <li><strong>Generated Leads</strong> -  We developed a lead generation strategy by offering downloadable resources like e-books, guides, or newsletters in exchange for visitor contact information. Lead capture forms were strategically implemented throughout the website.</li>
                                <li><strong>Promoted Sustainability</strong> -  If Galaxy Enterprise prioritized sustainability, we highlighted their eco-friendly practices and products. We created a dedicated section on the website to showcase their commitment to environmental responsibility.</li>
                                <li><strong>Ensured Mobile-Friendly Design</strong> -  We ensured that the website was fully responsive and optimized for mobile devices, as a significant portion of web traffic came from smartphones and tablets.</li>
                                <li><strong>Implemented Security Measures</strong> -  We implemented robust security measures to protect user data and ensure a safe browsing experience. The website was secured against potential cyber threats.</li>
                                <li><strong>Optimized Speed and Performance</strong> -  We continuously optimized the website's speed and performance to reduce loading times and provide a seamless experience for users.</li>
                                <li><strong>Provided Content Management</strong> -  We provided Galaxy Enterprise with an easy-to-use content management system (CMS) that allowed them to update and curate content regularly without technical barriers.</li>
                            </ul>

                            <h6>Results</h6>
                            <p>
                                We optimized the website for search engines, ensuring high visibility, and implemented robust security measures to protect user data. The mobile-friendly design catered to users on various devices. <br />
                                This collaboration resulted in the successful delivery of a website that not only reflected Galaxy Enterprise's brand identity but also established a digital presence that would enable them to reach a broader audience and further establish themselves as industry leaders. Our commitment to efficiency even led to the project's completion ahead of schedule, allowing Galaxy Enterprise to showcase their products and services sooner than expected.
                                <br />
                                Capeark is proud to have played a role in enhancing Galaxy Enterprise's digital presence and looks forward to continued collaboration in the future.
                            </p>

                        </div>
                        <div className="mt-4 project_details_contact_btn" >
                            <ButtonHover
                                btnLink={"/contact"}
                                btnText={"Get in Touch"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default GalaxyEnterprisePage;