import React from 'react'
import highEducation from '../../../../assets/capeark_img/industry/HigherEducationBanner.jpg'
function HigherEducationBanner() {
    return (
        <React.Fragment>
            <div className="service_banner_item_cont_main">
                <div className="service_banner_item_bg_cont">
                    <div className="row service_banner_item_content_cont pt-5 pb-5 ">
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_hover_cont service_banner_column">
                            <figure className="p-0 m-0 service_banner_img_cont">
                                <img
                                    src={highEducation}
                                    alt=""
                                />
                            </figure>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_desc_cont">
                            <div className="service_banner_desc_media__body">
                                <h2 className="service_banner_desc_media__title mb-4">
                                    We Understand Higher Education
                                </h2>
                                <div className="service_banner_desc_media__text">
                                    <p>Are you effectively reaching your student recruitment goals? Does your website live up to your institution’s reputation? Is it suited to your staff’s needs? <br /><br />  Higher education institutions face unique challenges when it comes to building successful online experiences.</p>
                                    <ul className="service_banner_desc_list ">
                                        <li>Rapid digital transformation—not to mention the move to online learning</li>
                                        <li>Multiple stakeholders with diverse needs</li>
                                        <li>A multitude of systems that need to be connected</li>
                                        <li>A large amount of content across websites that need to be consolidated and optimized</li>
                                        <li>The surge of activity at key points in the academic calendar</li>
                                    </ul>
                                    <p className='fw-bold'>We turn those challenges into opportunities.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default HigherEducationBanner;