import React from 'react';
import { Helmet } from 'react-helmet';
import "./WebDesignServices.css";
import WebDesignBanner from './pages/WebDesignBanner';
import WebDesignDesc from './pages/WebDesignDesc';
import ContactBottom from '../../Helpers/ContactBtn/ContactBottom';

function WebDesignServices() {
    return (
        <React.Fragment>
            <Helmet>
                <meta charset="utf-8" />
                <title>
                    Capeark | Web Design Services
                </title>
                <meta charset="utf-&" />
                <meta name="description" content="Web Design Services, Realize Your Vision, Design for Delight" />
                <meta name="keywords" content="Web development, App development, Mern stack, Mean stack, Audit Services, Strategy, Digital Products & Experience Designs, Ongoing Support" />
                <meta name="author" content="Capeark" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div className="service_header_main_cont">
                <div className="container mt-4 mb-5">
                    <div className="row mt-5">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <h6 className="service-page-header-title-text text-white">
                                Realize Your Vision
                            </h6>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                            <h1 className="service-page-header-subtitle-text text-white">
                                Design for Delight
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="project-banner-clip-one"></div>
            <div>
                <WebDesignBanner />
            </div>
            <div>
                <WebDesignDesc />
            </div>
            <div>
                <ContactBottom
                    btnLink={"/contact"}
                    btnText={"Contact Capeark"}
                    BannerText={"BRING YOUR WEB DESIGN VISION TO LIFE"}
                />
            </div>
        </React.Fragment>
    )
}

export default WebDesignServices;