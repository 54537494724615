import React from "react";
import "./index.css";

function DevTechProcessIntro() {
    return (
        <React.Fragment>
            {" "}
            <div>
                <div className="container pb-5 pt-5">
                    <div className="dev_tech_desc_header_main">
                        <h2>OUR DEVELOPMENT PRINCIPLES:</h2>
                        <p>
                            Our technical team has a defined ethic that underpins all our
                            processes, decisions, and deliverables.
                        </p>
                    </div>
                    <div className="row pb-4 pt-4">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                            <div className="dev_tech_desc_card_main">
                                <h2>SUSTAINABLE</h2>
                                <p>
                                    Everything we build provides the best value for as long as
                                    possible. We balance your big-picture vision with your
                                    priorities and constraints to get the job done.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3 ">
                            <div className="dev_tech_desc_card_main">
                                <h2>HIGH-QUALITY</h2>
                                <p>
                                    Our team focuses on delivering quality over quantity. We’re
                                    dedicated to raising the bar for design and development
                                    excellence.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3 ">
                            <div className="dev_tech_desc_card_main">
                                <h2>HUMAN-CENTERED</h2>
                                <p>
                                    We value the human experience and deliver accessible websites
                                    by default, and contribute to the open source community.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-3 ">
                            <div className="dev_tech_desc_card_main">
                                <h2>INNOVATIVE</h2>
                                <p>
                                    Our team is constantly exploring the latest technology and
                                    best practices. We bring fresh knowledge and extensive
                                    expertise to your project.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="dev_tech_desc_header_main">
                        <h2>OUR DEVELOPMENT PROCESS:</h2>
                    </div>
                    <div className="row pb-4 pt-4">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="dev_tech_desc_card_main">
                                <h2>Technical Discovery</h2>
                                <p>
                                    Our team deep-dives into your needs and existing workflows,
                                    content, and capabilities. We then conduct extensive research
                                    to find the ideal combination of custom development,
                                    integrations and contributed modules.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 ">
                            <div className="dev_tech_desc_card_main">
                                <h2>Content Migration</h2>
                                <p>
                                    We implement migrations in a way that maintains content
                                    integrity and presentation standards. We take care to migrate
                                    important meta-data and other data identified in the discovery
                                    phase.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 ">
                            <div className="dev_tech_desc_card_main">
                                <h2>Strategic Planning</h2>
                                <p>
                                    We use the insights gained in the discovery phase to map out
                                    your new site architecture and deliver a solid content
                                    migration plan. Our team draws on its extensive experience to
                                    provide actionable steps for success.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 ">
                            <div className="dev_tech_desc_card_main">
                                <h2>User Acceptance Testing</h2>
                                <p>
                                    Your team has the opportunity to validate and approve the
                                    results of our work. This process happens in a staging
                                    environment and leverages the user stories that were defined
                                    before the development phase.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 ">
                            <div className="dev_tech_desc_card_main">
                                <h2>Development</h2>
                                <p>
                                    Our technologists, designers, and strategists collaborate to
                                    ensure design deliverables are compliant, flexible, and ready
                                    to develop. We iteratively build out your new platform,
                                    applying best practices to ensure quality and minimize tech
                                    debt.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="dev_tech_desc_header_main">
                        <h2>
                            BENEFITS OF WORKING WITH OUR TECHNOLOGY AND DEVELOPMENT TEAM:
                        </h2>
                    </div>
                    <div className="row pb-4 pt-4">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="dev_tech_desc_card_main">
                                <h2>Minimal Code Debt</h2>
                                <p>
                                    We minimize custom code in order to reduce tech debt, improve
                                    cost efficiency, and make your platform easier for you to
                                    independently maintain. We leverage our roots in the open
                                    source community to find and implement the best-contributed
                                    code.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 ">
                            <div className="dev_tech_desc_card_main">
                                <h2>Accessible by Default</h2>
                                <p>
                                    All our projects meet the WCAG AA Standards to help you reach
                                    a broader audience and comply with relevant regulations. We
                                    can exceed this baseline if your project requires it.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 ">
                            <div className="dev_tech_desc_card_main">
                                <h2>User-Friendly for Admins</h2>
                                <p>
                                    We’re passionate about empowering your team with the tools to
                                    confidently own and grow your new platform. Our developers use
                                    best practices to streamline the admin UI so that it’s simple
                                    for your content editors to use.{" "}
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 ">
                            <div className="dev_tech_desc_card_main">
                                <h2>Optimized for Security</h2>
                                <p>
                                    We follow best practices and integrate code reviews into our
                                    development workflows to minimize vulnerabilities. Our team
                                    can provide security audits, updates, encryption, and
                                    authentication functionality.{" "}
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 ">
                            <div className="dev_tech_desc_card_main">
                                <h2>Expertly Multilingual </h2>
                                <p>
                                    We have extensive experience of delivering multilingual
                                    platforms, with some of our projects supporting as many as ten
                                    languages. Our team has contributed to Drupal’s core
                                    multilingual systems and the Multilingual Migrate module.Our
                                    technologists, designers, and strategists collaborate to
                                    ensure design deliverables are compliant, flexible, and ready
                                    to develop. We iteratively build out your new platform,
                                    applying best practices to ensure quality and minimize tech
                                    debt.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default DevTechProcessIntro;
