import React from "react";
import "./OurServices.css";
import ServiceBannerItem from "./pages/ServiceBannerItem";
import Technology from "../SpecialisedTech/Technology";
import { Helmet } from 'react-helmet';


function OurServices() {
  return (
    <React.Fragment>
      <Helmet>
        <meta charset="utf-8" />
        <title>
          Capeark | Services
        </title>
        <meta charset="utf-&" />
        <meta name="description" content="We have a dedicated team of experts who stay updated with the latest technology to provide strategic guidance to maximize our client's growth potential." />
        <meta name="keywords" content="Web development, App development, Mern stack, Mean stack, Audit Services, Strategy, Digital Products & Experience Designs, Ongoing Support" />
        <meta name="author" content="Capeark" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="service_header_main_cont">
        <div className="container mt-4 mb-5">
          <div className="row mt-5">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <h6 className="service-page-header-title-text text-white">
                FULL-SERVICE DESIGN AGENCY
              </h6>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
              <h1 className="service-page-header-subtitle-text text-white">
                Digital Platforms Designed for Growth
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="project-banner-clip-one"></div>
      <div className="">
        <div className="container">
          <div className="row pt-5 pb-5">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <p className="service_intro_text">
                We have a dedicated team of experts who stay updated with the latest
                advancements in digital technology and closely monitor market trends
                to provide strategic guidance and innovative solutions that maximize our
                clients' growth potential.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Technology />
      </div>
      <div>
        <ServiceBannerItem />
      </div>
    </React.Fragment>
  );
}

export default OurServices;
