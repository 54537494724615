export default function CheckLink(type) {
  switch (type) {
    case "/about":
      return { parant: "/about", child: "/about" };
    case "/careers":
      return { parant: "/about", child: "/careers" };
    case "/apply-now":
      return { parant: "/about", child: "/apply-now" };
    case "/projects":
      return { parant: "/projects", child: "/projects" };
    case "/services":
      return { parant: "/services", child: "/services" };
    case "/audit-services":
      return { parant: "/services", child: "/audit-services" };
    case "/strategies-services":
      return { parant: "/services", child: "/strategies-services" };
    case "/web-design-services":
      return { parant: "/services", child: "/web-design-services" };
    case "/development-technology":
      return { parant: "/services", child: "/development-technology" };
    case "/marketing":
      return { parant: "/services", child: "/marketing" };
    case "/maintenance":
      return { parant: "/services", child: "/maintenance" };
    case "/content-strategy-seo":
      return { parant: "/services", child: "/content-strategy-seo" };
    case "/industries":
      return { parant: "/industries", child: "/industries" };
    case "/website-development-government-agencies":
      return { parant: "/industries", child: "/website-development-government-agencies" };
    case "/higher-education-website-design-development":
      return { parant: "/industries", child: "/higher-education-website-design-development" };
    case "/website-development-non-governmental-organizations":
      return { parant: "/industries", child: "/website-development-non-governmental-organizations" };
    case "/website-development-healthcare":
      return { parant: "/industries", child: "/website-development-healthcare" };
    case "/enterprise":
      return { parant: "/industries", child: "/enterprise" };
    case "/contact":
      return { parant: "/contact", child: "/contact" };
    case "/blogs":
      return { parant: "/blogs", child: "/blogs" };
    case "/blog":
      return { parant: "/blog", child: "/blog" };
    default:
      return { parant: "/", child: undefined };
  }

}

