import React from 'react'

function NonProfitTextBanner() {
    return (
        <React.Fragment>
            <div style={{ backgroundColor: "#f5f5f5"}} className='mb-5'>
                <div className="container pb-5 pt-5">
                    <div className="row pb-4 pt-4">
                        <div className="col-sm-12 col-md-6 col-lg-6 ">
                            <div className="industry_non_profit_design_desc_card_main">
                                <h2>Amplification Through Digital Content</h2>
                                <p>
                                    Let us help you raise awareness, build trust and maximize your impact.
                                </p>
                                <div className="industry_non_profit__desc_media__text">
                                    {/* <p>To ensure the return on your investment, it needs to:</p> */}
                                    <ul className="industry_non_profit_desc_list ">
                                        <li>Clearly written content that is designed for online consumption and social media sharing will expand your efforts and extend your reach.</li>
                                        <li>Providing regular content updates such as blog posts and newsletters will keep you top of mind to members, will ensure they see the impact you’re making and encourage repeat donations.</li>
                                        <li>Having engaging content and powerful stories will empower your members, inciting them to share content on your behalf and become ambassadors for your cause. </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 ">
                            <div className="industry_non_profit_design_desc_card_main">
                                <h2>Amplifying Your Web Presence</h2>
                                <p>
                                    Your non-profit website should build affinity for your cause, raise money, coordinate events and find volunteers.
                                </p>
                                <div className="industry_non_profit__desc_media__text">
                                    <p>To ensure the return on your investment, it needs to:</p>
                                    <ul className="industry_non_profit_desc_list ">
                                        <li>Draw the audience in, quickly explaining your cause</li>
                                        <li>Provide value through education</li>
                                        <li>Focus on Join Us and Donate features</li>
                                        <li>Have content that drives action, with strong visuals and clear calls to action</li>
                                        <li>Provide members access to connect, contribute and give back</li>
                                        <li>Offer sharable content to empower members to become ambassadors</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default NonProfitTextBanner