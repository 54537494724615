import React from "react";
import "../css/Topbar.css";
import { Row, Col } from "reactstrap";
function Topbar() {
  return (
    <div className="top-navigation-wrap">
      <div className="container topbar-main">
        <Row>
          <Col lg={6}></Col>
          <Col lg={6} className="top_nav_social_cont">
            <a href="https://www.facebook.com/capearkofficial" target="_blank" rel="noreferrer">
              <i className="fa fa-facebook-square"></i>
            </a>
            <a href="https://www.instagram.com/capearkofficial" target="_blank" rel="noreferrer">
              <i className="fa fa-instagram"></i>
            </a>
            <a href="https://twitter.com/capearkofficial" target="_blank" rel="noreferrer">
              <i className="fa fa-twitter-square"></i>
            </a>
            <a href="https://www.linkedin.com/company/capeark" target="_blank" rel="noreferrer">
              <i className="fa fa-linkedin-square"></i>
            </a>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Topbar;
