import React, { useState, useEffect } from "react";
import "../css/Header.css";
import "./index.css";
import { Link } from "react-router-dom";
import FullLogoLight from "../../assets/Images/FullLogoLight.png"
import { Row, Col } from "reactstrap";
import Industries from "./Submenus/Industries";
import Abouts from "./Submenus/About";
import Services from "./Submenus/Services";
import CheckLink from "./LinkCheck";
// import Resources from "./Submenus/Resources";

var $ = require("jquery");

function Header() {
  const [activeBar, setActiveBar] = useState({});
  $(function () {
    var header = $(".start-style");
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 10) {
        header.removeClass("start-style").addClass("scroll-on");
      } else {
        header.removeClass("scroll-on").addClass("start-style");
      }
    });
  });

  //Menu On Hover
  $("body").on("mouseenter mouseleave", ".nav-main-item", function (e) {
    // if ($(window).width() > 750) {
    var _d = $(e.target).closest(".nav-main-item");
    _d.addClass("show");
    setTimeout(function () {
      _d[_d.is(":hover") ? "addClass" : "removeClass"]("show");
    }, 1);
    // }
  });
  $("body").on("click", ".dropdown-item", function (e) {
    var _d = $(e.target).closest(".nav-main-item");
    _d.removeClass("show");
    if ($(window).width() <= 750) {
      toggleMenu()
    }

  });
  const [state, setState] = useState({
    menu: false,
    isOpen: false,
    homeLinkClass: "nav-main-item nav-link",
    aboutLinkClass: "nav-main-item nav-link",
    menuClass: "",
  });
  const toggleMenu = () => {
    setState({
      ...state,
      menu: !state.menu,
    });
  };
  let show = state.menu ? "show" : "";

  var url = window.location.pathname;
  var id = url.split("/");

  useEffect(() => {
    setActiveBar(CheckLink("/" + id[1]));
  }, [url]);

  const colorLink = (index) => {
    setActiveBar(CheckLink(index));
  };



  return (
    <div className="navigation-wrap bg-light start-header start-style">
      <div className="container">
        <Row>
          <Col lg={12}>
            <nav className="navbar navbar-expand-md navbar-light p-0">
              <Link to="/" className="header-navbar-brand">
                <img src={FullLogoLight} alt="/" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={toggleMenu}
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className={`collapse navbar-collapse ${show} nav_collapsed_box`}
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto py-4 py-md-0">
                  <li className={`nav-main-item ${activeBar?.parant === "/about" ? "active" : ""} pl-4 pl-md-0 ml-0 ml-md-4 nav_item_custom_class`}>
                    <Link
                      to="/about"
                      className="nav-main-link dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="false"
                      aria-expanded="false"
                      onClick={() => colorLink("/about")}
                    >
                      About
                    </Link>
                    <div className="dropdown-menu">
                      <Abouts colorLink={colorLink} activeChild={activeBar?.child} />
                    </div>
                  </li>
                  <li className={`nav-main-item ${activeBar?.parant === "/projects" ? "active" : ""} pl-4 pl-md-0 ml-0 ml-md-4`}>
                    <Link to="/projects" className="nav-main-link" onClick={() => colorLink("/projects")}>
                      Projects
                    </Link>
                  </li>
                  <li className={`nav-main-item ${activeBar?.parant === "/services" ? "active" : ""} pl-4 pl-md-0 ml-0 ml-md-4 nav_item_custom_class`}>
                    <Link
                      to="/services"
                      className="nav-main-link dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="false"
                      aria-expanded="false"
                      onClick={() => colorLink("/services")}
                    >
                      Services
                    </Link>
                    <div className="dropdown-menu">
                      <Services colorLink={colorLink} activeChild={activeBar?.child} />
                    </div>
                  </li>
                  <li className={`nav-main-item ${activeBar?.parant === "/industries" ? "active" : ""} pl-4 pl-md-0 ml-0 ml-md-4 nav_item_custom_class`}>
                    <Link to="/industries"
                      className="nav-main-link dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="false"
                      aria-expanded="false"
                      onClick={() => colorLink("/industries")}
                    >
                      Industries
                    </Link>
                    <div className="dropdown-menu">
                      <Industries colorLink={colorLink} activeChild={activeBar?.child} />
                    </div>
                  </li>
                  <li className={`nav-main-item ${activeBar?.parant === "/contact" ? "active" : ""} pl-4 pl-md-0 ml-0 ml-md-4`}>
                    <Link to="/contact" className="nav-main-link" onClick={() => colorLink("/contact")}>
                      Contact
                    </Link>
                  </li>
                  <li className={`nav-main-item  ${activeBar?.parant === "/blogs" || activeBar?.parant === "/blog"  ? "active" : ""} pl-4 pl-md-0 ml-0 ml-md-4`}>
                    <Link
                      to="/blogs"
                      className="nav-main-link "
                      onClick={() => colorLink("/blogs")}
                    >
                      Blog
                    </Link>
                    {/* <div className="dropdown-menu">
                      <Resources />
                    </div> */}
                  </li>
                </ul>
              </div>
            </nav>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Header;
