import React from 'react'
import ContactBanner from './ContactBanner';
import GetInTouch from './GetInTouch';
import { Helmet } from 'react-helmet';


import Map from './Map'
import AddressInfo from './AddressInfo';

const ContactUs = () => {
  return (
    <React.Fragment>
      <Helmet>
        <meta charset="utf-8" />
        <title>
          Capeark | Contact
        </title>
        <meta charset="utf-&" />
        <meta name="description" content="Contact, Interested in our IT services or need advice? Then please get in touch and we’ll be glad to help." />
        <meta name="keywords" content="Web development, App development, Mern stack, Mean stack, Strategy, Digital Products & Experience Designs, Ongoing Support, Marketings, Seo" />
        <meta name="author" content="Capeark" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div>
        <ContactBanner />
      </div>
      <div>
        <AddressInfo />
      </div>
      <div>
        <GetInTouch />
      </div>
      <div className='mt-4'>
        <Map />
      </div>

    </React.Fragment>
  )
}

export default ContactUs
