import React from 'react'
import "./CompanyDelInfo.css";
import Techonology_img from "../../../assets/capeark_img/home/Techonology-img.png"
function CompanyDelInfo() {
    return (
        <section className='home_comp_del_info_main'>
            <div className='container'>
                <div className='row mt-5 mb-5'>
                    <div className='col-md-5 align-self-center'>
                        <div className='home_comp_del_tech_img_cont pr-md-4 wow image_bounces_left home_intro_animated'>
                            <img src={Techonology_img} alt='techonology' className='img-fluid' />
                        </div>
                    </div>
                    <div className='col-md d-flex'>
                        <div>
                            <h4 className='text-muted'>Our Company</h4>
                            <div className='home_comp_del_tech_line' ></div>
                            <h1 className='mt-4 home__company_intro_text_head'>Providing Excellence in the Digital Era</h1>
                            <p className='home__company_intro_text_quotes'>We are expert in Offshore Software Development while turning everything in the digital matrix and running parallel with the technology trends.
                                <br /><br />Our experts are also adopting new technical innovation to deliver you tomorrow’s revolution today.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CompanyDelInfo;