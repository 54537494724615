import React from 'react'
import nonprofitbanner from '../../../../assets/capeark_img/industry/NonProfitBanner.jpg'
import interprises from '../../../../assets/capeark_img/industry/interprises.jpg'
import higherEducationbanner from '../../../../assets/capeark_img/industry/HigherEducationBanner.jpg'
import healthcarebanner from '../../../../assets/capeark_img/industry/HealthCareBanner.jpg'

function NonProfitBanner() {
    return (
        <React.Fragment>
            <div className="service_banner_item_cont_main">
                <div className="service_banner_item_bg_cont">
                    <div className="row service_banner_item_content_cont pt-5 pb-5 ">
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_hover_cont service_banner_column">
                            <figure className="p-0 m-0 service_banner_img_cont">
                                <img
                                    src={nonprofitbanner}
                                    alt=""
                                />
                            </figure>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_desc_cont">
                            <div className="service_banner_desc_media__body">
                                <h2 className="service_banner_desc_media__title mb-4">
                                    Website Development for NGOs
                                </h2>
                                <div className="service_banner_desc_media__text">
                                    <p>The majority of non-governmental organizations (NGOs) have limited budgets, making a website build a costly investment. A well-built website can create a strong online presence and can be a cost-effective way to reach your audience. <br /> <br /> A well-developed NGO website should:</p>
                                    <ul className="service_banner_desc_list ">
                                        <li>Increase donations</li>
                                        <li>Build awareness for your cause and the impact you're making</li>
                                        <li>Engage the community and grow membership</li>
                                        <li>Garner loyalty and trust</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default NonProfitBanner