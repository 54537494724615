import React from "react";
import "./Loader.css";
import { Spin } from 'antd';

function MainLoader() {
  return (
    <React.Fragment>
      <div className="main_loader_cont">
        <Spin id="main_loader_cont_dot" size="large"></Spin>
      </div>
    </React.Fragment>
  );
}
export default MainLoader;
