import React from 'react';
import './css/Map.css'

const MapContainer = () => {

    return (
        <>
            <div className="map-container">
                <iframe src="https://maps.google.com/maps?q=Gachibowli, Hyderabad, Telangana 500032&t=&z=13&ie=UTF8&iwloc=&output=embed" className='map' title='office place'></iframe>
            </div>
        </>
    );
};

export default MapContainer;
