import galaxyone from "../assets/capeark_img/project/galaxyenterprise/galaxyone.jpeg"

export const AllProject = [
    {
        id: "1",
        img: galaxyone,
        img_type: "full",
        categories: "Enterprise",
        description: "Establishing a Cohesive and Powerful Digital Footprint for Leaders in the Windows & Doors Solutions Industry.",
        project_name: "Galaxy Enterprise",
        color: "#8abaa2",
        url: "galaxy-enterprise"
    },
    // {
    //     id: "2",
    //     img: "https://evolvingweb.com/sites/default/files/styles/project_xxl_2x/public/2023-03/IRS-thumbnail-small.png?h=4a54159c&itok=kPXMFNC-",
    //     img_type: "short",
    //     categories: "Higher Education",
    //     description: "A Compact Showcase for a Centennial Celebration",
    //     project_name: "Princeton Industrial Relations Section Centennial (Microsite)",
    //     color: "rgb(248, 129, 39)",
    // }
];

export const InterpriseData = [
    {
        id: "1",
        img: galaxyone,
        img_type: "full",
        categories: "Enterprise",
        description: "Establishing a Cohesive and Powerful Digital Footprint for Leaders in the Windows & Doors Solutions Industry.",
        project_name: "Galaxy Enterprise",
        color: "#8abaa2",
        url: "galaxy-enterprise"
    },
   
]
export const HigherEduData = [
    {
        id: "1",
        img: "https://evolvingweb.com/sites/default/files/styles/project_xxl_2x/public/2023-03/IRS-thumbnail-small.png?h=4a54159c&itok=kPXMFNC-",
        img_type: "short",
        categories: "Higher Education",
        description: "A Compact Showcase for a Centennial Celebration",
        project_name: "Princeton Industrial Relations Section Centennial (Microsite)",
        color: "rgb(248, 129, 39)",
    }, {
        id: "2",
        img: "https://evolvingweb.com/sites/default/files/styles/project_xxl_2x/public/2023-03/Queens-thumbnail.png?h=4a54159c&itok=oXqBO4kV",
        img_type: "short",
        categories: "Higher Education",
        description: "A Bold Website to Lead Queen’s University Into the Future",
        project_name: "Queen’s University",
        color: "rgb(137, 10, 36)",
    }, {
        id: "3",
        img: "https://evolvingweb.com/sites/default/files/styles/project_xxl_2x/public/2023-01/GTL_thumbnail.png?h=5f573ba3&itok=PUc21Knq",
        img_type: "short",
        categories: "Higher Education",
        description: "An Engaging New Refresh for an Award-Winning Library",
        project_name: "Georgia Tech Library",
        color: "rgb(0, 81, 146)",
        url: "/georgia-tech-library"
    }, {
        id: "4",
        img: "https://evolvingweb.com/sites/default/files/styles/project_featured_xxl_2x/public/2023-01/Guelph-Humber-thumbnail-featured.png?h=c409bb3e&itok=6a51HMj4",
        img_type: "full",
        categories: "Higher Education",
        description: "Evaluating the User Journeys to Create a Redesign Roadmap",
        project_name: "University of Guelph-Humber",
        color: "rgb(50, 53, 60)",
    },
]

export const NonProfitData = [
    {
        id: "1",
        img: "https://evolvingweb.com/sites/default/files/styles/project_xxl_2x/public/2021-10/Looking-forward%20-thumbnail_0.png?h=a8f92ffd&itok=j1as2Z5V",
        img_type: "short",
        categories: "Non-Profit",
        description: "A mobile-first resource that supports patients in remission from all types of cancer",
        project_name: "CURE Foundation - Looking Forward",
        color: "rgb(250, 242, 220)",
    },
    {
        id: "2",
        img: "https://evolvingweb.com/sites/default/files/styles/project_xxl_2x/public/2023-02/MPY-thumbnail.png?h=5f573ba3&itok=9RkyRzMh",
        img_type: "short",
        categories: "Non-Profit",
        description: "Brand Redesign: Positioning Million Peacemakers Youth as the Heroes They Are Million Peacemakers",
        project_name: "Million Peacemakers",
        color: "rgb(103, 162, 235)",
    },
    {
        id: "3",
        img: "https://evolvingweb.com/sites/default/files/styles/project_xxl_2x/public/2023-01/NewMode_thumbnail.png?h=5f573ba3&itok=zi8M14Qq",
        img_type: "short",
        categories: "Non-Profit",
        description: "A Web-Based Platform To Build Advocacy Campaigns",
        project_name: "New/Mode",
        color: "rgb(50, 53, 60)",
    },
    {
        id: "4",
        img: "https://evolvingweb.com/sites/default/files/styles/project_xxl_2x/public/2022-06/CFI%20-%20%20thumbnail%201%20-%20resources%20page.png?h=4dd68006&itok=8V_IzoFo",
        img_type: "short",
        categories: "Government, Non-Profit",
        description: "An Innovative Digital Experience Focused on Canada’s Scientific Community",
        project_name: "Canada Foundation for Innovation",
        color: "rgb(148, 214, 210)",
    },
    {
        id: "5",
        img: "https://evolvingweb.com/sites/default/files/styles/project_xxl_2x/public/2022-03/auma_thumbnail.png?h=a8f92ffd&itok=VlTPDueY",
        img_type: "short",
        categories: "Government, Non-Profit",
        description: "A Fresh Design and a Drupal 9 Rebuild for Alberta Municipalities",
        project_name: "Alberta Municipalities",
        color: "rgb(1, 160, 192)",
    }
]

export const GovernmentData = [
    {
        id: "1",
        img: "https://evolvingweb.com/sites/default/files/styles/project_xxl_2x/public/2022-06/CFI%20-%20%20thumbnail%201%20-%20resources%20page.png?h=4dd68006&itok=8V_IzoFo",
        img_type: "short",
        categories: "Government, Non-Profit",
        description: "An Innovative Digital Experience Focused on Canada’s Scientific Community",
        project_name: "Canada Foundation for Innovation",
        color: "rgb(148, 214, 210)",
    },
    {
        id: "2",
        img: "https://evolvingweb.com/sites/default/files/styles/project_xxl_2x/public/2022-03/auma_thumbnail.png?h=a8f92ffd&itok=VlTPDueY",
        img_type: "short",
        categories: "Government, Non-Profit",
        description: "A Fresh Design and a Drupal 9 Rebuild for Alberta Municipalities",
        project_name: "Alberta Municipalities",
        color: "rgb(1, 160, 192)",
    },
    {
        id: "3",
        img: "https://evolvingweb.com/sites/default/files/styles/project_xxl_2x/public/2021-07/%20project%20ontario%20securites%20commission.png?h=a8f92ffd&itok=v4s7s-bp",
        img_type: "short",
        categories: "Government",
        description: "A User-Friendly Interface for a Content-Rich Website",
        project_name: "Ontario Securities Commission",
        color: "rgb(178, 232, 243)",
    }, {
        id: "4",
        img: "https://evolvingweb.com/sites/default/files/styles/project_xxl_2x/public/2021-07/project%20yukon%20road%20trip%20app.png?h=a8f92ffd&itok=YUCLvIp2",
        img_type: "short",
        categories: "Government",
        description: "Helping Travellers Plan Their Next Yukon Adventure",
        project_name: "Yukon Road Trip Mobile App",
        color: "rgb(1, 158, 175)",
    }
]

export const TravelData = [
    {
        id: "1",
        img: "https://evolvingweb.com/sites/default/files/styles/project_xxl_2x/public/2022-03/Princeton-International-thumbnail.png?h=a8f92ffd&itok=BAgUEzTp",
        img_type: "short",
        categories: "Higher Education, Travel",
        description: "An Immersive Website for Students Planning to Study Abroad",
        project_name: "Princeton International",
        color: "rgb(230, 119, 47)",
    }, {
        id: "2",
        img: "https://evolvingweb.com/sites/default/files/styles/project_xxl_2x/public/2021-07/%20project%20journeys%20by%20van%20dyke.png?h=a8f92ffd&itok=o62xd2bo",
        img_type: "short",
        categories: "Travel",
        description: "Showcasing Luxury Travel Experiences",
        project_name: "Journeys by Van Dyke",
        color: "rgb(1, 129, 169)",
    }
]
export const HealthcareData = [
    {
        id: "1",
        img: "https://evolvingweb.com/sites/default/files/styles/project_xxl_2x/public/2021-07/project%20sante%CC%81%20monte%CC%81re%CC%81gie.png?h=a8f92ffd&itok=jE9WWSAV",
        img_type: "short",
        categories: "Healthcare",
        description: "Centralized Health Services Portal",
        project_name: "Santé Montérégie",
        color: "rgb(4, 48, 159)",
    },
    {
        id: "2",
        img: "https://evolvingweb.com/sites/default/files/styles/project_xxl_2x/public/2021-07/project%20ooq.png?h=a8f92ffd&itok=dMGFf6-C",
        img_type: "short",
        categories: "Healthcare",
        description: "Membership-Driven Website for Optometrists",
        project_name: "The Order of Optometrists of Quebec",
        color: "rgb(91, 173, 179)",
    }, {
        id: "3",
        img: "https://evolvingweb.com/sites/default/files/styles/project_xxl_2x/public/2021-07/project%20council%20for%20responsible%20nutrition.png?h=c74aa9ce&itok=UGYmtcUE",
        img_type: "short",
        categories: "Healthcare",
        description: "Association Website and Membership System",
        project_name: "Council for Responsible Nutrition",
        color: "rgb(182, 212, 106)",
    },
]