import React from 'react'
import { Slide } from 'react-slideshow-image';
import slider1 from "../../../assets/capeark_img/home/Slider1.jpeg";
import slider2 from "../../../assets/capeark_img/home/slider2.jpeg";

import "./carousel.css";

function Carousel() {
  const buttonStyle = {
    width: "30px",
    background: 'none',
    border: '0px'
  };

  const properties = {
    prevArrow: <button style={{ ...buttonStyle }} className='home_carousel_btn'><i className="fa fa-angles-left m-2"></i></button>,
    nextArrow: <button style={{ ...buttonStyle }} className='home_carousel_btn'><i className="fa fa-angles-right m-2"></i></button>
  }

  const images = [
    slider1,
    slider2,
  ];

  return (
    <>
      <div className='home-owl-slider'>
        <Slide {...properties} autoplay={true} duration={5000} className="active" style={{ border: "1px solid blue" }} >
          <div className="each-slide-effect home_slider_item">
            <div style={{ backgroundImage: `url(${images[0]})` }} className='home_slider_pages home_slider_cover'>
              <div className='home_slider_pages_overlay'>
                <div className='container'>
                  <div className='home_slider_header_content col-md-11 col-lg-10'>
                    <div className='home_slider_line'></div>
                    <h1>Innovative Strategies. <br /> Outstanding results. </h1>
                    <h4>By seamlessly integrating innovative Strategies with the right technology, our software services surpass competitors and establish market dominance.</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="each-slide-effect home_slider_item">
            <div style={{ 'backgroundImage': `url(${images[1]})` }} className='home_slider_pages home_slider_cover'>
              <div className='home_slider_pages_overlay'>
                <div className='container'>
                  <div className='home_slider_header_content col-md-11 col-lg-10'>
                    <div className='home_slider_line'></div>
                    <h1>Ultra services. <br /> Exceptional outcomes. </h1>
                    <h4>Our software services achieve market dominance by seamlessly integrating innovative ideas with the right technology.</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slide>
        <div className='home-banner-clip-img'> </div>
      </div>
    </>
  )
}

export default Carousel