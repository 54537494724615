import React from 'react'

function EnterpriseIntro() {
    return (
        <React.Fragment>
            <div>
                <div className="container pb-5 pt-5">
                    <div className="row pb-4 pt-4">
                        <div className="col-sm-12 col-md-4 col-lg-4 ">
                            <div className="industry_non_profit_design_desc_card_main">
                                <h2>DISCOVERY & STRATEGY</h2>
                                <p>
                                    Through extensive research, we learn about your audience and uncover innovative ways of engaging them. Our insights are documented and built upon at every stage.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 ">
                            <div className="industry_non_profit_design_desc_card_main">
                                <h2>DESIGN & DEVELOPMENT</h2>
                                <p>
                                    Users are at the heart of everything we build. We focus on their needs and test our work to create highly valuable and accessible experiences.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 ">
                            <div className="industry_non_profit_design_desc_card_main">
                                <h2>SUPPORT & TRAINING</h2>
                                <p>
                                    We’re always on-hand if you need additional support with updates and governance. Our experts can also coach your team towards digital independence.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}

export default EnterpriseIntro