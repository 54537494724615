import Home from "../pages/Home/Home";
import NotFound from "../constants/NotFound/NotFound";
import Carousel from "../pages/Home/Pages/Carousel";
import Projects from "../pages/Projects/Projects";
import ContactUs from "../pages/ContactUs/Contact";
import OurServices from "../pages/Services/OurServices/OurServices";
import AuditServices from "../pages/Services/AuditServices/AuditServices";
import Strategy from "../pages/Services/Strategy/Strategy";
import WebDesignServices from "../pages/Services/Designs/WebDesignServices";
import DevAndTechServices from "../pages/Services/DevAndTech/DevAndTechServices";
import Marketing from "../pages/Services/Marketing/Marketing";
import Maintenance from "../pages/Services/Maintanance/Maintenance";
import SeoServices from "../pages/Services/SEO/SeoServices";
import About from "../pages/About/About";
import Careers from "../pages/About/Careers";
import AllIndustries from "../pages/Industries/AllIndustries/AllIndustries";
import Government from "../pages/Industries/Govt/Government";
import HigherEducation from "../pages/Industries/HigherEducation/HigherEducation";
import NonProfit from "../pages/Industries/NonProfit/NonProfit";
import HealthCare from "../pages/Industries/HealthCare/HealthCare";
import Enterprise from "../pages/Industries/Enterprise/Enterprise";
import Blog from "../pages/Blog/Blog";
import BlogDetailMain from "../pages/Blog/Pages/BlogDetailMain";
import ApplyNow from "../pages/About/JobsOpening";
import GeorgiaTechLibrary from "../pages/Projects/ProjectDetailsPage/GeorgiaTechLibrary";
import GalaxyEnterprisePage from "../pages/Projects/ProjectDetailsPage/GalaxyEnterprisePage";

export const routes = [
  //   { path: "/", Component: <Home /> },
  { path: "/carousel", Component: <Carousel /> }
];

export const publicRoutes = [
  { path: "*", Component: <NotFound /> },
  { path: "/", Component: <Home /> },
  { path: "/projects", Component: <Projects /> },
  { path: '/projects/galaxy-enterprise', Component: <GalaxyEnterprisePage /> },
  // { path: '/projects/georgia-tech-library', Component: <GeorgiaTechLibrary /> },
  { path: "/services", Component: <OurServices /> },
  { path: '/about', Component: <About /> },
  { path: '/careers', Component: <Careers /> },
  { path: '/contact', Component: <ContactUs /> },
  { path: '/audit-services', Component: <AuditServices /> },
  { path: '/strategies-services', Component: <Strategy /> },
  { path: '/web-design-services', Component: <WebDesignServices /> },
  { path: '/development-technology', Component: <DevAndTechServices /> },
  { path: '/marketing', Component: <Marketing /> },
  { path: '/maintenance', Component: <Maintenance /> },
  { path: '/content-strategy-seo', Component: <SeoServices /> },
  { path: '/industries', Component: <AllIndustries /> },
  { path: '/website-development-government-agencies', Component: <Government /> },
  { path: '/higher-education-website-design-development', Component: <HigherEducation /> },
  { path: '/website-development-non-governmental-organizations', Component: <NonProfit /> },
  { path: '/website-development-healthcare', Component: <HealthCare /> },
  { path: '/enterprise', Component: <Enterprise /> },
  { path: '/blogs', Component: <Blog /> },
  { path: '/blog/:blog_id', Component: <BlogDetailMain /> },
  { path: '/apply-now/:job_id', Component: <ApplyNow /> },

  
];
