import React from 'react';
import "./AllIndustries.css";
import { Helmet } from 'react-helmet';
import AllIndustriesBannerItem from './Pages/AllIndustriesBannerItem';
import ContactBottom from '../../Helpers/ContactBtn/ContactBottom';
import stareBanner from "../../../assets/Images/General/stare_banner.jpeg";
function AllIndustries() {
    return (
        <React.Fragment>
            <Helmet>
                <meta charset="utf-8" />
                <title>
                    Capeark | Industries
                </title>
                <meta charset="utf-&" />
                <meta name="description" content="Industries, Organizations of all sizes trust us to create powerful, informative, and intuitive digital experiences." />
                <meta name="keywords" content="Government, Municipality, Higer education, Non profit, Health care, Enterprise" />
                <meta name="author" content="Capeark" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div className="row position-relative industries-top-banner g-0">
                <img src={stareBanner} className="img-fluid p-0" alt="" />
                <div className="col-12 text-center industries-banner-content d-flex justify-content-center">
                    <div className="align-self-center industries-banner-content-child">
                        <h1>Industries</h1>
                        <p>
                            Organizations of all sizes trust us to create powerful, informative, <br /> and intuitive digital experiences.
                        </p>
                    </div>
                </div>
                <div className="industires-banner-clip-img">
                    <div className='industires-banner-clip-img-div'></div>
                </div>
            </div>
            <div>
                <AllIndustriesBannerItem />
            </div>
            <div>
                <ContactBottom
                    btnLink={"/contact"}
                    btnText={"Contact Capeark"}
                    BannerText={"HOW CAN WE HELP YOU WITH YOUR NEXT PROJECT?"}

                />
            </div>

        </React.Fragment>
    )
}

export default AllIndustries;