import React from "react";
import { Helmet } from 'react-helmet';
import ContactBottom from "../../Helpers/ContactBtn/ContactBottom";
import SeoServicesBanner from "./Pages/SeoServicesBanner";
import SeoIntro from "./Pages/SeoIntro";
function SeoServices() {
  return (
    <React.Fragment>
      <Helmet>
        <meta charset="utf-8" />
        <title>
          Capeark | Content & SEO Services
        </title>
        <meta charset="utf-&" />
        <meta name="description" content="Content & SEO Services, Get More Out Of Your Content" />
        <meta name="keywords" content="Web development, App development, Mern stack, Mean stack, Audit Services, Strategy, Digital Products & Experience Designs, Ongoing Support" />
        <meta name="author" content="Capeark" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="service_header_main_cont">
        <div className="container mt-4 mb-5">
          <div className="row mt-5">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <h6 className="service-page-header-title-text text-white">
                ACHIEVE YOUR MARKETING GOALS
              </h6>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
              <h1 className="service-page-header-subtitle-text text-white">
                Get More Out Of Your Content
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="project-banner-clip-one"></div>
      <div className="">
        <div className="container">
          <div className="row pt-5">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <p className="service_intro_text">
                Innovative strategies that cut through digital noise.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <SeoServicesBanner />
      </div>
      <div>
        <SeoIntro />
      </div>
      <div>
        <ContactBottom
          btnLink={"/contact"}
          btnText={"Tell us About your Project"}
          BannerText={"Let’s get started."}
          textVarient={"h1"}
          bannerDesc={
            "Our team of marketers and content strategists are always excited about finding ways to harness the power of content and partner with you to meet your business goals."
          }
          Desc={true}
        />
      </div>
    </React.Fragment>
  );
}

export default SeoServices;
