import React from "react";
import { Helmet } from 'react-helmet';
import AuditServiceBannerItem from "./pages/AuditServiceBannerItem";
import AuditStrategy from "./pages/AuditStrategy";
import AuditInfo from "./pages/AuditInfo";
function AuditServices() {
    return (
        <React.Fragment>
            <Helmet>
                <meta charset="utf-8" />
                <title>
                    Capeark | Audit Services
                </title>
                <meta charset="utf-&" />
                <meta name="description" content="Site Audits, Make smarter decisions for your website’s future." />
                <meta name="keywords" content="Web development, App development, Mern stack, Mean stack, Audit Services, Strategy, Digital Products & Experience Designs, Ongoing Support" />
                <meta name="author" content="Capeark" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div className="service_header_main_cont">
                <div className="container mt-4 mb-5">
                    <div className="row mt-5">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <h6 className="service-page-header-title-text text-white">
                                UNDERSTAND THE PRESENT. PLAN THE FUTURE.
                            </h6>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                            <h1 className="service-page-header-subtitle-text text-white">
                                Site Audits: See the Big Picture
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="project-banner-clip-one"></div>
            <div className="">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <p className="service_intro_text">
                                Make smarter decisions for your website’s future. Our audits
                                give you a high-level view of how your platform is meeting
                                users’ needs.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <AuditStrategy />
            </div>
            <div>
                <AuditServiceBannerItem />
            </div>
            <div>
                <AuditInfo />
            </div>
        </React.Fragment>
    );
}

export default AuditServices;
