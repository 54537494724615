import React from 'react'
import ideateBrand from '../../../../assets/capeark_img/services/icons/icons_Ideate Your Brand.svg'
import createDigitalContent from '../../../../assets/capeark_img/services/icons/icons_Create Digital Content.svg'
import generateDesiredresults from '../../../../assets/capeark_img/services/icons/icons_Generate Desired results.svg'

function MarketingAdvertise() {
    return (
        <React.Fragment>
            <div className='container'>
                <div className='row pb-2 mb-4'>
                    <div className='col-sm-12 col-md-6 col-lg-4'>
                        <div className='marketing_strategy_card_main'>
                            <div className='mt-2'>
                                <img src={ideateBrand} alt='' />
                            </div>
                            <div>
                                <h4 className='mt-3 mb-3'>Ideate Your Brand</h4>
                                <p>We work together to highlight the unique ways you stand out from the competition.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-4'>
                        <div className='marketing_strategy_card_main'>
                            <div className='mt-2'>
                            <img src={createDigitalContent} alt='' />
                            </div>
                            <div>
                                <h4 className='mt-3 mb-3'>Create Digital Content</h4>
                                <p>Our storytellers and creatives develop content that drives traffic and wins attention.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-4'>
                        <div className='marketing_strategy_card_main'>
                            <div className='mt-2'>
                            <img src={generateDesiredresults} alt='' />
                            </div>
                            <div>
                                <h4 className='mt-3 mb-3'>Generate Desired results</h4>
                                <p>We report on the most essential metrics and make sure you achieve your desired results.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MarketingAdvertise;