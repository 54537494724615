import React from 'react';
import { Helmet } from 'react-helmet';
import ContactBottom from '../../Helpers/ContactBtn/ContactBottom';
import Marketingintro from './Pages/Marketingintro';
import MarketingBanner from './Pages/MarketingBanner';
import MarketingAdvertise from './Pages/MarketingAdvertise';

function Marketing() {
    return (
        <React.Fragment>
            <Helmet>
                <meta charset="utf-8" />
                <title>
                    Capeark | Digital Marketing Services
                </title>
                <meta charset="utf-&" />
                <meta name="description" content="Digital Marketing Services, Propel Your Brand Story Forward" />
                <meta name="keywords" content="Web development, App development, Mern stack, Mean stack, Audit Services, Strategy, Digital Products & Experience Designs, Ongoing Support" />
                <meta name="author" content="Capeark" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div className="service_header_main_cont">
                <div className="container mt-4 mb-5">
                    <div className="row mt-5">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <h6 className="service-page-header-title-text text-white">
                                MEANINGFUL BRAND STORIES THAT BRING YOU CLOSER TO YOUR AUDIENCES.
                            </h6>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                            <h1 className="service-page-header-subtitle-text text-white">
                                Propel Your Brand Story Forward
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="project-banner-clip-one"></div>
            <div className="">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <p className="service_intro_text">
                                Innovative strategies that cut through digital noise.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <MarketingAdvertise />
            </div>
            <div>
                <MarketingBanner />
            </div>
            <div className='mt-4 mb-5'>
                <Marketingintro />
            </div>
            <div>
                <ContactBottom
                    btnLink={"/contact"}
                    btnText={"Contact Capeark"}
                    BannerText={"BRING YOUR WEB DESIGN VISION TO LIFE"} />
            </div>
        </React.Fragment>
    )
}

export default Marketing;