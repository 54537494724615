import React, { useCallback, useState } from "react";
import ".././css/ApplyNow.css";
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import { useDropzone } from "react-dropzone";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import "./index.css";
import { disablePastDateFromSelected } from "../../../helpers/Globel/DateHandler";
import { uploadDocument } from "../../../helpers/dataFetcher/FileUpload/Document";
import LinearProgress from "@mui/material/LinearProgress";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import SavingProgress from "../../../constants/Loader/SavingProgress";
import { createNewApplicants } from "../../../helpers/dataFetcher/JobApplicants.js/JobApplicants";
import { useParams } from "react-router-dom";

const JobApplyForm = () => {
  const [showExeInputs, setShowExeInputs] = useState(false);
  const { job_id } = useParams();

  const initFormData = {
    job_id: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    resume: "",
    experience: {},
    experience_in_related_field: null,
    devlopment_experience_in_year: "",
  };

  const expInitData = {
    title: "",
    company: "",
    industry: "",
    summary: "",
    start_date: "",
    end_date: "",
  };
  const [formData, setFormData] = useState(initFormData);
  const [expFormData, setExpFormData] = useState(expInitData);
  const [error, setError] = useState(false);
  const [expError, setExpError] = useState(false);
  const [load, setLoad] = useState(false);
  const [expLoad, setExpLoad] = useState(false);
  const [fileLoad, setFileLoad] = useState(false);
  const [fileUpload, setFileUpload] = useState("");

  const onDrop = useCallback(async (acceptedFiles) => {
    setFileLoad(true);

    const fileData = acceptedFiles[0];
    if (!fileData) {
      setFileLoad(false);
      toast.error("Please select pdf files.")
      return;
    }
    const fileSizeInMB = fileData?.size / (1024 * 1024);
    if (fileSizeInMB > 10) {
      setFileLoad(false);
      toast.error("File size must be less than 10mb.")
      return;
    }

    const formDocData = new FormData();
    formDocData.append("file", fileData)
    const { data, status } = await uploadDocument(formDocData);
    if (status === 200) {
      setFormData((prevState) => {
        return {
         ...prevState, resume: data?.data?.url
        };
      })
    }
    setFileLoad(false);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
    },
  });

  async function handleSubmit(e) {
    e.preventDefault();
    setLoad(true);

    if (formData.first_name === "") {
      setLoad(false);
      setError(true);
      return;
    }
    if (formData.last_name === "") {
      setLoad(false);
      setError(true);
      return;
    }
    if (!validateEmail(formData.email) || formData.email === "") {
      setLoad(false);
      setError(true);
      return;
    }
    if (formData.resume === "") {
      setLoad(false);
      setError(true);
      return;
    }
    if (formData.experience_in_related_field === null) {
      setLoad(false);
      setError(true);
      return;
    }
    if (formData.devlopment_experience_in_year === "") {
      setLoad(false);
      setError(true);
      return;
    }
    if (expFormData.title !== "" && Object.keys(formData.experience).length === 0) {
      setLoad(false);
      setError(true);
      toast.error("Please save your experience form.")
      return;
    }
    let updatedJson = { ...formData, job_id: job_id };


    // After successfully submition setLoad(false
    const { status } = await createNewApplicants(updatedJson);
    if (status === 201) {
      toast.success("Job applied successfully");
    }
    setLoad(false);
  }

  //  check email validation
  function validateEmail(inputText) {
    // var mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var mailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (inputText !== "") {
      if (inputText.match(mailFormat)) {
        return true;
      } else {
        return false;
      }
    } else {
      return;
    }
  }

  function checkExperienceInput() {
    setExpLoad(true);
    if (expFormData.title === "") {
      setExpError(true);
      setExpLoad(false);
      return false;
    }
    setFormData({ ...formData, experience: expFormData })
    setShowExeInputs(!showExeInputs);
    setExpLoad(false);
  }

  function preventSubmit(event) {
    if (event.keyCode === 13) {
      event.preventDefault(); // Prevent form submission
      return false;
    }
  }

  return (
    <React.Fragment>
      <section className="container mb-5 p-2">
        <Form
          className="mt-3"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
        >
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <FormGroup>
                <Label for="firstName" className="job_apply_input_lebal">
                  First name <span className="required_star_error">*</span>
                </Label>
                <Input
                  invalid={formData?.first_name === "" && error}
                  className="job_apply_input_box"
                  onChange={(e) =>
                    setFormData({ ...formData, first_name: e.target.value })
                  }
                  name="firstName"
                  value={formData?.first_name}
                />
                <FormFeedback
                  invalid={formData?.first_name === "" && error}
                  className="job_apply_feedback_text"
                >
                  This field is required.
                </FormFeedback>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <FormGroup>
                <Label for="lastName" className="job_apply_input_lebal">
                  Last name <span className="required_star_error">*</span>
                </Label>
                <Input
                  invalid={formData?.last_name === "" && error}
                  className="job_apply_input_box"
                  onChange={(e) =>
                    setFormData({ ...formData, last_name: e.target.value })
                  }
                  name="lastName"
                  value={formData?.last_name}
                />
                <FormFeedback
                  invalid={formData?.last_name === "" && error}
                  className="job_apply_feedback_text"
                >
                  This field is required.
                </FormFeedback>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
              <FormGroup>
                <Label for="email" className="job_apply_input_lebal">
                  Email <span className="required_star_error">*</span>
                </Label>
                <Input
                  invalid={!validateEmail(formData.email) && error}
                  className="job_apply_input_box"
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  name="email"
                  value={formData?.email}
                />
                <FormFeedback
                  invalid={!validateEmail(formData.email) && error}
                  className="job_apply_feedback_text"
                >
                  {formData.email === ""
                    ? "This field is required."
                    : "Invalid email id."}
                </FormFeedback>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
              <FormGroup>
                <Label for="phone" className="job_apply_input_lebal">
                  Phone <span className="required_star_error">*</span>
                </Label>
                <Input
                  invalid={formData?.phone === "" && error}
                  className="job_apply_input_box"
                  onChange={(e) =>
                    setFormData({ ...formData, phone: e.target.value })
                  }
                  name="phone"
                  value={formData?.phone}
                />
                <FormFeedback
                  invalid={formData?.phone === "" && error}
                  className="job_apply_feedback_text"
                >
                  This field is required.
                </FormFeedback>
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
              <FormGroup>
                <Label for="resume" className="job_apply_input_lebal">
                  Resume <span className="required_star_error">*</span>
                </Label>
                <div
                  style={{ height: "140px" }}
                  {...getRootProps()}
                  className={`job_apply_file_upload_main ${isDragActive ? "job_apply_file_upload_main_active" : ""
                    }`}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the files here ...</p>
                  ) : (
                    <p>
                      Drag and drop pdf files here, or Click to select files
                    </p>
                  )}
                </div>
                {fileLoad && (
                  <Box sx={{ width: '100%', color: '#2294b5' }}>
                    <LinearProgress className="mt-1" color="inherit" sx={{ borderRadius: "10px" }} />
                  </Box>
                )}
                {fileUpload === "" && error ? (
                  <p className="job_apply_feedback_text job_apply_feedback_p_text ">
                    This field is required.
                  </p>
                ) : null}
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="job_apply_application_heading mb-3">
                <div>
                  <h4>
                    Experience <span>(Optional)</span>
                  </h4>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowExeInputs(!showExeInputs);
                    }}
                  >
                    + add
                  </button>
                </div>
              </div>
              {showExeInputs && (
                <div className="row job_apply_applicaton_exp_main g-0 p-4 mb-4">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <FormGroup>
                      <Label for="title" className="job_apply_input_lebal">
                        Title <span className="required_star_error">*</span>
                      </Label>
                      <Input
                        invalid={expFormData.title === "" && expError}
                        className="job_apply_input_box"
                        onChange={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setExpFormData({
                            ...expFormData,
                            title: e.target.value,
                          });
                        }}
                        name="title"
                        value={expFormData?.title}
                        onKeyDown={preventSubmit}
                      />
                      <FormFeedback
                        invalid={expFormData.title === "" && expError}
                        className="job_apply_feedback_text"
                      >
                        This field is required.
                      </FormFeedback>
                    </FormGroup>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <FormGroup>
                      <Label for="company" className="job_apply_input_lebal">
                        Company{" "}
                      </Label>
                      <Input
                        className="job_apply_input_box"
                        onChange={(e) =>
                          setExpFormData({
                            ...expFormData,
                            company: e.target.value,
                          })
                        }
                        name="company"
                        value={expFormData?.company}
                        onKeyDown={preventSubmit}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <FormGroup>
                      <Label for="industry" className="job_apply_input_lebal">
                        Industry
                      </Label>
                      <Input
                        className="job_apply_input_box"
                        onChange={(e) =>
                          setExpFormData({
                            ...expFormData,
                            industry: e.target.value,
                          })
                        }
                        value={expFormData?.industry}
                        name="industry"
                        onKeyDown={preventSubmit}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <FormGroup>
                      <Label for="summary" className="job_apply_input_lebal">
                        Summary
                      </Label>
                      <Input
                        type="textarea"
                        rows={5}
                        className="job_apply_input_box"
                        onChange={(e) =>
                          setExpFormData({
                            ...expFormData,
                            summary: e.target.value,
                          })
                        }
                        name="summary"
                        value={expFormData?.summary}
                        onKeyDown={preventSubmit}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 pr-2">
                    <FormGroup>
                      <Label for="startDate" className="job_apply_input_lebal">
                        Start date
                      </Label>
                      <Input
                        type="date"
                        className="job_apply_input_box"
                        name="startDate"
                        onChange={(e) =>
                          setExpFormData({
                            ...expFormData,
                            start_date: e.target.value,
                          })
                        }
                        value={expFormData?.start_date}
                        onKeyDown={preventSubmit}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 pl-2">
                    <FormGroup>
                      <Label for="endDate" className="job_apply_input_lebal">
                        End date
                      </Label>
                      <Input
                        type="date"
                        className="job_apply_input_box"
                        name="endDate"
                        onChange={(e) =>
                          setExpFormData({
                            ...expFormData,
                            end_date: e.target.value,
                          })
                        }
                        min={disablePastDateFromSelected(
                          expFormData?.start_date
                        )}
                        value={expFormData?.end_date}
                        onKeyDown={preventSubmit}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-12 job_apply_applicaton_exp_btn_cont">
                    <button
                      type="button"
                      onClick={() => {
                        checkExperienceInput();
                      }}
                    >
                      {expLoad ? (
                        <SavingProgress />
                      ) : "Save"}
                    </button>
                    <button type="button"
                      onClick={(e) => {
                        setExpError(false)
                        setExpFormData(expInitData);
                        setShowExeInputs(!showExeInputs);
                      }}
                    >Cancel</button>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <FormGroup>
                <Label className="job_apply_input_lebal">
                  Do you have experience in developing custom WordPress plugins?{" "}
                  <span className="required_star_error">*</span>
                </Label>
                <div className="job_apply_application_exp_conf_cont">
                  <div
                    onClick={() =>
                      setFormData({
                        ...formData,
                        experience_in_related_field: true,
                      })
                    }
                    className={`${formData?.experience_in_related_field === true
                      ? "job_apply_application_exp_conf_cont_active"
                      : ""
                      }`}
                  >
                    <DoneIcon fontSize="small" /> <span>Yes</span>
                  </div>
                  <div
                    onClick={() =>
                      setFormData({
                        ...formData,
                        experience_in_related_field: false,
                      })
                    }
                    className={`${formData?.experience_in_related_field === false
                      ? "job_apply_application_exp_conf_cont_active"
                      : ""
                      }`}
                  >
                    <CloseIcon fontSize="small" /> <span>No</span>
                  </div>
                </div>
                {formData?.experience_in_related_field === null && error ? (
                  <p className="job_apply_feedback_text job_apply_feedback_p_text">
                    This field is required.
                  </p>
                ) : null}
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
              <FormGroup>
                <Label for="devExperience" className="job_apply_input_lebal">
                  How many years of development experience do you have?{" "}
                  <span className="required_star_error">*</span>
                </Label>
                <Input
                  invalid={
                    formData?.devlopment_experience_in_year === "" && error
                  }
                  type="textarea"
                  rows={5}
                  className="job_apply_input_box"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      devlopment_experience_in_year: e.target.value,
                    })
                  }
                  value={formData?.devlopment_experience_in_year}
                  name="devExperience"
                />
                <FormFeedback
                  invalid={
                    formData?.devlopment_experience_in_year === "" && error
                  }
                  className="job_apply_feedback_text"
                >
                  This field is required.
                </FormFeedback>
              </FormGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 d-flex justify-content-end">
              <button
                type="submit"
                className="job_apply_submit_btn"
                onClick={handleSubmit}
              >
                {load ? (
                  <SavingProgress />
                ) : "Submit Application"}

              </button>
            </div>
          </div>
        </Form>
      </section>
    </React.Fragment>
  );
};

export default JobApplyForm;
