import React from 'react'

function HealthCareIntro() {
    return (
        <React.Fragment>
            <div>
                <div className="container pb-5 pt-5">
                    <div className="row pb-4 pt-4">
                        <div className="col-sm-12 col-md-4 col-lg-4 ">
                            <div className="industry_non_profit_design_desc_card_main">
                                <h2>Customizable Templates and Landing Pages</h2>
                                <p>
                                    Working to understand your team’s needs, we develop customizable templates for your services, products and content needs.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 ">
                            <div className="industry_non_profit_design_desc_card_main">
                                <h2>Intranet and Private Pages</h2>
                                <p>
                                    We can help you design and develop private portals for your staff or patients, providing a centralized and secure location for your information.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 ">
                            <div className="industry_non_profit_design_desc_card_main">
                                <h2>Content Optimized for Search Engines</h2>
                                <p>
                                    Our in-house experts can work with you to create an SEO strategy that will help your website appear at the top of the search engines.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}

export default HealthCareIntro