import React from "react";
import { Link } from "react-router-dom";
import ButtonHover from "../../../Helpers/ButtonHover";
import auditserviceBanner from '../../../../assets/capeark_img/services/auditserviceBanner.jpg'


function AuditServiceBannerItem() {
  return (
    <React.Fragment>
      {/* Css is used in one place (in main service folder) */}
      <div className="service_banner_item_cont_main_right">
        <div className="service_banner_item_bg_cont_right capeark-bg">
          <div className="row right_banner_flex service_banner_item_content_cont_right pt-5 pb-5 g-0 ">
            <div className="col-sm-12 col-md-6 col-lg-6 service_banner_desc_cont_right">
              <div className="service_banner_desc_media__body">
                <h2 className="service_banner_desc_media__title mb-4 text-white">
                  Our Services
                </h2>
                <div className="service_banner_desc_media__text text-white">
                  {/* <h2>Design for Delight</h2> */}
                  <p>
                    We perform a range of audits and can adapt them to your
                    specific needs.
                  </p>
                  <ul className="service_banner_desc_list text-white">
                    <li>Full website audit</li>
                    <li>Content and SEO audits</li>
                    <li>Technical site audit</li>
                    <li>Web accessibility audit</li>
                    <li>Multilingual site audit</li>
                  </ul>
                  <p>
                    Audits are a great starting point for envisioning your
                    platform’s future. Our strategy services take your ideas to
                    the next level, helping you pin down objectives and a course
                    of action.
                  </p>
                </div>

                <div className="mt-4">
                  <ButtonHover
                    btnLink={"/services"}
                    btnText={"Find Out More"}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 service_banner_hover_cont service_banner_column_right">
              <figure className="p-0 m-0 service_banner_img_cont">
                <img
                src={auditserviceBanner}
                  alt=""
                />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AuditServiceBannerItem;
