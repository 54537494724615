import React from "react";
import "./index.css";
import mernProgrammer from "../../../assets/capeark_img/services/mernProgrammer.png"
import meanProgrammer from "../../../assets/capeark_img/services/meanProgrammer.png"
import globe from "../../../assets/capeark_img/services/globe.png"
import appDevelopment from "../../../assets/capeark_img/services/appDevelopment.png"

function Technology() {
  return (
    <React.Fragment>
      <div className="container service_section_tech_main">
        <div className="row">
          <div className="service_section_tech_head col-sm-12">
            <h4>
              <span>Our Specialization</span>
            </h4>
            <p>
              These are some technology for custom IT services and solutions built specifically for your
              business
            </p>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3">
            <div className="service_section_tech_container">
              <div className="service_section_tech_card">
                <div className="service_section_tech_content">
                  <div className="service_section_tech_imgBx">
                    <img src={globe} alt="" />
                  </div>
                  <div className="service_section_tech_contentBx">
                    <h3>Web Development<br /><span>Web Design & Development</span></h3>
                  </div>
                </div>
                <div className="service_section_tech_sci">
                  <p>
                  Build the web application you need, on time with an experienced team that uses a clear and effective design and development process. Turn to our experts to perform comprehensive, multi-stage testing and auditing of your web app.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3 p-0">
            <div className="service_section_tech_container">
              <div className="service_section_tech_card">
                <div className="service_section_tech_content">
                  <div className="service_section_tech_imgBx">
                    <img src={mernProgrammer} alt="" />
                  </div>
                  <div className="service_section_tech_contentBx">
                    <h3>Mern stack<br /><span>Mern Stack Development</span></h3>
                  </div>
                </div>
                <div className="service_section_tech_sci">
                  <p>
                    MERN Stack Development is a full-stack web development approach that combines MongoDB, Express.js, React, and Node.js to build dynamic and scalable web applications using JavaScript.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3 ">
            <div className="service_section_tech_container">
              <div className="service_section_tech_card">
                <div className="service_section_tech_content">
                  <div className="service_section_tech_imgBx">
                    <img src={meanProgrammer} alt="" />
                  </div>
                  <div className="service_section_tech_contentBx">
                    <h3>Mean stack<br /><span>Mean Stack Development</span></h3>
                  </div>
                </div>
                <div className="service_section_tech_sci">
                  <p>
                    Mean Stack Development is a full-stack web development approach that combines MongoDB, Express.js, Angular, and Node.js to build dynamic and scalable web applications using JavaScript.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3">
            <div className="service_section_tech_container">
              <div className="service_section_tech_card">
                <div className="service_section_tech_content">
                  <div className="service_section_tech_imgBx">
                    <img src={appDevelopment} alt="" />
                  </div>
                  <div className="service_section_tech_contentBx">
                    <h3>iPhone & Android<br /><span>iPhone & Android App Development</span></h3>
                  </div>
                </div>
                <div className="service_section_tech_sci">
                  <p>
                    Flutter and React Native are cross-platform frameworks that allow developers to build mobile applications using a single codebase, with Flutter using Dart and React Native using JavaScript, respectively.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-md-6"></div> */}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Technology;
