import React from "react";
import { Helmet } from 'react-helmet';
import DevServicesBanner from "./Pages/DevServicesBanner";
import "./DevAndTechServices.css"
import DevTechProcessIntro from "./Pages/DevTechProcessIntro";
import ContactBottom from "../../Helpers/ContactBtn/ContactBottom";
function DevAndTechServices() {
    return (
        <React.Fragment>
            <Helmet>
                <meta charset="utf-8" />
                <title>
                    Capeark | Web Devlopement & Technology Services
                </title>
                <meta charset="utf-&" />
                <meta name="description" content="Web Devlopement & Technology Services for Success" />
                <meta name="keywords" content="Web development, App development, Mern stack, Mean stack, Audit Services, Strategy, Digital Products & Experience Designs, Ongoing Support" />
                <meta name="author" content="Capeark" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div className="service_header_main_cont">
                <div className="container mt-4 mb-5">
                    <div className="row mt-5">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <h6 className="service-page-header-title-text text-white">
                                DEVELOPMENT & TECHNOLOGY
                            </h6>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                            <h1 className="service-page-header-subtitle-text text-white">
                                Tech for Success
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="project-banner-clip-one"></div>
            <div className="">
                <div className="container">
                    <div className="row pt-5 pb-5">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <p className="service_intro_text">
                                We help organizations build a valuable digital presence and give
                                them the tools to own and grow intuitive, flexible and robust
                                digital platforms.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="container">
                    <div className="row pb-4">
                        <div className="col-sm-12 col-md-4 col-lg-4 ">
                            <div className="dev_tech_top_desc_card_main">
                                <h2>Guide & Build</h2>
                                <p>
                                    For simple websites or large, multi-channel platforms, we help
                                    you make strategic decisions based on technical feasibility,
                                    organizational fit, and long-term objectives. We deliver the
                                    appropriate level of development work and incorporate
                                    technology from partners whenever possible.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 ">
                            <div className="dev_tech_top_desc_card_main">
                                <h2>Improve & Support</h2>
                                <p>
                                    Launching your new platform is just the beginning, we offer
                                    continuous support with security and maintenance by working as
                                    an extension of your technical team or acting as your go-to
                                    experts. And as a full-service agency, we can help you grow
                                    and evolve your digital presence.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 ">
                            <div className="dev_tech_top_desc_card_main">
                                <h2>Empower</h2>
                                <p>
                                    We provide a powerful collaborative experience that gives your
                                    team the confidence to innovate and do more. We share
                                    knowledge openly and offer expert-led training to help your
                                    team take what we’ve built and run with it.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <DevServicesBanner />
            </div>
            <div>
                <DevTechProcessIntro />
            </div>
            <div>
                <ContactBottom
                    btnLink={"/contact"}
                    btnText={"Tell us About your Project"}
                    BannerText={"Let’s get started."}
                    textVarient={"h1"}
                />
            </div>
        </React.Fragment>
    );
}

export default DevAndTechServices;
