export function getTransformedText(text) {
    if (!text) {
        return []; // Return an empty array if text is undefined or empty
    }

    const regexPattern = /\*\*(.*?)\*\*/g;
    const parts = text.split(regexPattern);

    const transformedText = parts.map((part, index) => {
        if (index % 2 === 0) {
            return part;
        } else {
            return <strong style={{fontWeight:'bold'}} key={index}>{part}</strong>;
        }
    });

    return transformedText;
}
