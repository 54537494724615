import React from 'react'
import "./HomeMessage.css";
function HomeMessage() {
    return (
        <section className='home_msg_main_cont'>
            <div className='container'>
                <div className='row mt-5 mb-5'>
                    <div className='col-md-5 align-self-center'>
                    </div>
                    <div className='col-md d-flex'>
                        <div className='home_msg_main_content_cont'>
                            <h6 className='text-white'>Digital Transformation</h6>
                            <div className='home_comp_del_tech_line' ></div>
                            <h3 className='mt-4 text-white'>Enterprises today are innovating at an unprecedented rate.</h3>
                            <p className='text-white'>Entire industries are being born, disrupted, and consolidated. <br /><br />Digital transformation has become the cornerstone of modern business strategies, driving exponential growth, streamlining automation, and inspiring groundbreaking innovation. <br /><br />As businesses navigate the era of digital transformation, they are leveraging the potential of disruptive technologies to drive sustainable growth, unlock operational efficiencies through automation, and foster a spirit of innovation that fuels their evolution.</p>
                            <div class=" text-left text-white">
                                <blockquote className='text-white'>56% of CEOs say digital transformation has increased profits	</blockquote>
                                <h4 className="title text-white">— Gartner</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default HomeMessage