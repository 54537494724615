import React from 'react'
import { Link } from 'react-router-dom';
import "./index.css"
function Abouts({ colorLink, activeChild }) {
    return (
        <div>
            <Link to="/about" className={`dropdown-item ${activeChild === "/about" ? "active" : ""}`} onClick={() => colorLink("/about")}>
                Meet Capeark
            </Link>
            <Link to="/careers" className={`dropdown-item ${activeChild === "/careers" ? "active" : ""}`}  onClick={() => colorLink("/careers")}>
                Careers
            </Link>
            {/* <Link to="/" className="dropdown-item">
                Our Parteners
            </Link> */}
        </div>
    )
}

export default Abouts;