import React from 'react'
import { Helmet } from 'react-helmet';
import ContactBottom from '../../Helpers/ContactBtn/ContactBottom';
import NonProfitBanner from './Pages/NonProfitBanner';
import NonProfitIntro from './Pages/NonProfitIntro';
import NonProfitTextBanner from './Pages/NonProfitTextBanner';
import stareBanner from "../../../assets/Images/General/stare_banner.jpeg";
function NonProfit() {
    return (
        <React.Fragment>
            <Helmet>
                <meta charset="utf-8" />
                <title>
                    Capeark | Website Development for Non-Governmental Organizations
                </title>
                <meta charset="utf-&" />
                <meta name="description" content="Non-Governmental Organizations, Websites That Fully Support Your Mission and Initiatives" />
                <meta name="keywords" content="Government, Municipality, Higer education, Non profit, Non Governmental, Health care, Enterprises" />
                <meta name="author" content="Capeark" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div className="row position-relative industries-top-banner g-0">
                <img src={stareBanner} className="img-fluid p-0" alt="" />
                <div className="col-12 text-center industries-banner-content d-flex justify-content-center">
                    <div className="align-self-center industries-banner-content-child">
                        <h1>NON-GOVERNMENTAL ORGANIZATIONS</h1>
                        <p>
                            Websites That Fully Support Your Mission and Initiatives
                        </p>
                    </div>
                </div>
                <div className="industires-banner-clip-img">
                    <div className='industires-banner-clip-img-div'></div>
                </div>
            </div>
            <div>
                <NonProfitBanner />
            </div>

            <div>
                <NonProfitIntro />
            </div>
            <div>
                <NonProfitTextBanner />
            </div>
            <div>
                <ContactBottom
                    btnLink={'/contact'}
                    btnText={'Reach Out to Our Team'}
                    textVarient="h1"
                    BannerText={"We Look Forward to Working with You"}
                />
            </div>
        </React.Fragment>
    )
}

export default NonProfit