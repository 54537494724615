import React from 'react';
import "./index.css";
import seoservicesbanner from '../../../../assets/capeark_img/services/Seoservicesbanner.jpg'


function SeoServicesBanner() {
    return (
        <React.Fragment>
            {/* Css is used in one place (in main service folder) */}
            <div className="service_banner_item_cont_main">
                <div className="service_banner_item_bg_cont">
                    <div className="row service_banner_item_content_cont pt-5 pb-5 ">
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_hover_cont service_banner_column">
                            <figure className="p-0 m-0 service_banner_img_cont">
                                <img
                                    src={seoservicesbanner}
                                    alt=""
                                />
                            </figure>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_desc_cont">
                            <div className="service_banner_desc_media__body">
                                <h2 className="service_banner_desc_media__title mb-4">
                                    What We Do
                                </h2>
                                <div className="service_banner_desc_media__text">
                                    <p>
                                    We provide a full range of content strategy and SEO services tailored to your needs. We partner with your marketing team to make targeted improvements based on key metrics. Together we will:
                                    </p>
                                    <ul className="service_banner_desc_list ">
                                        <li>Run an SEO audit, identifying top performing pages and how to better leverage this content</li>
                                        <li>Examine content to determine what is delivering the best results and how to optimize it in the appropriate channels</li>
                                        <li>Align your content to support your brand, reach your target audience and deliver a positive user experience</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SeoServicesBanner;