import React from "react";
import "./footer.css";
import FullLogoDark from "../../assets/Images/FullLogoDark.png";
import { AiFillInstagram, AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { BsWhatsapp } from "react-icons/bs";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { GrLinkedinOption } from "react-icons/gr";
import { Link } from "react-router-dom";

export default function Footer() {
  const handlePhoneClick = () => {
    const phoneNumber = '7607619527';
    const dialerUrl = `tel:${phoneNumber}`;
    window.location.href = dialerUrl;
  };

  const handleEmailClick = () => {
    const emailAddress = 'capeark.info@gmail.com';
    const emailUrl = `mailto:${emailAddress}`;
    window.location.href = emailUrl;
  };
  const handleWhatsAppClick = () => {
    const phoneNumber = '+918953432271';
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.location.href = whatsappUrl;
  };

  let origin = window?.location?.origin;
  return (
    <React.Fragment>
      <section className="globalbgcolor">

        <div className="capeark-footer">
          <div className="capeark-footer-cont container">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-3">
                <div className="footer-row-logo">
                  <img src={FullLogoDark} alt="" />
                </div>
                <h3 className="ml-2 footer-bottom-heading-cont">Contact Us</h3>
                <div className="footer-bottom-sublink-cont ml-3">
                  <p onClick={handleEmailClick}><span className="icon mr-1"><AiOutlineMail /> </span>capeark.info@gmail.com</p>
                  <p onClick={handlePhoneClick}><span className="icon mr-1"><AiOutlinePhone style={{ transform: "rotate(90deg)" }} /> </span>+917607619527</p>
                  <p onClick={handleWhatsAppClick}><span className="icon mr-1"><BsWhatsapp />  </span>+918953432271</p>
                </div>
              </div>
              <div className=" col-sm-12 col-md-6 col-lg-3 ">
                <h3 className="footer-bottom-heading-cont">Our Services</h3>
                <div className="footer-bottom-sublink-cont">
                  <Link className="footer_link" to='/services'><p className="footer-row-column">Android App Development</p></Link>
                  <Link className="footer_link" to='/services'><p className="footer-row-column">IOS App Development</p></Link>
                  <Link className="footer_link" to='/services'> <p className="footer-row-column">Web Design & Development</p></Link>
                  <Link className="footer_link" to='/services'> <p className="footer-row-column">Mean Stack Development</p></Link>
                  <Link className="footer_link" to='/services'> <p className="footer-row-column">Mern Stack Development</p></Link>
                  <Link className="footer_link" to='/services'> <p className="footer-row-column">Cloud Computing</p></Link>
                  <Link className="footer_link" to='/services'>  <p className="footer-row-column">Digital Marketing</p></Link>
                </div>
              </div>
              <div className=" col-sm-12 col-md-6 col-lg-3 ">
                <h3 className="footer-bottom-heading-cont">Blog</h3>
                <div className="footer-bottom-sublink-cont">
                  <Link className="footer_link" to={`${origin}/blog/1`} target="_blank"><p className="footer-row-column">How will ChatGPT redefine the future of telecommunications?</p></Link>
                  <Link className="footer_link" to={`${origin}/blog/4`} target="_blank"><p className="footer-row-column">Website Development for Government Agencies</p></Link>
                  <Link className="footer_link" to={`${origin}/blog/6`} target="_blank"> <p className="footer-row-column">Introducing Prompt Engineering (Unlock the Power of GPT)</p></Link>
                  <Link className="footer_link" to={`${origin}/blog/7`} target="_blank"> <p className="footer-row-column">Best Frontend Frameworks for Web Development</p></Link>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-3 ">
                <h3 className="footer-bottom-heading-cont">Address</h3>
                <div className="footer-bottom-sublink-cont">
                  <h6 className="footer-row-column">Headquarter (Hyderabad):</h6>
                  <p className="footer-row-column">
                    Gachibowli, Hyderabad – 500032, India.
                    <br />
                    ( Our team is operating remotely )
                  </p>
                </div>
              </div>

            </div>

          </div>
        </div>

        <div className="capeark-bottom-footer">
          <div className="container capeark-bottom-footer-cont">
            <div className="row footer_bottom_flex">
              <div className="bottom-footer-terms-cont col-sm-12 col-md-6 col-lg-6">All rights reserved by Capeark. Copyright ©2024</div>
              <div className="bottom-footer_social_cont col-sm-12 col-md-6 col-lg-6">
                <Link to="https://www.facebook.com/capearkofficial" target={"/"} className="bottom-footer_social_icon_cont"> <FaFacebookF /> </Link>
                <Link to="https://www.instagram.com/capearkofficial" target={"/"} className="bottom-footer_social_icon_cont"><AiFillInstagram /></Link>
                <Link to='https://twitter.com/capearkofficial' target={"/"} className="bottom-footer_social_icon_cont"> <FaXTwitter /></Link>
                <Link to="https://www.linkedin.com/company/capeark" target={"/"} className="bottom-footer_social_icon_cont"> <GrLinkedinOption /></Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}



