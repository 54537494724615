import React, { useState } from 'react'
import { AiOutlineMail } from "react-icons/ai";
import { BsPhone, BsBuildings, } from "react-icons/bs";
import { BiUser, BiListCheck } from "react-icons/bi";

import './css/GetInTouch.css'
import { createNewContact } from '../../helpers/dataFetcher/Contact/Contact';
import { toast } from 'react-toastify';
import SavingProgress from '../../constants/Loader/SavingProgress';

const GetInTouch = () => {

  const [btnClick, setBtnClick] = useState(false)

  const initFormData = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    company: "",
    contact_about: "",
    hear_through: "",
    message: ""
  }

  const [formData, setFormData] = useState(initFormData);
  const [error, setError] = useState(false);
  const [load, setLoad] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    setLoad(true);

    if (formData.first_name === "") {
      setLoad(false)
      setError(true);
      return;
    }
    if (!validateEmail(formData.email) || formData.email === "") {
      setLoad(false)
      setError(true);
      return;
    }
    if (formData.company === "") {
      setLoad(false)
      setError(true);
      return;
    }
    if (formData.contact_about === "") {
      setLoad(false)
      setError(true);
      return;
    }
    if (formData.hear_through === "") {
      setLoad(false)
      setError(true);
      return;
    }
    if (formData.message === "") {
      setLoad(false)
      setError(true);
      return;
    }


    // After successfully submition setLoad(false

    setTimeout(function () { setLoad(false) }, 20000)
    const { status } = await createNewContact(formData);
    if (status === 201) {
      toast.success("Data sent to our team, we will reach out to you soon!")
      setBtnClick(true);
      executedForm();
      setLoad(false);
    }

    setLoad(false);
  }


  //  check email validation 
  function validateEmail(inputText) {
    // var mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var mailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (inputText !== "") {
      if (inputText.match(mailFormat)) {
        return true;
      } else {
        return false;
      }
    } else {
      return
    }
  }

  function executedForm() {
    setTimeout(function () { setBtnClick(false) }, 5000);

  }

  return (
    <React.Fragment>
      <section className='container mb-5 p-2' >
        <div className='row p-2'>
          <div className='col-sm-12 col-md-2 col-lg-2'></div>
          <div className='col-sm-12 col-md-8 col-lg-8 '>
            <h3 className='text-center mb-5'>Get in touch</h3>
            <div className=' '>
              <form className=' mt-3' onSubmit={(e) => handleSubmit(e)}>
                <div className='row g-0'>

                  <div className='col-sm-12 col-md-12 col-lg-3 d-flex align-items-center text-truncate'>
                    <label className='mb-0 text-truncate getintouch-label'>Name <span className={`${formData.first_name === "" && error ? "required_star_error" : "required_star"}`}>*</span></label>
                  </div>
                  <div className='col-sm-12 col-md-12 col-lg-9'>
                    <div className='GetInTouch_input_field_cont '>
                      <div className="getintouch-input-container">
                        <BiUser className="getintouch-input-icon" />
                        <input
                          type="text"
                          className="getintouch-input"
                          placeholder="First Name"
                          onChange={(e) => setFormData({ ...formData, first_name: e.target.value })}
                        />
                      </div>
                      <div className="getintouch-input-container getintouch-input-last-name">
                        <input
                          type="text"
                          className="getintouch-input getintouch-input-without-icon  "
                          placeholder="Last Name"
                          onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}
                        />
                      </div>
                    </div>
                    {formData.first_name === "" && error ? (
                      <span className='form_feedback_error_text'>Name is required.</span>
                    ) : null}
                  </div>
                </div>

                <div className='row g-0 mt-4'>
                  <div className='col-sm-12 col-md-12 col-lg-3 d-flex align-items-center text-truncate'>
                    <label className='m-0 text-truncate getintouch-label'>Contact me by email <span className={`${!validateEmail(formData.email) && error ? "required_star_error" : "required_star"}`}>*</span></label>
                  </div>
                  <div className='col-sm-12 col-md-12 col-lg-9'>
                    <div className="getintouch-input-container">
                      <AiOutlineMail className="getintouch-input-icon" />
                      <input
                        type="email"
                        className="getintouch-input "
                        placeholder="Email"
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                      />

                    </div>
                    {!validateEmail(formData.email) && error ? (
                      <span className='form_feedback_error_text'>{formData.email === "" ? "Email id is required." : "Invalid Email id."}</span>
                    ) : null}
                  </div>
                </div>

                <div className='row g-0 mt-4'>
                  <div className='col-sm-12 col-md-12 col-lg-3 d-flex align-items-center text-truncate'>
                    <label className='m-0 text-truncate getintouch-label'>Or call me</label>
                  </div>
                  <div className='col-sm-12 col-md-12 col-lg-9'>
                    <div className="getintouch-input-container">
                      <BsPhone className="getintouch-input-icon" />
                      <input
                        type="text"
                        className="getintouch-input"
                        placeholder="Phone"
                        onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                      />
                    </div>
                  </div>
                </div>


                <div className='row g-0 mt-4'>
                  <div className='col-sm-12 col-md-12 col-lg-3 d-flex align-items-center text-truncate'>
                    <label className='m-0 text-truncate getintouch-label'>I work for <span className={`${formData.company === "" && error ? "required_star_error" : "required_star"}`}>*</span></label>
                  </div>
                  <div className='col-sm-12 col-md-12 col-lg-9'>
                    <div className="getintouch-input-container">
                      <BsBuildings className="getintouch-input-icon" />
                      <input
                        type="text"
                        className="getintouch-input"
                        placeholder="Company"
                        onChange={(e) => setFormData({ ...formData, company: e.target.value })}
                      />
                    </div>
                    {formData.company === "" && error ? (
                      <span className='form_feedback_error_text'>Please complete this required field.</span>
                    ) : null}
                  </div>
                </div>


                <div className='row g-0 mt-4'>
                  <div className='col-sm-12 col-md-12 col-lg-3 d-flex align-items-center text-truncate'>
                    <label className='m-0 text-truncate getintouch-label'>I would like to talk about <span className={`${formData.contact_about === "" && error ? "required_star_error" : "required_star"}`}>*</span></label>
                  </div>
                  <div className='col-sm-12 col-md-12 col-lg-9'>
                    <div className="getintouch-input-container">
                      <BiListCheck className="getintouch-input-icon" />
                      <select
                        type="text"
                        className="getintouch-input"
                        placeholder="Please Select"
                        onChange={(e) => setFormData({ ...formData, contact_about: e.target.value })}
                      >
                        <option value="" disabled selected>Please Select</option>
                        <option value="project_quote" >Project Quote</option>
                        <option value="maintenance_support" >Maintenance and Support</option>
                        <option value="site_audit_consulting" >Site Audit and Consulting</option>
                        <option value="general_enquiry" >General Enquiry</option>
                      </select>
                    </div>
                    {formData.contact_about === "" && error ? (
                      <span className='form_feedback_error_text'>Please complete this required field.</span>
                    ) : null}
                  </div>
                </div>


                <div className='row g-0 mt-4'>
                  <div className='col-sm-12 col-md-12 col-lg-3 d-flex align-items-center text-truncate'>
                    <label className='m-0 text-truncate getintouch-label'>How did you hear about us? <span className={`${formData.hear_through === "" && error ? "required_star_error" : "required_star"}`}>*</span></label>
                  </div>
                  <div className='col-sm-12 col-md-12 col-lg-9'>
                    <div className="getintouch-input-container">
                      <BiListCheck className="getintouch-input-icon" />
                      <select
                        type="text"
                        className="getintouch-input"
                        placeholder="Please Select"
                        onChange={(e) => setFormData({ ...formData, hear_through: e.target.value })}
                      >
                        <option value="" disabled selected>Please Select</option>
                        <option value="search_engine" >Search Engine</option>
                        <option value="social_media" >Social Media</option>
                        <option value="word_of_mouth" >Word of Mouth</option>
                        <option value="advertisement" >Advertisement</option>
                        <option value="link_from_another_website" >Link from Another website</option>
                        <option value="event" >Event</option>
                        <option value="other" >Other</option>
                      </select>
                    </div>
                    {formData.hear_through === "" && error ? (
                      <span className='form_feedback_error_text'>Please complete this required field.</span>
                    ) : null}
                  </div>
                </div>


                <div className='row g-0 mt-4'>
                  <div className='col-sm-12 col-md-12 col-lg-12 d-flex align-items-center text-truncate'>
                    <label className='m-0 text-truncate getintouch-label'>Message <span className={`${formData.message === "" && error ? "required_star_error" : "required_star"}`}>*</span></label>
                  </div>
                  <div className='col-sm-12 col-md-12 col-lg-12'>
                    <div className="getintouch-input-container getintouch-input-container-with-textarea mt-2">
                      <textarea
                        className="getintouch-textarea"
                        placeholder="Your Message"
                        onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                      />
                    </div>
                    {formData.message === "" && error ? (
                      <span className='form_feedback_error_text'>Please complete this required field.</span>
                    ) : null}
                  </div>
                </div>
                <div className='getintouch_submit_button_cont mt-4 mb-4'>
                  <button id="btn" disabled={load} className={`getintouch_submit_button ${btnClick ? 'getintouch-active' : ""}`} onClick={(e) => { handleSubmit(e) }}>
                    <p className='mb-0'>{load ? <SavingProgress /> : <>{btnClick ? "Thank you!" : "Submit"}</>}</p>
                    <div className="getintouch-check-box">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                        <path fill="transparent" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                      </svg>
                    </div>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className='col-sm-12 col-md-2 col-lg-2'></div>
        </div>
      </section>

    </React.Fragment>
  )
}

export default GetInTouch
