import React, { useEffect, useState } from "react";
import "./App.css";
import AppRoutes from "./routes/AppRoutes";
import { BrowserRouter as Router } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/reset.css";
// import "react-phone-input-2/lib/style.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'react-slideshow-image/dist/styles.css';
import WOW from 'wowjs';
import BackToTopButton from "./Layout/Scroll/ScrollToTop";
import ScrollFromTop from "./Layout/Scroll/ScrollFromTop";

function App() {

  const currentUrl = window.location.pathname;
  const [isOnline, setIsOnline] = useState(navigator.onLine); // Initial network status

  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentUrl]);




  useEffect(() => {
    // Set up an event listener to track network status changes
    const handleNetworkChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', handleNetworkChange);
    window.addEventListener('offline', handleNetworkChange);

    // Clean up the event listeners when the component is unmounted
    return () => {
      window.removeEventListener('online', handleNetworkChange);
      window.removeEventListener('offline', handleNetworkChange);
    };
  }, []);

  if (!isOnline) {
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        height: "100vh"
      }}>
        <h6>Please connect to the internet</h6>
        <p>You're offline. Check your connection.</p>
        <button
          style={{
            padding: "0px 15px",
            border: "1px sollid gray",
            borderRadius: "50px",
          }}
          onClick={() => { window.location.reload() }}
        >Retry</button>
      </div>
    )
  }

  return (
    <div className="App">
      <BackToTopButton />
      <Router>
        <ScrollFromTop />
        <AppRoutes />
        <Toaster position="top-right" reverseOrder={false} />
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Router>
    </div>
  );
}

export default App;
