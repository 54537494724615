import React, { useState } from "react";
import "./index.css";
import AllProjects from "./Pages/AllProjects";
import InterPrise from "./Pages/Interprise";
import HigherEducation from "./Pages/HigherEducation";
import NonProfit from "./Pages/NonProfit";
import Government from "./Pages/Government";
import Travel from "./Pages/Travel";
import HealthCare from "./Pages/Healthcare";
import { AllProject, InterpriseData, HigherEduData, NonProfitData, GovernmentData, TravelData, HealthcareData } from "../../helpers/projectsData";
import { Helmet } from 'react-helmet';
function Projects() {
    const [tabValue, setTabValue] = useState("0")
    return (
        <React.Fragment>
            <Helmet>
                <meta charset="utf-8" />
                <title>
                    Capeark | Storytelling Without Limits
                </title>
                <meta charset="utf-&" />
                <meta name="description" content="You can trust Capeark expertise to help your reach your goals. See how we’ve helped organizations like yours make an impact." />
                <meta name="keywords" content="All,Travel, Government, Municipality, Higer education, Non profit, Non Governmental, Health care, Enterprises" />
                <meta name="author" content="Capeark" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div className="project_header_main_cont">
                <div className='container mt-4 mb-5'>
                    <div className='row mt-5'>
                        <div className='col-sm-12 col-md-12 col-lg-12'>
                            <h1 className="project-page-header-title-text text-white">Storytelling Without Limits</h1>
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-12 mb-4'>
                            <h6 className="project-page-header-subtitle-text text-white">You can trust Capeark's expertise to help you reach your goals. See how we've helped organizations like yours make an impact.</h6>
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-12'>
                            <div className="project_custom_TabContext">
                                <div className="project_custom_tab_list_cont">
                                    <div className={`project_custom_tab_list ${tabValue === "0" ? "project_custom_tab_list_active" : ""}`} onClick={() => setTabValue("0")}>

                                        <div>All <span className="custom_tab_list_tab_count">{AllProject?.length}</span></div>
                                    </div>
                                    <div className={`project_custom_tab_list ${tabValue === "1" ? "project_custom_tab_list_active" : ""}`} onClick={() => setTabValue("1")}>
                                        <div>Enterprise <span className="custom_tab_list_tab_count">{InterpriseData?.length}</span></div>
                                    </div>
                                    {/* <div className={`project_custom_tab_list ${tabValue === "2" ? "project_custom_tab_list_active" : ""}`} onClick={() => setTabValue("2")}>
                                        <div>Higher Education  <span className="custom_tab_list_tab_count">{HigherEduData?.length}</span></div>
                                    </div>
                                    <div className={`project_custom_tab_list ${tabValue === "3" ? "project_custom_tab_list_active" : ""}`} onClick={() => setTabValue("3")}>
                                        <div>Non-Profit  <span className="custom_tab_list_tab_count">{NonProfitData?.length}</span></div>
                                    </div>
                                    <div className={`project_custom_tab_list ${tabValue === "4" ? "project_custom_tab_list_active" : ""}`} onClick={() => setTabValue("4")}>
                                        <div>Government  <span className="custom_tab_list_tab_count">{GovernmentData?.length}</span></div>
                                    </div>
                                    <div className={`project_custom_tab_list ${tabValue === "5" ? "project_custom_tab_list_active" : ""}`} onClick={() => setTabValue("5")}>
                                        <div>Travel  <span className="custom_tab_list_tab_count">{TravelData?.length}</span></div>
                                    </div>
                                    <div className={`project_custom_tab_list ${tabValue === "6" ? "project_custom_tab_list_active" : ""}`} onClick={() => setTabValue("6")}>
                                        <div>Healthcare  <span className="custom_tab_list_tab_count">{HealthcareData?.length}</span></div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='project-banner-clip-one'></div>

            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        {tabValue === "0" ? <div sx={{ padding: 0 }}>

                            <AllProjects />

                        </div> : null}

                        {tabValue === "1" ? <div sx={{ padding: 0 }}>

                            <InterPrise />

                        </div>
                            : null}
                        {tabValue === "2" ? <div sx={{ padding: 0 }}>

                            <HigherEducation />

                        </div>
                            : null}
                        {tabValue === "3" ? <div sx={{ padding: 0 }}>

                            <NonProfit />

                        </div>
                            : null}
                        {tabValue === "4" ? <div sx={{ padding: 0 }}>

                            <Government />

                        </div>
                            : null}
                        {tabValue === "5" ? <div sx={{ padding: 0 }}>

                            <Travel />

                        </div>
                            : null}
                        {tabValue === "6" ? <div sx={{ padding: 0 }}>

                            <HealthCare />

                        </div>
                            : null}
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

export default Projects;