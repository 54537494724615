import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './css/jobsOpening.css'
import { Helmet } from 'react-helmet';
import JobApplyForm from './Pages/JobApplyForm';
import JobDetailsPage from './Pages/JobDetailsPage';
import { getJobsById } from '../../helpers/dataFetcher/Jobs/Jobs';
import MainLoader from '../../helpers/loader/Loader';

const JobsOpening = () => {

    const [tabValue, setTabValue] = useState("0");
    const { job_id } = useParams();
    const [jobDetails, setJobDetails] = useState({});
    const [load, setLoad] = useState(false);

    async function getDetailJob() {
        setLoad(true);
        const { status, data } = await getJobsById(job_id);
        if (status === 200) {
            setLoad(false);
        }
        setLoad(false);
        setJobDetails(data?.data);
    }

    useEffect(() => {
        getDetailJob();
    }, [])


    return (
        <React.Fragment>
            <Helmet>
                <meta charset="utf-8" />
                <title>
                    Capeark | Careers
                </title>
                <meta charset="utf-&" />
                <meta name="description" content="Capeark Careers, Build a future you believe in." />
                <meta name="keywords" content="Web development, App development, Mern stack, Mean stack, Audit Services, Strategy, Digital Products & Experience Designs, Ongoing Support" />
                <meta name="author" content="Capeark" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div className='hiring_main_wraper'>
                <div className="hiring_main_cont">
                    <div className="container mt-4 mb-1">
                        <div className="row mt-3 ">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <div className="mb-4">
                                    <h6 className="careers-page-header-title-text text-white">
                                        CAREERS
                                    </h6>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mb-2">
                                <h2 className="text-white">
                                    Looking for Career Opportunities?
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="project-banner-clip-one"></div>
            {/* <div className="container pb-2 mt-5 mb-5">
                <div className="row">
                    <div className="col-12 col-md-8 m-auto text-center">
                        <h1 className="text-muted">Career at Capeark</h1>
                        <div className="w-100 mb-4"><div className="home_footer_get_started_line"> </div></div>
                        <p className="mb-4 fs-5">Interested to Join our Team? Then please get in touch and we’ll be glad to help...</p>
                    </div>
                </div>
            </div> */}
            {/* <div className='container mt-5 mb-5 g-0 '>
                <div className='row g-0 '>
                    <div className='col-md-4 mb-4 g-0 mb-2 p-3'>
                        <div className='job-opening-box-shadow text-center pt-4 pb-4 pl-2 pr-2 bg-white  bounceInLeft contact-us-hover-effects animated' style={{ visibility: "visible" }}>
                            <div className="map-icon">
                                <i className="fa fa-map-marker" aria-hidden="true"></i>
                            </div>
                            <div className='contact_address_card_child_2'>
                                <h3>Branch Office Address</h3>
                                <p className="">
                                    12, 3rd Floor, SM Road, (above Reliance Fresh), Prashanth Nagar, Jalahalli Cross, Bangalore – 560057, Karnataka, India.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 mb-4 g-0 mb-2 p-3'>
                        <div className='job-opening-box-shadow text-center pt-4 pb-4 pl-2 pr-2 bg-white  bounceInLeft contact-us-hover-effects animated' style={{ visibility: "visible" }}>
                            <div className="map-icon">
                                <i className="fa fa-map-marker" aria-hidden="true"></i>
                            </div>
                            <div className='contact_address_card_child_2'>
                                <h3> Headquarter</h3>
                                <p className="">
                                    12, 3rd Floor, SM Road, (above Reliance Fresh), Prashanth Nagar, Jalahalli Cross, Bangalore – 560057, Karnataka, India.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 mb-4 g-0 mb-2 p-3'>
                        <div className='job-opening-box-shadow text-center pt-4 pb-4 pl-2 pr-2 bg-white  bounceInLeft contact-us-hover-effects animated' style={{ visibility: "visible" }}>
                            <div className="map-icon">
                                <i class="fa fa-hourglass-start" aria-hidden="true"></i>
                            </div>
                            <div className='contact_address_card_child_2'>
                                <h3>Address</h3>
                                <p className="">
                                    12, 3rd Floor, SM Road, (above Reliance Fresh), Prashanth Nagar, Jalahalli Cross, Bangalore – 560057, Karnataka, India.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div> */}

            <div className='jobopening_details_main'>
                <div className='jobopening_details_content'>
                    <h2>
                        {jobDetails?.job_name}
                    </h2>
                    <p>{jobDetails?.job_type}</p>
                    <div>
                        <span>{jobDetails?.job_location} </span>&#x2022;<span> {jobDetails?.job_role} </span>&#x2022;<span> {jobDetails?.employment_type}</span>
                    </div>
                </div>
                <div className='jobopening_details_tab_cont'>
                    <div className="jobopening_custom_tab_list_cont">
                        <div className={`jobopening_custom_tab_list ${tabValue === "0" ? "jobopening_custom_tab_list_active" : ""}`} onClick={() => setTabValue("0")}>
                            <div>Summary</div>
                            <span></span>
                        </div>
                        <div className={`jobopening_custom_tab_list ${tabValue === "1" ? "jobopening_custom_tab_list_active" : ""}`} onClick={() => setTabValue("1")}>
                            <div>Application</div>
                            <span></span>
                        </div>
                    </div>
                </div>
                <div className='jobopening_details_job_details_main pt-5 pb-5'>
                    <div className='jobopening_details_job_details_cont container'>
                        <div className="row">
                            <div className='col-sm-12 col-lg-2 col-md-2'></div>
                            <div className='col-sm-12 col-lg-8 col-md-8'>
                                {load ? (
                                    <MainLoader />
                                ) : (
                                    <>
                                        {tabValue === "0" ? (<JobDetailsPage jobDetails={jobDetails} setTabValue={setTabValue} />) : null}
                                        {tabValue === "1" ? (<JobApplyForm />) : null}
                                    </>
                                )}
                            </div>
                            <div className='col-sm-12 col-lg-2 col-md-2'></div>
                        </div>
                    </div>
                </div>
                <div className='jobopening_details_footer_main'>
                    <Link to="/careers">View all Jobs</Link>
                </div>
            </div>
        </React.Fragment>
    );
};

export default JobsOpening;
