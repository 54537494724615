import React, { useEffect, useState } from "react";
import BlogsCard from "./Pages/BlogsCard";
import BlogFilterTab from "./Pages/BlogFilterTab";
import MainLoader from "../../helpers/loader/Loader";
import { Helmet } from 'react-helmet';
import stareBanner from "../../assets/Images/General/stare_banner.jpeg";
import { AllBlogData } from '../../helpers/dataFetcher/Blog/AllBlogData';
import { getFilterKey } from "../Helpers/GetFilterKey";

function Blog() {

    const itemsPerPage = 6;

    const [currentPage, setCurrentPage] = useState(1)
    const [pages, setPages] = useState(1)
    const [filterData, setFilterData] = useState([]);
    const [load, setLoad] = useState(false);
    const [blogsData, setBlogsData] = useState();


    useEffect(() => {
        getBlogData(1);
        getAllFilterData()

    }, [])

    function getAllFilterData() {
        const filters = getFilterKey(AllBlogData, "category");
        setFilterData(filters)
    }

    function getBlogData(page, filter) {

        setLoad(true);
        const filteredData = AllBlogData?.filter((item) =>
            item.category.toLowerCase().includes(!filter || filter === "all" ? "" : filter?.toLowerCase())
        );
        // for PAginations   
        const totalPageCount = Math.ceil(filteredData.length / itemsPerPage);
        const startIndex = (page - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const currentItems = filteredData.slice(startIndex, endIndex);
        setPages(totalPageCount);
        setBlogsData(currentItems);
        setLoad(false);
    }

    return (
        <React.Fragment>
            <Helmet>
                <meta charset="utf-8" />
                <title>Capeark | News, Update & Thoughts</title>
                <meta charset="utf-&" />
                <meta name="description" content="Blogs and Articles. Be the change you wish to see in the world." />
                <meta name="keywords" content="Web development, App development, Mern stack, Mean stack, Strategy, Digital Products & Experience Designs, Ongoing Support, technology" />
                <meta name="author" content="Capeark" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div className="row position-relative industries-top-banner g-0">
                <img
                    src={stareBanner}
                    className="img-fluid p-0"
                    alt="blogimage"
                />
                <div className="col-12 text-center industries-banner-content d-flex justify-content-center">
                    <div className="align-self-center industries-banner-content-child">
                        <h1>Blogs and Articles</h1>
                        <p>
                            Be the change you wish to see in the world.
                        </p>
                    </div>
                </div>
                <div className="industires-banner-clip-img">
                    <div className="industires-banner-clip-img-div"></div>
                </div>
            </div>
            {load ? (
                <MainLoader />
            ) : (
                <>
                    <div>
                        <BlogFilterTab page={currentPage} filterData={filterData} getBlogData={getBlogData} />
                    </div>
                    <div>
                        <BlogsCard pages={pages} page={currentPage} blogsData={blogsData} setBlogsData={setBlogsData} getBlogData={getBlogData} setCurrentPage={setCurrentPage} />
                    </div>
                </>
            )}

        </React.Fragment>
    );
}

export default Blog;
