import React from 'react'
import "./index.css";
function GeorgiaTechLibrary() {
    return (
        <React.Fragment>
            <div className="project_details_banner_cont">
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-6 col-md-3 col-lg-2 project_details_banner_logo'>
                            <div>
                                <img src='https://evolvingweb.com/sites/default/files/2023-01/GTL%20Logo_0.png' alt='' />
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-12'>
                            <div className='mt-2 project_details_banner_details'>
                                <h1>
                                    An Engaging New Refresh for an Award-Winning Library
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className='container mt-5 mb-5'>
                    <div className='row'>
                        <div className='col-sm-12 col-lg-12 col-md-12 project_detail_overview_cont'>
                            <h6>
                                OVERVIEW
                            </h6>
                            <p>
                                The Georgia Tech Library approached Capeark in late 2021 for the purpose of updating its website. While the main impetus behind the project was migrating the library’s existing site from Drupal 8 to Drupal 9, the library also sought to update its brand identity in line with its recent renovations while incorporating new features to better serve its community amid a growing range of public offerings.
                            </p>

                            <div className='project_detail_overview_img_cont'>
                                <img src="https://evolvingweb.com/sites/default/files/2023-01/GTL_1.jpg" alt="" />
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-12 col-md-12 project_detail_overview_cont'>
                            <h6>
                                ABOUT THE CLIENT
                            </h6>
                            <p>
                                The Georgia Tech Library is an academic library system that serves the needs of the students, faculty and staff of the Georgia Institute of Technology. It consists of two physical buildings, the S. Price Gilbert Memorial Library and the Dorothy M. Crosland Tower – both located on Georgia Tech’s main campus in Atlanta, Georgia, while also managing the Clough Undergraduate Learning Commons.
                            </p>
                            <p>
                                In 2007 the Georgia Tech Library was awarded the Excellence in Academic Libraries Award by the Association of College and Research Libraries (ACRL) for its integration of digital technology and renewed focus on the studying needs of students. The library underwent extensive renovations between 2017 and 2020, starting with the Crosland Tower and continuing with the Price Gilbert Library.
                            </p>
                            <p>
                                The library’s new facilities include a Science Fiction Lounge, Audio and Video Recording Studios, the Scholars Event Network, a Data Visualization Lab, a Faculty Research Zone, a Graduate Student Community and extensive computing clusters and numerous reservable meeting and collaboration rooms. It also offers a wide range of free classes, lectures and workshops for students, faculty and the campus community.
                            </p>
                            <div className='project_detail_overview_img_cont'>
                                <img src="https://evolvingweb.com/sites/default/files/2023-01/GTL_2.jpg" alt="" />
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-12 col-md-12 project_detail_overview_cont'>
                            <h6>
                            GOALS
                            </h6>
                            <p>
                            Aside from the need to upgrade its existing website from Drupal 8 to Drupal 9, the Georgia Tech Library needed better search functionality in light of its expanding range of public classes, lectures and workshops. Visually, the library’s site had not been updated since before its recent renovations and the existing site did not capture the beauty and dynamism of this increasingly modern and eclectic library system. There was a threefold need for site migration, improved functionality and a fresh brand identity.
                            </p>
                          
                            <div className='project_detail_overview_img_cont'>
                                <img src="https://evolvingweb.com/sites/default/files/2023-01/GTL_3.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default GeorgiaTechLibrary;