import React from 'react'
import "./index.css";
import icons_DIGITAL from "../../../../assets/capeark_img/services/icons/icons_DIGITAL GROWTH.svg";
import icons_USER from "../../../../assets/capeark_img/services/icons/icons_USER EXPERIENCE.svg";
import icons_GOVERNANCE from "../../../../assets/capeark_img/services/icons/icons_GOVERNANCE.svg";


function Marketingintro() {
    return (
        <React.Fragment>
            <div className='container'>
                <div className='marketing_strategy_header_main'>
                    <h2>BENEFITS OF WORKING WITH OUR MARKETING TEAM</h2>
                </div>
                <div className='row pb-2 pt-2'>
                    <div className='col-sm-12 col-md-6 col-lg-4'>
                        <div className='marketing_strategy_card_main'>
                            <div className='mt-2'>
                                <img src={icons_USER} alt='' />
                            </div>
                            <div>
                                <h4 className='mt-3 mb-3'>CONNECT WITH YOUR AUDIENCE</h4>
                                <p>Capeark helps you improve your content tooling by creating style guides, content calendars, dashboards and consistent templates.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-4'>
                        <div className='marketing_strategy_card_main'>
                            <div className='mt-2'>
                                <img src={icons_DIGITAL} alt='' />
                            </div>
                            <div>
                                <h4 className='mt-3 mb-3'>MAKE YOUR MARK</h4>
                                <p>We deliver projects that increase traffic, engagement and conversion by working closely with a wider team of UX designers, and graphic designers.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-4'>
                        <div className='marketing_strategy_card_main'>
                            <div className='mt-2'>
                                <img src={icons_GOVERNANCE} alt='' />
                            </div>
                            <div>
                                <h4 className='mt-3 mb-3'>BUILD LONG-TERM INDEPENDENCE</h4>
                                <p>We equip you with the skills to run your marketing campaigns and help you adjust them as necessary based on the latest technological and market trends.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Marketingintro