import React from 'react';
import "./HomeMessage.css";
import ButtonHover from '../../Helpers/ButtonHover';

function HomeIntroBlockOne() {
    return (
        <section className='home_intro_block_one_main'>
            <div className='container home_intro_block_one_cont'>
                <div className='row g-0'>
                    <div className='col-xs-12 col-sm-12 col-md-4 col-lg-4 p-2 home_intro_block_one_card_content'>
                        <h4>Unique methodology</h4>
                        <p>
                            We don't create one-size-fits-all solutions. Instead, we invest the effort to tailor our approach to meet your users' requirements.
                        </p>
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-4 col-lg-4 p-2 home_intro_block_one_card_content'>
                        <h4>Long-term partnerships</h4>
                        <p>
                            We establish enduring Long-term partnerships to provide you with substantial guidance on all digital matters.
                        </p>
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-4 col-lg-4 p-2 home_intro_block_one_card_content'>
                        <h4>Cyber powerhouse</h4>
                        <p>
                            We are a leading force in the cyber realm—a close-knit team of specialists prepared to offer you tailored assistance.
                        </p>
                    </div>

                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 p-2 ' style={{ display: "flex", justifyContent: "flex-end" }}>
                        <ButtonHover
                            btnLink={"/about"}
                            btnText={"Meet our Team"}
                        />
                    </div>
                </div>
            </div>

        </section>
    )
}

export default HomeIntroBlockOne;