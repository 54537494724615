import React from "react";
import { Slide } from "react-slideshow-image";
import "./HomeFooterContent.css";
import { Link } from "react-router-dom";
import content_bottom_img from "../../../assets/capeark_img/home/content-bottom-bg.jpeg"

import angular_icon from '../../../assets/capeark_img/home/TechIcons/angular.svg';
import cplus_icon from '../../../assets/capeark_img/home/TechIcons/c-.png';
import bootstrap_icon from '../../../assets/capeark_img/home/TechIcons/bootstrap.svg';
import c_sharp_icon from '../../../assets/capeark_img/home/TechIcons/c-sharp.png';
import c_icon from '../../../assets/capeark_img/home/TechIcons/c.png';
import css_3_icon from '../../../assets/capeark_img/home/TechIcons/css-3.png';
import html_5_icon from '../../../assets/capeark_img/home/TechIcons/html-5.png';
import js_icon from '../../../assets/capeark_img/home/TechIcons/js.png';
import mongodb_icon from '../../../assets/capeark_img/home/TechIcons/mongodb.png';
import node_js_icon from '../../../assets/capeark_img/home/TechIcons/node-js.png';
import php_icon from '../../../assets/capeark_img/home/TechIcons/php.png';
import postgre_icon from '../../../assets/capeark_img/home/TechIcons/postgre.png';
import postman_icon from '../../../assets/capeark_img/home/TechIcons/postman-icon.png';
import python_icon from '../../../assets/capeark_img/home/TechIcons/python.png';
import react_icon from '../../../assets/capeark_img/home/TechIcons/react.png';
import redux_icon from '../../../assets/capeark_img/home/TechIcons/redux.png';
import typescript_icon from '../../../assets/capeark_img/home/TechIcons/typescript.png';
import Vue_icon from '../../../assets/capeark_img/home/TechIcons/Vue.png';
import flutter_icon from '../../../assets/capeark_img/home/TechIcons/flutter-icon.png';
import react_native_icon from '../../../assets/capeark_img/home/TechIcons/react_native.png';
const responsiveSettings = [
    {
        breakpoint: 1400,
        settings: {
            slidesToShow: 6,
            slidesToScroll: 6,
        },
    },
    {
        breakpoint: 1100,
        settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
        },
    },
    {
        breakpoint: 800,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
        },
    },
    {
        breakpoint: 500,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
        },
    },
    {
        breakpoint: 400,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        },
    },
];
function HomeFooterContent() {


    const images = [
        angular_icon,
        cplus_icon,
        bootstrap_icon,
        c_sharp_icon,
        c_icon,
        css_3_icon,
        html_5_icon,
        js_icon,
        mongodb_icon,
        node_js_icon,
        php_icon,
        postgre_icon,
        postman_icon,
        python_icon,
        react_icon,
        redux_icon,
        typescript_icon,
        Vue_icon,
        flutter_icon,
        react_native_icon
    ];

    return (
        <section>
            <div className='container pt-5 pb-5 home_footer_owl_slider'>
                <Slide
                    slidesToScroll={4}
                    slidesToShow={1}
                    indicators={false}
                    autoplay={true}
                    arrows={false}
                    duration={5000}
                    className="active"
                    responsive={responsiveSettings}
                >
                    <div className="home_footer_slider_item">
                        <div style={{ 'backgroundImage': `url(${images[0]})` }} className='home_footer_slider_cover'></div>
                    </div>
                    <div className="home_footer_slider_item">
                        <div style={{ 'backgroundImage': `url(${images[1]})` }} className='home_footer_slider_cover'></div>
                    </div>
                    <div className="home_footer_slider_item">
                        <div style={{ 'backgroundImage': `url(${images[2]})` }} className='home_footer_slider_cover'></div>
                    </div>
                    <div className="home_footer_slider_item">
                        <div style={{ 'backgroundImage': `url(${images[3]})` }} className='home_footer_slider_cover'></div>
                    </div>
                    <div className="home_footer_slider_item">
                        <div style={{ 'backgroundImage': `url(${images[4]})` }} className='home_footer_slider_cover'></div>
                    </div>
                    <div className="home_footer_slider_item">
                        <div style={{ 'backgroundImage': `url(${images[5]})` }} className='home_footer_slider_cover'></div>
                    </div>
                    <div className="home_footer_slider_item">
                        <div style={{ 'backgroundImage': `url(${images[6]})` }} className='home_footer_slider_cover'></div>
                    </div>
                    <div className="home_footer_slider_item">
                        <div style={{ 'backgroundImage': `url(${images[7]})` }} className='home_footer_slider_cover'></div>
                    </div>

                    <div className="home_footer_slider_item">
                        <div style={{ 'backgroundImage': `url(${images[8]})` }} className='home_footer_slider_cover'></div>
                    </div>
                    <div className="home_footer_slider_item">
                        <div style={{ 'backgroundImage': `url(${images[9]})` }} className='home_footer_slider_cover'></div>
                    </div>
                    <div className="home_footer_slider_item">
                        <div style={{ 'backgroundImage': `url(${images[10]})` }} className='home_footer_slider_cover'></div>
                    </div>
                    <div className="home_footer_slider_item">
                        <div style={{ 'backgroundImage': `url(${images[11]})` }} className='home_footer_slider_cover'></div>
                    </div>
                    <div className="home_footer_slider_item">
                        <div style={{ 'backgroundImage': `url(${images[12]})` }} className='home_footer_slider_cover'></div>
                    </div>
                    <div className="home_footer_slider_item">
                        <div style={{ 'backgroundImage': `url(${images[13]})` }} className='home_footer_slider_cover'></div>
                    </div>
                    <div className="home_footer_slider_item">
                        <div style={{ 'backgroundImage': `url(${images[14]})` }} className='home_footer_slider_cover'></div>
                    </div>
                    <div className="home_footer_slider_item">
                        <div style={{ 'backgroundImage': `url(${images[15]})` }} className='home_footer_slider_cover'></div>
                    </div>
                    <div className="home_footer_slider_item">
                        <div style={{ 'backgroundImage': `url(${images[16]})` }} className='home_footer_slider_cover'></div>
                    </div>
                    <div className="home_footer_slider_item">
                        <div style={{ 'backgroundImage': `url(${images[17]})` }} className='home_footer_slider_cover'></div>
                    </div>
                    <div className="home_footer_slider_item">
                        <div style={{ 'backgroundImage': `url(${images[18]})` }} className='home_footer_slider_cover'></div>
                    </div>
                    <div className="home_footer_slider_item">
                        <div style={{ 'backgroundImage': `url(${images[19]})` }} className='home_footer_slider_cover'></div>
                    </div>
                </Slide>
            </div>
            <div className="home_footer_get-started-section">
                <div className="container pb-2">
                    <div className="row">
                        <div className="col-12 col-md-8 m-auto text-center">
                            <h6 className="text-muted">Let’s get started</h6>
                            <div className="w-100 mb-4"><div className="home_footer_get_started_line"> </div></div>
                            <h3 >Are you ready for a better, more productive business?</h3>
                            <p className="mb-4">Stop worrying about technology problems. Focus on your business.
                                Allow us to offer the assistance you deserve.</p>
                            <Link to="/contact" className="text-white home_footer_get_started_btn">
                                <span class="home_footer_dot-line-left"></span>
                                <span class="home_footer_text">Contact Us</span>
                                <span class="home_footer_block-right"></span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="home_footer_bg_cont_main">
                <img src={content_bottom_img} alt="content_bottom_img" className="home-footer-content-img-fluid" />
            </div>
        </section>
    );
}

export default HomeFooterContent;
