import React from 'react'
import "./index.css";
import userExperience from '../../../../assets/capeark_img/services/icons/icons_USER EXPERIENCE.svg'
import brand from '../../../../assets/capeark_img/services/icons/icons_BRAND.svg'
import DigitalGrowth from '../../../../assets/capeark_img/services/icons/icons_DIGITAL GROWTH.svg'

function AuditStrategy() {
    return (
        <React.Fragment>
            <div className='container'>
                <div className='audit_strategy_header_main'>
                    <h2>HOW CAN AN AUDIT INFORM YOUR STRATEGY</h2>
                </div>
                <div className='row pb-2 pt-2'>
                    <div className='col-sm-12 col-md-6 col-lg-4'>
                        <div className='audit_strategy_card_main'>
                            <div className='mt-2'>
                                <img src={userExperience} alt='' />
                            </div>
                            <div>
                                <h4>USER EXPERIENCE</h4>
                                <h2>Uncover new opportunities</h2>
                                <p>Add value to your site and build deeper connections with your audience.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-4'>
                        <div className='audit_strategy_card_main'>
                            <div className='mt-2'>
                            <img src={brand} alt='' />
                            </div>
                            <div>
                                <h4>BRAND</h4>
                                <h2>Identify Potential Issues</h2>
                                <p>Proactively address risks around security, performance, UX, and accessibility.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-4'>
                        <div className='audit_strategy_card_main'>
                            <div className='mt-2'>
                            <img src={DigitalGrowth} alt='' />
                            </div>
                            <div>
                                <h4>DIGITAL GROWTH</h4>
                                <h2>Find Your Path Forward</h2>
                                <p>Get meaningful insights that drive the evolution of your platform.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AuditStrategy