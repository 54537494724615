import { POST, PUT, DELETE, GET } from "../../constants/methods";
import callApi from "../../callApi";


export const getAllContact = async () => {
  return await callApi("/api/contact/list",{}, GET);
};
export const getContactById = async (id) => {
  return await callApi(`/api/contact/get/${id}`, GET);
};
export const createNewContact = async (data) => {
  return await callApi("/api/contact/create", data, POST);
};
export const updateContactById = async (id, data) => {
  return await callApi(`/api/contact/update/${id}/`, data, PUT);
};
export const deleteContactById = async (id) => {
  return await callApi(`/api/contact/delete/${id}/`, {}, DELETE);
};