import React, { useState } from "react";
import "./css/Contact.css";

const ContactBanner = () => {
  const [tabValue, setTabValue] = useState("0");

  return (
    <React.Fragment>
      <div className="contact_banner_main_cont">
        <div className="container mt-4 mb-5">
          <div className="row mt-5">
            <div className="col-sm-12 col-md-12 col-lg-12 contact_heading_banner_text_cont">
              <h1 className="text-white text-center">Contact</h1>
              <p className="text-white text-center">
                Interested in our IT services or need advice? Then please get in
                touch and we’ll be glad to help.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="project-banner-clip-one"></div>
    </React.Fragment>
  );
};

export default ContactBanner;
