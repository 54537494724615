import React from 'react';
import "./AllProjects.css";
import "./Check.css";
import { HigherEduData } from '../../../helpers/projectsData';
import { Link } from 'react-router-dom';
import $ from "jquery";
function HigherEducation() {
    function isElementInViewport(el) {
        var rect = el.getBoundingClientRect();
        return (
            rect.top >= -100 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    function callbackFunc() {
        var items = $(".project_timeline li");
        for (var i = 0; i < items.length; i++) {
            if (isElementInViewport(items[i])) {
                if (!items[i].classList.contains("project-in-view")) {
                    items[i].classList.add("project-in-view");
                }
            } else if (items[i].classList.contains("project-in-view")) {
                items[i].classList.remove("project-in-view");
            }
        }
    }

    // window.addEventListener("load", callbackFunc);
    // window.addEventListener("scroll", callbackFunc);


    $(document).on("load", callbackFunc);
    $(document).on("scroll", callbackFunc);



    return (
        <React.Fragment>
            <div className='container' >
                <div className='row'>
                    <div className='col-sm-12 col-md-12 col-lg-12 project_timeline p-0'>
                        <ul className='p-0'>
                            {HigherEduData?.map((item) => (
                                <>
                                    {item?.img_type === "full" ? (
                                        <>
                                            <div className='project_single_effect_main_cont p-0 mb-2 mt-2' key={item?.id}>
                                                <img src={item?.img} alt={item?.project_name} className='project_effect_image' />
                                                <Link to="/" className="project_single_effect_main_overlay" style={{ 'background': `${item?.color}` }}>
                                                    <div className="project_all_detail_cont_main">
                                                        <div>
                                                            <h3 className='project_all_desc_text mb-4 text-white'>{item?.description}</h3>
                                                            <h2 className='project_all_name_text text-white'>{item?.project_name}</h2>
                                                        </div>
                                                        <div>
                                                            <h5 className='text-white'>{item?.categories}</h5>
                                                        </div>
                                                    </div>
                                                </Link>

                                            </div>
                                            <div className="project_all_mob_detail_cont_main p-0 mb-2 mt-2">
                                                <h3 className='project_all_mob_desc_text mb-4'>{item?.description}</h3>
                                                <h2 className='project_all_mob_name_text'>{item?.project_name}</h2>
                                            </div>
                                        </>
                                    ) : null}
                                    {item?.img_type === "short" ? (
                                        <li key={item?.id}>
                                            <div className='project_double_effect_main_cont p-0'>
                                                <img src={item?.img} alt={item?.project_name} className='project_double_effect_image' />
                                                <Link to="/" className="project_single_effect_main_overlay" style={{ 'background': `${item?.color}` }}>
                                                    <div className="project_all_single_detail_cont_main">
                                                        <div>
                                                            <h3 className='project_all_single_desc_text mb-4 text-white'>{item?.description}</h3>
                                                            <h2 className='project_all_single_name_text text-white'>{item?.project_name}</h2>
                                                        </div>
                                                        <div>
                                                            <h5 className='text-white'>{item?.categories}</h5>
                                                        </div>
                                                    </div>
                                                </Link>

                                            </div>
                                            <div className="project_all_single_mob_detail_cont_main p-0 mb-2 mt-2">
                                                <h3 className='project_all_single_mob_desc_text mb-4'>{item?.description}</h3>
                                                <h2 className='project_all_mob_single_name_text'>{item?.project_name}</h2>
                                            </div>
                                        </li>
                                    ) : null}
                                </>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default HigherEducation;