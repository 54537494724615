import React from 'react'
import "../HigherEducation.css";
import iconsmobile from '../../../../assets/capeark_img/services/icons/icons_mobile.svg'
import iconsblub from '../../../../assets/capeark_img/services/icons/icons_bulb.svg'
import iconswebsite from '../../../../assets/capeark_img/services/icons/icons_GOVERNANCE.svg'


function HigherEducationIntro() {
    return (
        <React.Fragment>
            <div>
                <div className="container pb-5 pt-5">
                    <div className="row pb-4 pt-4">
                        <div className="col-sm-12 col-md-4 col-lg-4 ">
                            <div className="industry_higher_edu_design_desc_card_main">
                                <div>
                                    <img src={iconsmobile} alt='' />
                                </div>
                                <p>
                                    To ensure an optimized search experience, we work to understand the content prioritization, create search filters adapted to the habits of your visitors and customize the results ordering and show the most relevant content first.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 ">
                            <div className="industry_higher_edu_design_desc_card_main">
                                <div>
                                    <img src={iconsblub} alt='' />
                                </div>
                                <p>
                                    By focusing on audience needs, we’ll work with your content team to help structure new content and streamline the process of adding this content to your CMS.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4 ">
                            <div className="industry_higher_edu_design_desc_card_main">
                                <div>
                                    <img src={iconswebsite} alt='' />
                                </div>
                                <p>
                                    To make the website accessible to as broad an audience as possible, we address accessibility during design, development, and QA. Our websites are built inline with the latest WCAG AA guidelines and AODA standards.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6 ">
                            <div className="industry_higher_edu_design_desc_card_main_second">
                                <h3>All-in-one Expertise</h3>
                                <p>
                                    We understand the unique challenges of large and small institutions and we thrive to give you the tools and guidance to own and grow your digital presence.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 ">
                            <div className="industry_higher_edu_design_desc_card_main_second">
                                <h3>Discovery and Strategy</h3>
                                <p>
                                    We'll assess your needs in-depth, help you map out audience journeys, and ensure we fully understand your vision. We'll also work with you to understand the risks and benefits of different approaches before making any decisions. We will orient our strategy toward making sure your website converts more prospective students into applicants.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 ">
                            <div className="industry_higher_edu_design_desc_card_main_second">
                                <h3>Design and Development</h3>
                                <p>
                                Our team will design, prototype, and build a user-focused platform that empowers your teams to deliver delightful, consistent, accessible content. This is especially beneficial for students because they will be able to find the information they need quickly and easily. This removes any possible barriers to entry that might exist on your current website.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 ">
                            <div className="industry_higher_edu_design_desc_card_main_second">
                                <h3>Post-launch Support and Optimization</h3>
                                <p>
                                We are your partners throughout this journey. We provide training and support to help you get the most out of your new digital platform. Our team can provide on-demand support, help you troubleshoot issues, and optimize your site's performance. This includes ensuring your website is mobile-friendly for a great user experience regardless of what device people are accessing your website from.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 ">
                        <div className="industry_higher_edu_design_desc_card_main_second">
                                <h3>Why Choose Us?</h3>
                                <p>
                                Capeark has 15 years of experience helping higher education institutions connect and empower students and communities. This experience gives us a deep understanding of the needs of post-secondary institutions and the systems they rely on. We have a long track record of success with higher education clients, and our team has a wealth of experience with the technologies and systems used by post-secondary institutions.
                                </p>
                            </div>
                        </div>
                        </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default HigherEducationIntro;