import React from "react";
import "./index.css";

function WebDesignDesc() {
  return (
    <React.Fragment>
      {" "}
      <div>
        <div className="container pb-5 pt-5">
          <div className="service_design_desc_header_main">
            <h2>GET INSPIRED WITH DESIGNS THAT ARE:</h2>
          </div>
          <div className="row pb-4 pt-4">
            <div className="col-sm-12 col-md-4 col-lg-4 ">
              <div className="service_design_desc_card_main">
                <h2>User-centric</h2>
                <p>
                  Developing your designs based on user insights to build a
                  brand presence that is accessible, tells a story and grasps
                  all aspects of your user journey.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 ">
              <div className="service_design_desc_card_main">
                <h2>Collaborative</h2>
                <p>
                  Working closely with you to grasp the needs of your
                  organization, stakeholders and markets resulting in a smooth
                  post-launch transition that your team can uptake.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 ">
              <div className="service_design_desc_card_main">
                <h2>Impactful</h2>
                <p>
                  Building a brand presence that tells a story and strongly
                  resonates with all your audiences with flexible, responsive
                  and ready-to-implement design features.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="service_design_desc_header_main">
              <h2>USABILITY TESTING</h2>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 ">
              <div className="service_design_desc_card_main">
                <p>
                  We run usability testing strategically, to validate our
                  assumptions about how users will interact with a new design.
                  It's surprising how much insight you can get from even a small
                  test focused on your most important user journeys. Based on
                  the feedback collected in usability testing, we iterate and
                  refine the content, visual designs, or user experience
                  patterns accordingly.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 ">
              <div className="service_design_desc_card_main">
                <p>
                  By creating a recruitment guide to identify which test
                  subjects to select and how to recruit them, we ensure that we
                  are inclusive in our testing process. We also build testing
                  scripts in advance so that testing is as consistent as
                  possible. Finally, we produce reports and recommendations
                  summarizing the test results.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default WebDesignDesc;
