import React from 'react'
import './css/Careers.css'
import CarrersIcon from './CarrersIcon'
import CurrentOpenings from './CurrentOpenings'
import { Helmet } from 'react-helmet';

function Careers() {
    return (
        <React.Fragment>
            <Helmet>
                <meta charset="utf-8" />
                <title>
                    Capeark | Careers
                </title>
                <meta charset="utf-&" />
                <meta name="description" content="Capeark Careers, Build a future you believe in." />
                <meta name="keywords" content="Web development, App development, Mern stack, Mean stack, Audit Services, Strategy, Digital Products & Experience Designs, Ongoing Support" />
                <meta name="author" content="Capeark" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div className="careers_header_main_cont">
                <div className="container mt-4 mb-5">
                    <div className="row mt-3 ">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div class="careers-border-bottom">
                                <h1 className="careers-page-header-title-text text-white">
                                    CAREERS
                                </h1>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                            <p className="careers-page-header-subtitle-text text-white">
                                Build a future you believe in
                            </p>
                        </div>
                        {/* <div className="col-sm-12 col-md-12 col-lg-12 mb-5">
                            <Link to={'/apply-now'} className="text-white carrer_apply_link ">
                                <p>Apply now</p>
                                <div class="circle">
                                    <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="#ffffff"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path d="M5 12h14M12 5l7 7-7 7" />
                                    </svg>
                                </div>
                            </Link>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="project-banner-clip-one"></div>
            <div className="about-contanet-section mt-5 mb-5">
                <div className="container pb-2">
                    <div className="row about_section_text_cont">
                        <div className="col-12 col-md-8 m-auto text-center">
                            <CarrersIcon />
                            <h1 className="text-muted mt-3">Nice to Meet You</h1>
                            <div className="w-100 mb-4"><div className="home_footer_get_started_line"> </div></div>
                            <p className="mb-4 fs-6">There are numerous IT companies in world, but none can compare to Capeark's unique approach. We have good approach to developing business growth, we have been working with client and helping them to grow their business.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="about-contanet-section">
                <div className="container pb-2">
                    <div className="row about_section_text_cont">
                        <div className="col-12 col-md-8 m-auto text-center">
                            <h1 className="text-muted">Current Openings</h1>
                            <div className="w-100 mb-4"><div className="home_footer_get_started_line"> </div></div>
                            <p className="mb-4 fs-6">Feel fulfilled. Have fun. Help us to shape the future.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <CurrentOpenings />
            </div>
        </React.Fragment>
    )
}

export default Careers
