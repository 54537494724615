import axios from "axios";
import { toast } from "react-toastify";
const API_URL = "https://caperark-services.onrender.com/";
// const API_URL = "http://localhost:7000/";

const axiosApi = axios.create({
  baseURL: API_URL,
});
function listner() {
  return localStorage.getItem("token");
}
export default async function callApi(
  url,
  data = {},
  method = "GET",
  config = {}
) {
  let newURl = url;
  if (url?.startsWith("/")) {
    newURl = url?.replace("/", "");
  }

  return await axiosApi({
    method: method,
    url: newURl,
    data,
    // headers: {
    //   Authorization: "Bearer " + listner(),
    //   // ...config,
    // },
  })
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        // if (res?.data?.message?.length > 0) toast.success(res?.data?.message);
      }
      return { data: res.data, status: res.status };
    })
    .catch(error => {
      // console.log(error, "error")
      if (error.response.status === 400) {
        // toast.error(error.response?.data.message);
      } else if (error.response.status === 500) {
        // toast.error(error.response?.data.message);
      } else if (error.response.status === 403) {
        // localStorage.clear();
        // window.location.href = '/login';
        // toast.error(error.response?.data.message);
      } else if (error.response.status === 401) {
        // localStorage.clear();
        // window.location.href = '/login';
        // toast.error(error.response?.data.message);
      } else if (error) {
        // toast.error(error.message);
      }
      if (error.response) {
        if (error?.response.status === 0) {
          // toast.error("Please Check your Internet");
          return;
        }
        //toast.error(error.response?.data.message);
        // toast.error("Invalid Credentials")
        return { status: error?.response.status, data: error?.response?.data };
      } else if (error.request) {
      } else {
        // toast.error(error.response?.data.message);
      }
    });
}
