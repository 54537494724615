import React from 'react'
import websiteforgovt from '../../../../assets/capeark_img/industry/website-for-govt.jpg'

function GovernmentBanner() {
    return (
        <React.Fragment>
            <div className="service_banner_item_cont_main">
                <div className="service_banner_item_bg_cont">
                    <div className="row service_banner_item_content_cont pt-5 pb-5 ">
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_hover_cont service_banner_column">
                            <figure className="p-0 m-0 service_banner_img_cont">
                                <img
                                    src={websiteforgovt}
                                    alt=""
                                />
                            </figure>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_desc_cont">
                            <div className="service_banner_desc_media__body">
                                <h2 className="service_banner_desc_media__title mb-4">
                                Website Development for Government Agencies
                                </h2>
                                <div className="service_banner_desc_media__text">
                                    <p>While organizations in the private sector can target their audience, optimizing the website experience for the most profitable customer, government websites need to ensure all visitors are able to find the information they’re looking for. The  websites should focus on inclusion, accessibility and search. The focus should be around task completion, visitor satisfaction and engagement. <br/> Capeark works with government agencies to focus on:</p>
                                    <ul className="service_banner_desc_list ">
                                        <li>User-centric architecture - organizing  content based on user needs and creating customized pathways for each audience</li>
                                        <li>Search-centric - search experience with clear ways to help visitors quickly find the content they’re searching for</li>
                                        <li>Accessibility - WCAG AA and AAA compliant websites need to ensure users of all backgrounds and abilities have access to the content</li>
                                        <li>Multilingual - Fully multilingual content and interface in each official language</li>
                                        <li>Inclusive design - a website that is representative of and speaks to all citizens</li>
                                        <li>Prioritization of security and privacy</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default GovernmentBanner