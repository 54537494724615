import React from 'react';
import "./index.css";
import { Link } from 'react-router-dom';
import ReadmoreLink from '../../Helpers/ReadmoreLink';
import Pagination from '@mui/material/Pagination';


function BlogsCard({ setCurrentPage, blogsData, page, getBlogData, pages }) {

    const searchUrl = window.location.search;

    const handlePageChange = (event, value) => {
        getBlogData(value, searchUrl?.split("=")[1])
        setCurrentPage(value);
    }


    return (
        <React.Fragment>
            <div>
                <div className='container mt-4 mb-4'>
                    <div className='row'>
                        {blogsData?.map((item, index) => (
                            <div className='col-sm-12 col-md-4 col-lg-4 p-4' key={index}>
                                <div className='blog_card_main'>
                                    <Link to={`/blog/${item?.id}`}>
                                        <div class="blog_card_top_img_wrapper">
                                            <img src={item?.poster_url} alt="" />
                                        </div>
                                        <div class="blog_card_content_wrapper_main pl-3 pr-3 pb-2">
                                            <div className='blog_card_content_top_details'>
                                                <span> {item?.date} </span>&#x2022;<span> {item?.duration} </span>
                                            </div>
                                            <h3>{item?.headline}</h3>
                                            <p>{item?.description}</p>

                                            <div className='blog_card_content_chip_cont'>
                                                {item?.tech_stack && (
                                                    <>
                                                        {item?.tech_stack.map((el, i) => (
                                                            <span key={i}>
                                                                {el?.name}
                                                            </span>
                                                        ))}
                                                    </>
                                                )}
                                            </div>

                                            <div className='blog_card_content_readmore_cont'> <ReadmoreLink link={`/blog/${item?.id}`} linkText="Read more" style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                color: "black",
                                                gap: "5px",
                                                fontWeight: '600',
                                                fontSize: '13px'
                                            }} /></div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='row mt-5 mb-5'>
                        <div className='col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center'>
                            <Pagination
                                count={pages}
                                variant="outlined"
                                color="primary"
                                page={page}
                                onChange={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default BlogsCard;