import React from "react";
import webdesignbanner from '../../../../assets/capeark_img/services/webdesignbanner.jpg'

function WebDesignBanner() {
    return (
        <React.Fragment>
            {/* Css is used in one place (in main service folder) */}
            <div className="service_banner_item_cont_main_right">
                <div className="service_banner_item_bg_cont_right capeark-bg">
                    <div className="row right_banner_flex service_banner_item_content_cont_right pt-5 pb-5 g-0 ">
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_desc_cont_right">
                            <div className="service_banner_desc_media__body">
                                <h2 className="service_banner_desc_media__title mb-4 text-white">
                                    Realize Your Vision
                                </h2>
                                <div className="service_banner_desc_media__text text-white">
                                    {/* <h2>Design for Delight</h2> */}
                                    <p>
                                        Engage your website users by creating and offering an intuitive visual experience. We'll take your brand guidelines and build them out in a fresh and consistent way, or help you build a new brand identity from the ground up.
                                    </p>
                                    <p className="mt-4">
                                        Capeark can help you create:
                                    </p>
                                    <ul className="service_banner_desc_list text-white">
                                        <li> Brand identity</li>
                                        <li>Style guides</li>
                                        <li>UI kit</li>
                                        <li>High-fidelity mockups</li>
                                        <li>Visual prototyping</li>
                                        <li>Visual design testing</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_hover_cont service_banner_column_right">
                            <figure className="p-0 m-0 service_banner_img_cont">
                                <img
                                    src={webdesignbanner}
                                    alt=""
                                />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default WebDesignBanner;
