import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import ServicesBanner from '../../../../assets/capeark_img/services/devServicesbanner.jpg'
import ServicesBanner2 from '../../../../assets/capeark_img/services/devServicesbanner.jpg'

function DevServicesBanner() {
    return (
        <React.Fragment>
            {/* Css is used in one place (in main service folder) */}
            <div className="service_banner_item_cont_main">
                <div className="service_banner_item_bg_cont">
                    <div className="row service_banner_item_content_cont pt-5 pb-5 ">
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_hover_cont service_banner_column">
                            <figure className="p-0 m-0 service_banner_img_cont">
                                <img
                                    src={ServicesBanner}
                                    alt=""
                                />
                            </figure>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_desc_cont">
                            <div className="service_banner_desc_media__body">
                                <h2 className="service_banner_desc_media__title mb-4">
                                    Our Services
                                </h2>
                                <div className="service_banner_desc_media__text">
                                    <p>We offer a range of services including: </p>
                                    <ul className="service_banner_desc_list ">
                                        <li>Website, applications, and platform architecture</li>
                                        <li>Content migration and synchronization</li>
                                        <li>API design and integration with third-party services</li>
                                        <li>Multilingual content management</li>
                                        <li>Advanced search features</li>
                                        <li>Technical, accessibility, and security audits</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service_banner_item_cont_main_right">
                <div className="service_banner_item_bg_cont_right capeark-bg">
                    <div className="row right_banner_flex service_banner_item_content_cont_right pt-5 pb-5 g-0 ">
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_desc_cont_right">
                            <div className="service_banner_desc_media__body">
                                <h2 className="service_banner_desc_media__title mb-4 text-white">
                                    Our Technologies Stack
                                </h2>
                                <div className="service_banner_desc_media__text text-white">
                                    {/* <h2>Design for Delight</h2> */}
                                    <p>
                                        We adapt our technology stack to your organization’s preferences and capacity as well as the specific needs of the project. We typically use the following tools and technologies:
                                    </p>
                                    <ul className="service_banner_desc_list text-white">
                                        <li>WordPress, a popular open source content publishing platform</li>
                                        <li>React, Angular, Vue, Gatsby, and Next to create a JS-based decoupled front-end</li>
                                        <li>Bootstrap and Tailwind, front-end frameworks for rapid development </li>
                                        <li>React Native, a JS framework for app development</li>
                                        <li>Storybook, Material, Foundation to document design systems</li>
                                        <li>Apache Solr and Elasticsearch to create an enterprise search experience</li>
                                    </ul>
                                    <p>
                                        Learn more about how we use a flexible technology stack for <Link to="" className="dev_tech_banner_project_link hover-underline-animation">our various projects</Link>.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_hover_cont service_banner_column_right">
                            <figure className="p-0 m-0 service_banner_img_cont">
                                <img
                                    src={ServicesBanner2}
                                    alt=""
                                />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default DevServicesBanner;
