import React from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom'
import AboutMessage from './AboutMessage'
import './css/About.css'

function About() {
    return (
        <React.Fragment>
            <Helmet>
                <meta charset="utf-8" />
                <title>
                    Capeark | About
                </title>
                <meta charset="utf-&" />
                <meta name="description" content="Meet Capeark Intelligence, Capeark is privately owned IT Support and IT Services Company." />
                <meta name="keywords" content="Web development, App development, Mern stack, Mean stack, Audit Services, Strategy, Digital Products & Experience Designs, Ongoing Support" />
                <meta name="author" content="Capeark" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <div className="about_header_main_cont">
                <div className="container mt-4 mb-5">
                    <div className="row mt-5">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <h6 className="about-page-header-title-text text-white">
                                Meet Capeark Intelligence
                            </h6>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                            <h1 className="about-page-header-subtitle-text text-white">
                                Propelling meaningful stories forward
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="project-banner-clip-one"></div>
            <div className='container'>
                <div className="">
                    <div className="container">
                        <div className="row pt-5 pb-5">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <p className="about_intro_text">
                                    Capeark is privately owned IT Support and IT Services Company.
                                    With a customer-centric approach at the core of our operations, we strive to provide top-notch IT solutions tailored to your specific requirements.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <AboutMessage />
            </div>
            <div className="about_upper_footer">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12  about_upper_footer_content_main">
                            <h1 className="fs-1 text-white text-center mt-5">
                                Facing a challenge?
                            </h1>

                            <p className=" fs-0 text-white">
                                Ready to take your business to the next level? Get a personalized quote tailored to your specific needs and goals.
                            </p>
                            <Link className="about_lets_contact_btn" to="/contact">Let's Work Together</Link>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default About
