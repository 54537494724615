import React from 'react';
import './index.css';

function MaintenanceIntro() {
    return (
        <React.Fragment>
            <div className="container pb-5 pt-5">
                <div>
                    <h2 className='maintanance_info_card_header_text mb-4'>EXTEND THE LIFE OF YOUR SITE</h2>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-4 p-2">
                        <div className="maintanance_info_card_main">
                            <div className='mb-4'>
                                <h4>Maintain to Stabilize</h4>
                            </div>
                            <div className="maintanance_info_desc_media__text">
                                <ul className="maintanance_info_desc_list ">
                                    <li>Security audits and updates at the application level</li>
                                    <li>Accessibility monitoring and remediation</li>
                                    <li>Performance testing to improve page-load times</li>
                                    <li>Analytics to evaluate your content efforts</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 p-2">
                        <div className="maintanance_info_card_main">
                            <div className='mb-4'>
                                <h4>Optimize to Improve</h4>
                            </div>
                            <div className="maintanance_info_desc_media__text">
                                <ul className="maintanance_info_desc_list ">
                                    <li>New features such as search interfaces, content personalization, page-building tools, CRM</li>
                                    <li>New apps and microsite that integrate with your main website</li>
                                    <li>Multilingual digital experiences</li>
                                    <li>SEO and SEM optimization</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4 p-2">
                        <div className="maintanance_info_card_main">
                            <div className='mb-4'>
                                <h4>Train to Evolve</h4>
                            </div>
                            <div className="maintanance_info_desc_media__text">
                                <ul className="maintanance_info_desc_list ">
                                    <li>Custom training programs and courses adapted to your specific requirement</li>
                                    <li>Single or multiple remote courses for punctual needs</li>
                                    <li>Full training tracks for a holistic approach to Drupal based on your site's users</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MaintenanceIntro;