import React from 'react';
import './index.css';
import discovery from '../../../../assets/capeark_img/services/icons/icons_DISCOVERY.svg'
import strategy from '../../../../assets/capeark_img/services/icons/icons_STRATEGY.svg'
import governnce from '../../../../assets/capeark_img/services/icons/icons_GOVERNANCE.svg'

function SeoIntro() {
    return (
        <React.Fragment>
            <div className='container mt-4 mb-5'>
                <div className='seo_strategy_header_main mb-5'>
                    <h2>CREATING STORIES THAT RESONATE WITH YOUR AUDIENCE</h2>
                    <p>For a marketing strategy to be successful, it needs the support of a good content strategy. Marketing strategy identifies the destination. Content strategy articulates how to get there.</p>
                </div>
                <div className='row pb-2 pt-2'>
                    <div className='col-sm-12 col-md-6 col-lg-4'>
                        <div className='seo_strategy_card_main'>
                            <div className='mt-2'>
                            <img src={discovery} alt='' />
                            </div>
                            <div>
                                <h4 className='mt-3 mb-3'>DISCOVERY</h4>
                                <h2>Identify your key audiences</h2>
                                <p>
                                    Our content experts will help you identify your audiences’ paint points, habits and desires.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-4'>
                        <div className='seo_strategy_card_main'>
                            <div className='mt-2'>
                            <img src={strategy} alt='' />
                            </div>
                            <div>
                                <h4 className='mt-3 mb-3'>STRATEGY</h4>
                                <h2>Meet your audience’s needs</h2>
                                <p>
                                    We will help you refine your communication style to meet your audience’s needs.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-4'>
                        <div className='seo_strategy_card_main'>
                            <div className='mt-2'>
                            <img src={governnce} alt='' />
                            </div>
                            <div>
                                <h4 className='mt-3 mb-3'>GOVERNANCE</h4>
                                <h2>Keep your website up to date</h2>
                                <p>
                                    We will work to develop and execute a content management plan your team can maintain.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SeoIntro;