import React from "react";
import "./IndustryIntro.css";
import "./index.css";
import home_banking from "../../../assets/capeark_img/home/home-banking.svg";
import home_capitalmarket from "../../../assets/capeark_img/home/home-capitalmarket.svg";
import home_product_development from "../../../assets/capeark_img/home/home-product-development.svg";
import home_manufacturing from "../../../assets/capeark_img/home/home-manufacturing.svg";
import home_higereducation from "../../../assets/capeark_img/home/home-higereducation.svg";

function IndustryIntro() {
  return (
    <section>
      <div className="home-banner-clip-three"></div>
      <div className="home_industry_intro_main capeark-bg pb-5">
        <div className="container">
          <section>
            <div className="home_industry_intro_section_header col-md-10 ml-auto mr-auto">
              <h2 className="text-white text-center home_industry_intro_heading">INDUSTRIES WE SERVE</h2>
              <h4 className="text-white text-center">Managed IT services customized for your industry</h4>
              <p>
                Our vertical solutions expertise allows your business to
                streamline workflow, and increase productivity. No matter the
                business, NanoSoft has you covered with industry compliant
                solutions, customized to your company’s specific needs.
              </p>
            </div>
            <div className="row">
              <div className="col-md-4 col-lg mb-4 text-center">
                <div className="home_industry_work-porcess-box d-flex home_justify-content-center rounded home_industry_hovicon home_industry_effect_1">
                  <img
                    src={home_banking}
                    alt="Banking"
                    className="img-fluid align-self-center wow swing animated home_industry_intro_logo"
                  />
                </div>
                <h6 className="text-center text-white mt-3">Banking</h6>
              </div>
              <div className="col-md-4 col-lg mb-4">
                <div className="home_industry_work-porcess-box d-flex home_justify-content-center rounded home_industry_hovicon home_industry_effect_1">
                  <img
                    src={home_capitalmarket}
                    alt="Capital markets"
                    className="img-fluid align-self-center wow swing animated home_industry_intro_logo"
                  />
                </div>
                <h6 className="text-center text-white mt-3">Capital markets</h6>
              </div>
              <div className="col-md-4 col-lg mb-4">
                <div className="home_industry_work-porcess-box d-flex home_justify-content-center rounded home_industry_hovicon home_industry_effect_1">
                  <img
                    src={home_product_development}
                    alt="Product Development"
                    className="img-fluid align-self-center wow swing animated home_industry_intro_logo"
                  />
                </div>
                <h6 className="text-center text-white mt-3">Product Development</h6>
              </div>
              <div className="col-md-4 col-lg mb-4">
                <div className="home_industry_work-porcess-box d-flex home_justify-content-center rounded home_industry_hovicon home_industry_effect_1">
                  <img
                    src={home_manufacturing}
                    alt="Manufacturing"
                    className="img-fluid align-self-center wow swing animated home_industry_intro_logo"
                  />
                </div>
                <h6 className="text-center text-white mt-3">Manufacturing</h6>
              </div>
              <div className="col-md-4 col-lg mb-4">
                <div className="home_industry_work-porcess-box d-flex home_justify-content-center rounded home_industry_hovicon home_industry_effect_1">
                  <img
                    src={home_higereducation}
                    alt="Higher education"
                    className="img-fluid align-self-center wow swing animated home_industry_intro_logo"
                  />
                </div>
                <h6 className="text-center text-white mt-3">Higher education</h6>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
}

export default IndustryIntro;
