import React from 'react'
import healthCareBanner from '../../../../assets/capeark_img/industry/HealthCareBanner.jpg'
function HealthCareBanner() {
    return (
        <React.Fragment>
            <div className="service_banner_item_cont_main">
                <div className="service_banner_item_bg_cont">
                    <div className="row service_banner_item_content_cont pt-5 pb-5 ">
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_hover_cont service_banner_column">
                            <figure className="p-0 m-0 service_banner_img_cont">
                                <img
                                    src={healthCareBanner}
                                    alt=""
                                />
                            </figure>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 service_banner_desc_cont">
                            <div className="service_banner_desc_media__body">
                                <h2 className="service_banner_desc_media__title mb-4">
                                    Developing Effective Healthcare Websites and Apps
                                </h2>
                                <div className="service_banner_desc_media__text">
                                    <p>As digital is both the gateway to health information and access to health services, the quality of your digital experience is tightly coupled with the quality of service you provide. <br /><br /> Capeark has extensive experience helping healthcare clients:</p>
                                    <ul className="service_banner_desc_list ">
                                        <li><span className='fw-bold'>Design clean websites</span> and native apps with simple, intuitive navigation</li>
                                        <li>Identify and bring top content forward</li>
                                        <li>Focus on <span className='fw-bold'>search experience</span> to optimize visitor satisfaction</li>
                                        <li>Create customized <span className='fw-bold'>intranet portals</span> based on staff or member needs</li>
                                        <li>Build <span className='fw-bold'>patient-centric tools</span> such as online scheduling, messaging capabilities or virtual appointments</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default HealthCareBanner;