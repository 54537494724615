import React from 'react'

function HealthCareTextBanner() {
    return (
        <React.Fragment>
            <div style={{ backgroundColor: "#f5f5f5" }} className='mb-5'>
                <div className="container pb-5 pt-5">
                    <div className="row pb-4 pt-4">
                        <div className="col-sm-12 col-md-6 col-lg-6 ">
                            <div className="industry_non_profit_design_desc_card_main">
                                <h2>Empowering Your Team</h2>
                                <p>
                                    Having worked with clients like yourselves for over 14 years, we understand the challenges you’re up against:
                                </p>
                                <div className="industry_non_profit__desc_media__text">
                                    <ul className="industry_non_profit_desc_list ">
                                        <li>Highly regulated environment with strict privacy and security requirements</li>
                                        <li>Patients with varied abilities, ages and technical abilities</li>
                                        <li>Medical content that needs to be updated, written for the general audience and redesigned to be optimized for web </li>
                                        <li>Robust governance standards</li>
                                        <li>Multitude of systems that need to be connected</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 ">
                            <div className="industry_non_profit_design_desc_card_main">
                                <h2>Accessibility for All</h2>
                                <p>
                                    From strategy to execution, our team can take the lead and deliver a turnkey website to meet your needs.
                                </p>
                                <div className="industry_non_profit__desc_media__text">
                                    <ul className="industry_non_profit_desc_list ">
                                        <li>While accessibility standards have become the norm for websites, we recognize that healthcare providers are held to higher expectations to ensure that visitors of all abilities receive the same standard of care.</li>
                                        <li>Our team considers accessibility from the beginning of the project through to the development and QA process We design to conform with accessibility from the start, selecting readable typography and high contrast colour options, and planning interactive features so that they are compatible with screen readers.</li>
                                        <li>Accessibility and usability testing is performed by end users of all abilities to ensure the effectiveness of our product.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default HealthCareTextBanner